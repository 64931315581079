<div class="mypb-video-player">
  <div class="mypb-video-player-close" (click)="stopAndHideVideo()">
    <span><i class="fal fa-times"></i></span>
  </div>
  <div class="mypb-video-player-video" *ngIf="videoUri">
    <video controls #videoPlayer>
      <source [src]="videoUri | getVideoFromUri" type="video/mp4">
      <source [src]="videoUri | getVideoFromUri" type="video/ogg">
      Dieser Browser unterstützt keine Videos.
    </video>
  </div>
</div>
