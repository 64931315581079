import { Component, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { PageMetadataService } from './services/page-metadata.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent {
  static PLATFORM_ID: Object;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private renderer: Renderer2,
    private pageMetadataService: PageMetadataService,
  ) {
    AppComponent.PLATFORM_ID = this.platformId;
    this.pageMetadataService.listenForRouteChanges();
  }

  ngAfterViewInit() {
    AppComponent.HIDE_LOADER();
  }

  static HIDE_LOADER() {
    if (isPlatformBrowser(AppComponent.PLATFORM_ID)) {
      setTimeout(() => {
        const loader = document.getElementById('loader');
        if (loader) {
          loader.classList.add('loader--hide');
          setTimeout(() => {
            loader.classList.add('loader--hidden');
          }, 500);
        }
      }, 0);
    }
  }
}
