<div class="mypb-add-block" [class.mypb-add-block--is-open]="showMenu">
  <div class="mypb-add-block-add"><button (click)="toggleMenu()"><i class="fal fa-plus"></i></button></div>
  <div class="mypb-add-block-blocks" *ngIf="showMenu">
    <ul>
      <li *ngFor="let blockType of blockTypes">
        <button [title]="blockType.description" (click)="addBlockOfType(blockType)"><i class="fal fa-cube"></i><span>{{ blockType.name }}</span></button>
      </li>
    </ul>
  </div>
</div>
