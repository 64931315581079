import { Directive, HostListener } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { AppComponent } from '../app.component';

@Directive({
  selector: '[scrollwatcher]',
})
export class ScrollWatcherDirective {
  static CLASSES = {
    START: 'mypb--scroll-start',
    UP: 'mypb--scroll-up',
    DOWN: 'mypb--scroll-down',
    OFFSET: 'mypb--scroll-offset',
  };
  lastScrollTop = 0;
  scrolling = false;
  timer: any;

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    if (isPlatformBrowser(AppComponent.PLATFORM_ID)) {
      if (!this.scrolling) {
        document.body.classList.add(ScrollWatcherDirective.CLASSES.START);
        this.scrolling = true;
      }

      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      /**
       * Up and down classes.
       */
      if (scrollTop > this.lastScrollTop) {
        document.body.classList.add(ScrollWatcherDirective.CLASSES.DOWN);
        document.body.classList.remove(ScrollWatcherDirective.CLASSES.UP);
      } else {
        document.body.classList.add(ScrollWatcherDirective.CLASSES.UP);
        document.body.classList.remove(ScrollWatcherDirective.CLASSES.DOWN);
      }

      /**
       * Start class. Added to body when scrolling, and removed only when
       * at the very top.
       */
      if (scrollTop <= 0) {
        document.body.classList.remove(ScrollWatcherDirective.CLASSES.START);
      }

      /**
       * Add offset class when scrolled at least 100px or more. Otherwise
       * remove the class.
       */
      if (scrollTop >= 100) {
        document.body.classList.add(ScrollWatcherDirective.CLASSES.OFFSET);
      } else {
        document.body.classList.remove(ScrollWatcherDirective.CLASSES.OFFSET);
      }

      /**
       * Save latest scroll top position.
       */
      this.lastScrollTop = scrollTop;

      /**
       * Timeout for setting scroll status.
       */
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.scrolling = false;
      }, 250);
    }
  }
}
