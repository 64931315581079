import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MypbProfile, MypbTemplate } from '../../_generated/mypagebuilder-rest-api';
import { Router } from '@angular/router';
import { RoutingHelperService } from '../../services/routing-helper.service';
import { Store } from '@ngxs/store';
import { AppState } from '../../state/app/app.state';

@Component({
  selector: 'mypb-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['profile.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileComponent implements OnInit {
  @Input() profile: MypbProfile = {} as any;
  @Input() template: MypbTemplate = {} as any;
  public showVideo: boolean = false;
  public isMyPage$ = this.store.select(AppState.isMyPage);

  constructor(
    protected store: Store,
    protected router: Router,
    protected routingHelperService: RoutingHelperService,
  ) {
  }

  ngOnInit(): void {
  }

  public async goToHome() {
    await this.router.navigateByUrl(this.routingHelperService.getRootUrlOfCurrentlySelectedPage(true));
  }
}
