import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { AppComponent } from '../app.component';

@Directive({
  selector: '[autoResizeTextarea]',
})
export class AutoResizeTextareaDirective implements OnInit {

  constructor(private elementRef: ElementRef) {
  }

  @HostListener(':input')
  onInput() {
    this.resize();
  }

  ngOnInit() {
    if (isPlatformBrowser(AppComponent.PLATFORM_ID)) {
      if (this.elementRef.nativeElement.scrollHeight) {
        setTimeout(() => this.resize(), 100);
      }
    }
  }

  resize() {
    if (this.elementRef.nativeElement.scrollHeight === 0) {
      return;
    }
    this.elementRef.nativeElement.style.height = '0';
    this.elementRef.nativeElement.style.height = this.elementRef.nativeElement.scrollHeight + 'px';
  }
}
