import { Injectable } from '@angular/core';
import { Action, NgxsAfterBootstrap, Selector, State, StateContext } from '@ngxs/store';
import { OnboardingStateModel } from './onboarding.state.model';
import { MypbTemplate } from '../../_generated/mypagebuilder-rest-api';
import {
  OnboardingStateSetDetails,
  OnboardingStateSetPageName,
  OnboardingStateSetTemplate,
} from './onboarding.actions';

const STATE_NAME = 'MYPB__OnboardingState';
const DEFAULT_STATE: OnboardingStateModel = {
  pageName: '',
  template: undefined,
  domainId: '',
  details: {
    firstname: '',
    lastname: '',
    email: '',
  },
};

@State<OnboardingStateModel>({
  name: STATE_NAME,
  defaults: DEFAULT_STATE,
})
@Injectable()
export class OnboardingState implements NgxsAfterBootstrap {
  constructor() {
  }

  @Selector()
  static canSetDetails(state: OnboardingStateModel): boolean {
    return !!state.template && !!state.domainId;
  }

  @Selector()
  static canChooseName(state: OnboardingStateModel): boolean {
    return !!state.template && !!state.domainId;
  }

  @Selector()
  static domainId(state: OnboardingStateModel): string {
    return state.domainId;
  }

  @Selector()
  static pageName(state: OnboardingStateModel): string {
    return state.pageName;
  }

  @Selector()
  static template(state: OnboardingStateModel): MypbTemplate | undefined {
    return state.template;
  }

  @Selector()
  static details(state: OnboardingStateModel): { firstname: string, lastname: string, email: string } {
    return state.details;
  }

  @Action(OnboardingStateSetTemplate)
  onboardingStateSetTemplate(ctx: StateContext<OnboardingStateModel>, action: OnboardingStateSetTemplate) {
    return ctx.patchState({
      template: action.template,
      domainId: action.domainId,
    });
  }

  @Action(OnboardingStateSetPageName)
  onboardingStateSetPageName(ctx: StateContext<OnboardingStateModel>, action: OnboardingStateSetPageName) {
    return ctx.patchState({
      pageName: action.pageName,
    });
  }

  @Action(OnboardingStateSetDetails)
  onboardingStateSetDetails(ctx: StateContext<OnboardingStateModel>, action: OnboardingStateSetDetails) {
    return ctx.patchState({
      details: {
        firstname: action.firstname,
        lastname: action.lastname,
        email: action.email,
      },
    });
  }

  ngxsAfterBootstrap(ctx: StateContext<OnboardingStateModel>) {
  }
}
