<div class="mypb-standalone-page">
  <mypb-apps-header></mypb-apps-header>
  <div class="mypb-profile-app mypb-standalone-page__margin">

    <div class="mypb-grid-small mypb-dashboard-lead">
      <h1>Mein Profil</h1>
    </div>

    <div class="mypb-grid-small">

      <div class="base">
        <h2>Profildaten</h2>
        <div class="form-wrapper">
          <mypb-profile-app-base [profile]="profile"></mypb-profile-app-base>
        </div>
      </div>
  
      <div class="picture">
        <h2>Portraitfoto</h2>
        <div class="form-wrapper">
          <div class="mypb-profile-app--dropzone">
            <mypb-profile-app-picture [profile]="profile"></mypb-profile-app-picture>
          </div>
        </div>
      </div>
  
      <div class="video">
        <h2>Video</h2>
        <div class="form-wrapper">
          <div class="mypb-profile-app--dropzone">
            <mypb-profile-app-video [profile]="profile"></mypb-profile-app-video>
          </div>
        </div>
      </div>
  
      <div class="links">
        <h2>Links</h2>
        <div class="form-wrapper">
          <mypb-profile-app-links></mypb-profile-app-links>
        </div>
      </div>
  
      <div class="contact">
        <h2>Kontakt</h2>
        <div class="form-wrapper">
          <mypb-profile-app-contact></mypb-profile-app-contact>
        </div>
      </div>

    </div>

  </div>

  <div class="mypb-bg"><div class="mypb-bg-inner"></div></div>
</div>
