/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MypbCreateSubpageDto } from '../models/mypb-create-subpage-dto';
import { MypbSubpage } from '../models/mypb-subpage';
import { MypbUpdateSubpageDto } from '../models/mypb-update-subpage-dto';

@Injectable({
  providedIn: 'root',
})
export class MypbContentManagementSubpagesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation subpagesControllerFindAll
   */
  static readonly SubpagesControllerFindAllPath = '/v1/content-management/subpages/byProfileId/{profileId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subpagesControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  subpagesControllerFindAll$Response(params: {
    profileId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<MypbSubpage>>> {

    const rb = new RequestBuilder(this.rootUrl, MypbContentManagementSubpagesService.SubpagesControllerFindAllPath, 'get');
    if (params) {
      rb.path('profileId', params.profileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MypbSubpage>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `subpagesControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subpagesControllerFindAll(params: {
    profileId: string;
    context?: HttpContext
  }
): Observable<Array<MypbSubpage>> {

    return this.subpagesControllerFindAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MypbSubpage>>) => r.body as Array<MypbSubpage>)
    );
  }

  /**
   * Path part for operation subpagesControllerFindOneById
   */
  static readonly SubpagesControllerFindOneByIdPath = '/v1/content-management/subpages/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subpagesControllerFindOneById()` instead.
   *
   * This method doesn't expect any request body.
   */
  subpagesControllerFindOneById$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<MypbSubpage>> {

    const rb = new RequestBuilder(this.rootUrl, MypbContentManagementSubpagesService.SubpagesControllerFindOneByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MypbSubpage>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `subpagesControllerFindOneById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subpagesControllerFindOneById(params: {
    id: string;
    context?: HttpContext
  }
): Observable<MypbSubpage> {

    return this.subpagesControllerFindOneById$Response(params).pipe(
      map((r: StrictHttpResponse<MypbSubpage>) => r.body as MypbSubpage)
    );
  }

  /**
   * Path part for operation subpagesControllerRemove
   */
  static readonly SubpagesControllerRemovePath = '/v1/content-management/subpages/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subpagesControllerRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  subpagesControllerRemove$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MypbContentManagementSubpagesService.SubpagesControllerRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `subpagesControllerRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subpagesControllerRemove(params: {
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.subpagesControllerRemove$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation subpagesControllerUpdate
   */
  static readonly SubpagesControllerUpdatePath = '/v1/content-management/subpages/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subpagesControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subpagesControllerUpdate$Response(params: {
    id: string;
    context?: HttpContext
    body: MypbUpdateSubpageDto
  }
): Observable<StrictHttpResponse<MypbSubpage>> {

    const rb = new RequestBuilder(this.rootUrl, MypbContentManagementSubpagesService.SubpagesControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MypbSubpage>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `subpagesControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subpagesControllerUpdate(params: {
    id: string;
    context?: HttpContext
    body: MypbUpdateSubpageDto
  }
): Observable<MypbSubpage> {

    return this.subpagesControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<MypbSubpage>) => r.body as MypbSubpage)
    );
  }

  /**
   * Path part for operation subpagesControllerFindOneBySlug
   */
  static readonly SubpagesControllerFindOneBySlugPath = '/v1/content-management/subpages/byProfileIdAndSlug/{profileId}/{slug}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subpagesControllerFindOneBySlug()` instead.
   *
   * This method doesn't expect any request body.
   */
  subpagesControllerFindOneBySlug$Response(params: {
    slug: string;
    profileId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<MypbSubpage>> {

    const rb = new RequestBuilder(this.rootUrl, MypbContentManagementSubpagesService.SubpagesControllerFindOneBySlugPath, 'get');
    if (params) {
      rb.path('slug', params.slug, {});
      rb.path('profileId', params.profileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MypbSubpage>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `subpagesControllerFindOneBySlug$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subpagesControllerFindOneBySlug(params: {
    slug: string;
    profileId: string;
    context?: HttpContext
  }
): Observable<MypbSubpage> {

    return this.subpagesControllerFindOneBySlug$Response(params).pipe(
      map((r: StrictHttpResponse<MypbSubpage>) => r.body as MypbSubpage)
    );
  }

  /**
   * Path part for operation subpagesControllerCreate
   */
  static readonly SubpagesControllerCreatePath = '/v1/content-management/subpages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subpagesControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subpagesControllerCreate$Response(params: {
    context?: HttpContext
    body: MypbCreateSubpageDto
  }
): Observable<StrictHttpResponse<MypbSubpage>> {

    const rb = new RequestBuilder(this.rootUrl, MypbContentManagementSubpagesService.SubpagesControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MypbSubpage>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `subpagesControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subpagesControllerCreate(params: {
    context?: HttpContext
    body: MypbCreateSubpageDto
  }
): Observable<MypbSubpage> {

    return this.subpagesControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<MypbSubpage>) => r.body as MypbSubpage)
    );
  }

}
