<div class="mypb-block mypb-block-text-image {{block.blockStyle ? block.blockStyle.cssClass : ''}}" *ngIf="block">

  <div class="mypb-block-text-image--image">
    <div class="block-image-wrap">
      <div class="block-image-remove" (click)="removeImage(block.images[0])">
        <i class="fas fa-trash"></i>
      </div>
      <div class="block-image" *ngIf="block && block.images && block.images.length && block.images[0].image">
        <img [src]="block.images[0].image.uri | getImageFromUri:600" [alt]="block.images[0].image.caption">
      </div>
      <mypb-upload [allowImage]="true"
                  [allowVideo]="false"
                  [allowDocument]="false"
                  [allowMultiple]="false"
                  [autoUpload]="true"
                  (uploadedImage)="onImageUploaded($event)"></mypb-upload>
      <ng-template #viewMode>
        <div class="block-content-wrapper">
          <div class="block-content" [innerHTML]="block.text"></div>
        </div>
      </ng-template>
    </div>
  </div>

  <div *ngIf="(isEditModeEnabled$ | async); else viewMode;" class="edit-mode edit-trix">
    <mypb-trix-editor [content]="block.text" (contentUpdated)="updateText($event)"
                      [triggerReset]="triggerReset"></mypb-trix-editor>
    <div class="edit-mode-actions">
      <div class="edit-mode-actions--inner">
        <button type="submit" class="btn-small-primary" (click)="updateBlockTextImage(newData)">
          {{ 'global.ui.save' | translate }}
        </button>
        <button class="btn-small-secondary" (click)="cancelBlockTextImage()">
          {{ 'global.ui.cancel' | translate }}
        </button>
      </div>
    </div>
  </div>

</div>
