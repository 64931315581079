/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MypbBlackListWord } from '../models/mypb-black-list-word';
import { MypbCreateBlackListWordDto } from '../models/mypb-create-black-list-word-dto';
import { MypbUpdateBlackListWordDto } from '../models/mypb-update-black-list-word-dto';

@Injectable({
  providedIn: 'root',
})
export class MypbAdminBlackListWordsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation blackListWordsAdminControllerFindAll
   */
  static readonly BlackListWordsAdminControllerFindAllPath = '/v1/admin/black-list-words';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `blackListWordsAdminControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  blackListWordsAdminControllerFindAll$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<MypbBlackListWord>>> {

    const rb = new RequestBuilder(this.rootUrl, MypbAdminBlackListWordsService.BlackListWordsAdminControllerFindAllPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MypbBlackListWord>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `blackListWordsAdminControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  blackListWordsAdminControllerFindAll(params?: {
    context?: HttpContext
  }
): Observable<Array<MypbBlackListWord>> {

    return this.blackListWordsAdminControllerFindAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MypbBlackListWord>>) => r.body as Array<MypbBlackListWord>)
    );
  }

  /**
   * Path part for operation blackListWordsAdminControllerCreate
   */
  static readonly BlackListWordsAdminControllerCreatePath = '/v1/admin/black-list-words';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `blackListWordsAdminControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  blackListWordsAdminControllerCreate$Response(params: {
    context?: HttpContext
    body: MypbCreateBlackListWordDto
  }
): Observable<StrictHttpResponse<MypbBlackListWord>> {

    const rb = new RequestBuilder(this.rootUrl, MypbAdminBlackListWordsService.BlackListWordsAdminControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MypbBlackListWord>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `blackListWordsAdminControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  blackListWordsAdminControllerCreate(params: {
    context?: HttpContext
    body: MypbCreateBlackListWordDto
  }
): Observable<MypbBlackListWord> {

    return this.blackListWordsAdminControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<MypbBlackListWord>) => r.body as MypbBlackListWord)
    );
  }

  /**
   * Path part for operation blackListWordsAdminControllerFindOne
   */
  static readonly BlackListWordsAdminControllerFindOnePath = '/v1/admin/black-list-words/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `blackListWordsAdminControllerFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  blackListWordsAdminControllerFindOne$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<MypbBlackListWord>> {

    const rb = new RequestBuilder(this.rootUrl, MypbAdminBlackListWordsService.BlackListWordsAdminControllerFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MypbBlackListWord>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `blackListWordsAdminControllerFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  blackListWordsAdminControllerFindOne(params: {
    id: string;
    context?: HttpContext
  }
): Observable<MypbBlackListWord> {

    return this.blackListWordsAdminControllerFindOne$Response(params).pipe(
      map((r: StrictHttpResponse<MypbBlackListWord>) => r.body as MypbBlackListWord)
    );
  }

  /**
   * Path part for operation blackListWordsAdminControllerDelete
   */
  static readonly BlackListWordsAdminControllerDeletePath = '/v1/admin/black-list-words/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `blackListWordsAdminControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  blackListWordsAdminControllerDelete$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MypbAdminBlackListWordsService.BlackListWordsAdminControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `blackListWordsAdminControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  blackListWordsAdminControllerDelete(params: {
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.blackListWordsAdminControllerDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation blackListWordsAdminControllerUpdate
   */
  static readonly BlackListWordsAdminControllerUpdatePath = '/v1/admin/black-list-words/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `blackListWordsAdminControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  blackListWordsAdminControllerUpdate$Response(params: {
    id: string;
    context?: HttpContext
    body: MypbUpdateBlackListWordDto
  }
): Observable<StrictHttpResponse<MypbBlackListWord>> {

    const rb = new RequestBuilder(this.rootUrl, MypbAdminBlackListWordsService.BlackListWordsAdminControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MypbBlackListWord>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `blackListWordsAdminControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  blackListWordsAdminControllerUpdate(params: {
    id: string;
    context?: HttpContext
    body: MypbUpdateBlackListWordDto
  }
): Observable<MypbBlackListWord> {

    return this.blackListWordsAdminControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<MypbBlackListWord>) => r.body as MypbBlackListWord)
    );
  }

}
