<div class="mypb-email-addresses-editor">
  <form [formGroup]="form" (ngSubmit)="save()" novalidate>
    <div class="mypb-email-addresses-editor-entry" *ngFor="let entity of entities; let index = index;">
      <div class="form-group">
        <label for="entity{{index}}value{{randomId}}">{{ 'global.personal.emailAddress' | translate }}</label>
        <input type="text" id="entity{{index}}value{{randomId}}" class="form-control"
               [formControl]="$any(form).controls['entity'+index+'value']"
               placeholder="{{ 'global.personal.emailAddress' | translate }}">
        <small class="form-text text-muted"
               *ngIf="form.controls['entity'+index+'value'].touched && form.controls['entity'+index+'value'].hasError('required')">
          {{ 'global.personal.emailAddress' | translate }} {{ 'global.ui.isRequired' | translate }}
        </small>
      </div>
      <div class="form-group">
        <input type="checkbox" id="entity{{index}}isPublic{{randomId}}" class="form-control"
               [formControl]="$any(form).controls['entity'+index+'isPublic']"
               placeholder="{{ 'global.ui.isPublic' | translate }}">
        <label for="entity{{index}}isPublic{{randomId}}">{{ 'global.ui.isPublic' | translate }}</label>
        <small class="form-text text-muted"
               *ngIf="form.controls['entity'+index+'isPublic'].touched && form.controls['entity'+index+'isPublic'].hasError('required')">
          {{ 'global.ui.isPublic' | translate }} {{ 'global.ui.isRequired' | translate }}
        </small>
      </div>
      <a class="mypb-email-addresses-editor-entry__delete" (click)="deleteAtIndex(index)">Löschen</a>
    </div>
    <button class="btn-small-primary" (click)="$event.preventDefault(); addNew()">
      {{ 'actions.new.emailAddress' | translate }}
    </button>
    <ng-container *ngIf="!autoSave">
      <button type="submit"
              class="btn btn-primary"
              [disabled]="!form.valid || form.pristine">
        {{ 'global.ui.save' | translate }}
      </button>
    </ng-container>
  </form>
</div>
