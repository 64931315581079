<div
  class="mypb-block mypb-block-gallery mypb-block-gallery--{{block.images ? block.images.length : '0'}} {{block.blockStyle ? block.blockStyle.cssClass : ''}}"
  *ngIf="block">
  <div *ngIf="(isEditModeEnabled$ | async); else viewMode;" class="edit-mode">
    <mypb-image-gallery [imagesOfBlock]="block.images"
                        [isEditModeEnabled]="isEditModeEnabled$ | async"
                        (onChangeOrder)="changeOrder($event)"
                        (onRemoveImage)="removeImage($event)">
      <mypb-upload [allowImage]="true"
                   [allowVideo]="false"
                   [allowDocument]="false"
                   [allowMultiple]="true"
                   [autoUpload]="true"
                   (uploadedImage)="onImageUploaded($event)"></mypb-upload>
    </mypb-image-gallery>

    <div class="edit-mode-actions" *ngIf="newImagesToSave.length || imagesToDelete.length">
      <div class="edit-mode-actions--inner">
        <button type="submit" class="btn-small-primary" (click)="updateBlockGallery(newData)">
          {{ 'global.ui.save' | translate }}
        </button>
        <button class="btn-small-secondary" (click)="cancelBlockGallery()">
          {{ 'global.ui.cancel' | translate }}
        </button>
      </div>
    </div>

  </div>
  <ng-template #viewMode>
    <mypb-image-gallery [imagesOfBlock]="block.images">MM</mypb-image-gallery>
  </ng-template>
</div>
