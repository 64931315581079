/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MypbAddLinkToBlockDto } from '../models/mypb-add-link-to-block-dto';
import { MypbBlockToLink } from '../models/mypb-block-to-link';
import { MypbRemoveLinkFromBlockDto } from '../models/mypb-remove-link-from-block-dto';

@Injectable({
  providedIn: 'root',
})
export class MypbContentManagementBlockToLinkService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation blockToLinksControllerAdd
   */
  static readonly BlockToLinksControllerAddPath = '/v1/content-management/block-to-link';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `blockToLinksControllerAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  blockToLinksControllerAdd$Response(params: {
    context?: HttpContext
    body: MypbAddLinkToBlockDto
  }
): Observable<StrictHttpResponse<MypbBlockToLink>> {

    const rb = new RequestBuilder(this.rootUrl, MypbContentManagementBlockToLinkService.BlockToLinksControllerAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MypbBlockToLink>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `blockToLinksControllerAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  blockToLinksControllerAdd(params: {
    context?: HttpContext
    body: MypbAddLinkToBlockDto
  }
): Observable<MypbBlockToLink> {

    return this.blockToLinksControllerAdd$Response(params).pipe(
      map((r: StrictHttpResponse<MypbBlockToLink>) => r.body as MypbBlockToLink)
    );
  }

  /**
   * Path part for operation blockToLinksControllerRemove
   */
  static readonly BlockToLinksControllerRemovePath = '/v1/content-management/block-to-link';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `blockToLinksControllerRemove()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  blockToLinksControllerRemove$Response(params: {
    context?: HttpContext
    body: MypbRemoveLinkFromBlockDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MypbContentManagementBlockToLinkService.BlockToLinksControllerRemovePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `blockToLinksControllerRemove$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  blockToLinksControllerRemove(params: {
    context?: HttpContext
    body: MypbRemoveLinkFromBlockDto
  }
): Observable<void> {

    return this.blockToLinksControllerRemove$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
