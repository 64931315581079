import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalService } from '../modal/modal.service';

@Component({
  selector: 'mypb-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['footer.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent implements OnInit {
  constructor(
    private modalService: ModalService,
  ) {
  }

  ngOnInit() {
  }

  openDataprotectionModal() {
    this.modalService.open('dataprotection');
  }
}
