import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mypb-server-error',
  templateUrl: './server-error.component.html',
  styleUrls: ['server-error.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ServerErrorComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {
  }
}
