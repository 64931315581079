import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageComponent } from './pages/page/page.component';
import { SsrPageResolverService } from './resolvers/ssr-page-resolver.service';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ServerErrorComponent } from './pages/server-error/server-error.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { LoginPage } from './pages/login/login.page';
import { PageSelectorPage } from './pages/page-selector/page-selector.page';
import { IfLoggedIn } from './guards/if-logged-in.guard';
import { RedirectIfLoggedIn } from './guards/redirect-if-logged-in.guard';
import { ProfileAppPage } from './pages/apps/profile-app/profile-app.page';
import { AppsPage } from './pages/apps/apps.page';
import { AnalyticsAppPage } from './pages/apps/analytics-app/analytics-app.page';
import { SubscriptionsAppPage } from './pages/apps/subscriptions-app/subscriptions-app.page';
import { OnboardingPage } from './pages/onboarding/onboarding.page';
import { OnboardingTemplatePage } from './pages/onboarding/onboarding-template/onboarding-template.page';
import { OnboardingDetailsPage } from './pages/onboarding/onboarding-details/onboarding-details.page';
import { OnboardingChooseNamePage } from './pages/onboarding/onboarding-choose-name/onboarding-choose-name.page';
import { OnboardingProfilePage } from './pages/onboarding/onboarding-profile/onboarding-profile.page';
import { DataprotectionComponent } from './pages/dataprotection/dataprotection.component';
import { SubpagesAppPage } from './pages/apps/subpages-app/subpages-app.page';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'onboarding',
    pathMatch: 'full',
  },
  {
    path: 'onboarding',
    component: OnboardingPage,
    data: {
      title: 'Onboarding',
    },
    children: [
      {
        path: '',
        component: OnboardingTemplatePage,
        canActivate: [RedirectIfLoggedIn],
      },
      {
        path: 'template',
        component: OnboardingTemplatePage,
        canActivate: [RedirectIfLoggedIn],
      },
      {
        path: 'details',
        component: OnboardingDetailsPage,
        canActivate: [RedirectIfLoggedIn],
      },
      {
        path: 'choose-name',
        component: OnboardingChooseNamePage,
        canActivate: [RedirectIfLoggedIn],
      },
      {
        path: 'profile',
        component: OnboardingProfilePage,
        canActivate: [IfLoggedIn],
      },
    ],
  },
  {
    path: 'login',
    component: LoginPage,
    canActivate: [RedirectIfLoggedIn],
    data: {
      title: 'Login',
    },
  },
  {
    path: 'page-selector',
    component: PageSelectorPage,
    canActivate: [IfLoggedIn],
    data: {
      title: 'Seite auswählen',
    },
  },
  {
    path: 'apps',
    canActivate: [IfLoggedIn],
    data: {
      title: 'Apps',
    },
    children: [
      {
        path: '',
        component: AppsPage,
        data: {
          title: 'Apps Übersicht',
        },
      },
      {
        path: 'profil',
        component: ProfileAppPage,
        data: {
          title: 'Profil',
        },
      },
      {
        path: 'seiten',
        component: SubpagesAppPage,
        data: {
          title: 'Seiten',
        },
      },
      {
        path: 'statistik',
        component: AnalyticsAppPage,
        data: {
          title: 'Statistik',
        },
      },
      {
        path: 'abos',
        component: SubscriptionsAppPage,
        data: {
          title: 'Abos',
        },
      },
    ],
  },
  {
    path: 'info',
    children: [
      {
        path: '',
        redirectTo: 'datenschutz',
        pathMatch: 'full',
      },
      {
        path: 'datenschutz',
        component: DataprotectionComponent,
        data: {
          title: 'Datenschutz',
        },
      },
    ],
  },
  {
    path: 's/404',
    component: NotFoundComponent,
    data: {
      title: 'Nicht gefunden',
    },
  },
  {
    path: 's/500',
    component: ServerErrorComponent,
    data: {
      title: 'System-Fehler',
    },
  },
  {
    path: 's/maintenance',
    component: MaintenanceComponent,
    data: {
      title: 'Wartungsarbeiten',
    },
  },
  // For local development only, so you can easily access different domains.
  {
    path: 'dev/:domainName/:pageName',
    component: PageComponent,
    resolve: {
      preFetchedData: SsrPageResolverService,
    },
  },
  // For server side rendering, where the "domainName" will be taken from the actual domain.
  {
    path: ':pageName',
    component: PageComponent,
    resolve: {
      preFetchedData: SsrPageResolverService,
    },
  },
  {
    path: ':pageName/:slug',
    component: PageComponent,
    resolve: {
      preFetchedData: SsrPageResolverService,
    },
  },
  {
    path: ':pageName/:slug/:subslug',
    component: PageComponent,
    resolve: {
      preFetchedData: SsrPageResolverService,
    },
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
