import { Component, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { AppState } from '../../state/app/app.state';

@Component({
  selector: 'mypb-apps-button',
  templateUrl: './apps-button.component.html',
  styleUrls: ['./apps-button.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class AppsButtonComponent {
  public isMyPage$ = this.store.select(AppState.isMyPage);

  constructor(
    private store: Store,
  ) {
  }
}
