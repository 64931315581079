import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { MypbContentManagementPagesService, MypbPage } from '../../_generated/mypagebuilder-rest-api';
import { Router } from '@angular/router';
import { RoutingHelperService } from '../../services/routing-helper.service';

@Component({
  selector: 'mypb-page-selector-page',
  templateUrl: './page-selector.page.html',
  styleUrls: ['page-selector.page.less'],
  encapsulation: ViewEncapsulation.None,
})
export class PageSelectorPage implements OnInit {
  public pages: MypbPage[] = [];

  constructor(
    public readonly routingHelperService: RoutingHelperService,
    private readonly mypbContentManagementPagesService: MypbContentManagementPagesService,
    private readonly router: Router,
  ) {
  }

  async ngOnInit() {
    await this.initializePages();
  }

  private async initializePages() {
    this.pages = await lastValueFrom(this.mypbContentManagementPagesService.pagesControllerFindAll());
    if (this.pages && this.pages.length === 1) {
      await this.router.navigateByUrl(this.routingHelperService.getUrlForPage(
        this.pages[0].name,
        this.pages[0].domain.name,
        true,
      ), { replaceUrl: true });
    }
  }
}
