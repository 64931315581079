import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { lastValueFrom, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {
  MypbCheckFreeResponseDto,
  MypbContentDeliveryPagesService,
  MypbContentDeliveryTemplatesService,
  MypbDomain,
  MypbPatternResponseDto,
} from '../../../_generated/mypagebuilder-rest-api';
import { Router } from '@angular/router';
import { OnboardingState } from '../../../state/onboarding/onboarding.state';
import { ApiServerHelperService } from '../../../services/api-server-helper.service';
import { OnboardingStateSetPageName } from '../../../state/onboarding/onboarding.actions';


@Component({
  selector: 'mypb-onboarding-choose-name-page',
  templateUrl: './onboarding-choose-name.page.html',
  styleUrls: ['onboarding-choose-name.page.less'],
  encapsulation: ViewEncapsulation.None,
})
export class OnboardingChooseNamePage implements OnInit {
  public onboardingForm: FormGroup;
  public pageName: string = '';
  public pattern: MypbPatternResponseDto | undefined;
  public isMatchingPattern: boolean = false;
  public checkFreeResponseDto: MypbCheckFreeResponseDto | undefined;
  private keyupEvent = new Subject<string>();
  private subscription: Subscription | undefined;

  constructor(
    public readonly apiServerHelperService: ApiServerHelperService,
    private readonly store: Store,
    private readonly router: Router,
    private readonly mypbContentDeliveryTemplatesService: MypbContentDeliveryTemplatesService,
    private readonly mypbContentDeliveryPagesService: MypbContentDeliveryPagesService,
  ) {
    this.onboardingForm = new FormGroup({
      pageName: new FormControl('', [Validators.required]),
    });
  }

  async ngOnInit() {
    this.pattern = await lastValueFrom(this.mypbContentDeliveryPagesService.pagesDeliveryControllerGetPattern({
      'x-api-key': this.apiServerHelperService.getXApiKey(),
    }));
    this.subscription = this.keyupEvent.pipe(
      debounceTime(500),
    ).subscribe(newValue => this.checkAvailability(newValue));
    this.store.select(OnboardingState.pageName).subscribe({
      next: pageName => {
        this.pageName = pageName;
        if (pageName) {
          this.checkAvailability(pageName);
        }
      },
    });
    this.store.select(OnboardingState.canChooseName).subscribe({
      next: async canChooseName => {
        if (!canChooseName) {
          await this.router.navigateByUrl('/onboarding');
        }
      },
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onKeyup(event: any) {
    if (event.target && event.target.value) {
      this.keyupEvent.next(event.target.value);
    }
  }

  async continue() {
    this.store.dispatch(new OnboardingStateSetPageName(this.pageName));
    await this.router.navigateByUrl('/onboarding/details');
  }

  public async checkAvailability(pageName: string) {
    if (this.pattern && new RegExp(this.pattern.pattern).test(pageName)) {
      this.isMatchingPattern = true;
      this.checkFreeResponseDto = await lastValueFrom(this.mypbContentDeliveryPagesService.pagesDeliveryControllerCheckFree({
        'x-api-key': this.apiServerHelperService.getXApiKey(),
        body: {
          domainId: this.getDomainIdBasedOnDomainName(),
          name: pageName,
        },
      }));
    } else {
      this.isMatchingPattern = false;
    }
  }

  private getDomainIdBasedOnDomainName() {
    const selectedTemplate = this.store.selectSnapshot(OnboardingState.template);
    if (selectedTemplate) {
      const domain = selectedTemplate.allowedDomains.find((domain: MypbDomain) => domain.name === this.apiServerHelperService.getDomainForXForwardedHost());
      if (domain) {
        return domain.id;
      }
    }
    return '';
  }
}
