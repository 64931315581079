import { Component, Inject, OnDestroy, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MypbContentDeliveryFormsService } from '../../_generated/mypagebuilder-rest-api';
import { LoggerService } from '../../_common/logger/logger.service';
import { environment } from '../../../environments/environment';
import { Store } from '@ngxs/store';
import { AppState } from '../../state/app/app.state';
import swal from 'sweetalert2';
import { ModalService } from '../modal/modal.service';

@Component({
  selector: 'mypb-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['contact-form.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ContactFormComponent implements OnInit, OnDestroy {
  public form: FormGroup;

  constructor(
    private mypbContentDeliveryFormsService: MypbContentDeliveryFormsService,
    private store: Store,
    private modalService: ModalService,
    @Optional() @Inject('X_API_KEY') private xApiKey: string,
  ) {
    this.form = new FormGroup({
      pageId: new FormControl(this.store.selectSnapshot(AppState.pageId), [
        Validators.required,
      ]),
      firstname: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(50),
      ]),
      lastname: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(50),
      ]),
      emailAddress: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.minLength(2),
        Validators.maxLength(100),
      ]),
      phoneNumber: new FormControl('', [
        Validators.minLength(10),
        Validators.maxLength(15),
      ]),
      message: new FormControl('', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(1000),
      ]),
    });
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {

  }

  public send() {
    this.form.patchValue({
      message: this.form.value['message'].replace(/(?:\r\n|\r|\n)/g, '<br>'),
    });
    this.mypbContentDeliveryFormsService.formsDeliveryControllerSendMessage({
      'x-api-key': this.getXApiKey(),
      body: this.form.value,
    }).subscribe({
      next: (sent: boolean) => {
        if (sent) {
          swal.fire('Die Nachricht wurde gesendet.', '', 'success').then();
          this.close();
        } else {
          swal.fire('Oops', 'Beim senden der Nachricht ist ein Fehler aufgetreten.', 'error').then();
        }
      },
      error: (notSentException: any) => {
        LoggerService.ERROR(this, 'notSentException', notSentException);
        swal.fire('Oops', 'Beim senden der Nachricht ist ein Fehler aufgetreten.', 'error').then();
      },
    });
  }

  /**
   * Returns the API Secret from node environment (SSR) or angular environment (CSR).
   *
   * @private
   */
  private getXApiKey(): string {
    if (this.xApiKey) {
      return this.xApiKey;
    }
    return environment.apiConfiguration.apiKey;
  }

  /**
   * Reset/empty the form and close modal.
   *
   * @private
   */
  private close() {
    this.form.reset();
    this.modalService.close('contactform');
  }
}
