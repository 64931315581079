<div class="mypb-standalone-page">
  <mypb-apps-header></mypb-apps-header>
  <div class="mypb-subpages-app  mypb-standalone-page__margin">
    
    <div class="mypb-grid-small mypb-dashboard-lead">
      <h1>Seiten</h1>
    </div>
    
    <div class="mypb-grid-small">
      <div cdkDropList cdkDropListLockAxis="y" cdkDropListOrientation="vertical" (cdkDropListDropped)="drop($event)">
        <div class="subpage" *ngFor="let subpage of subpages" cdkDrag>
          <div class="form-group">
            <input type="text" class="form-control" [ngModel]="subpage.name"
                   (blur)="changeSubpageName($event, subpage)">
          </div>
          <button (click)="toggleIsActive(subpage, true)" [disabled]="subpage.isActive" class="is-primary">Seite aktivieren</button>
          <button (click)="toggleIsActive(subpage, false)" [disabled]="!subpage.isActive">Seite deaktivieren</button>
          <button
            *ngIf="subpage.subpageType && !((subpage.subpageType.technicalName === subpageTechnicalNameEnum.Blog) || (subpage.subpageType.technicalName === subpageTechnicalNameEnum.Contact))"
            [swal]="{ title: 'Möchtest du diese Seite wirklich löschen? Damit wird auch der gesamte Inhalt gelöscht.', text: '', showCancelButton: true, cancelButtonText: 'Nein', confirmButtonText: 'Ja' }"
            (confirm)="deleteSubpage(subpage)">
            Seite löschen
          </button>
          <ng-template cdkDragPlaceholder>
            <div class="drag-placeholder">
              {{ subpage.name }}
            </div>
          </ng-template>
          <ng-template cdkDragPreview></ng-template>
        </div>
        <mypb-add-subpage (add)="addSubpage($event)"></mypb-add-subpage>
      </div>
    </div>

  </div>

  <div class="mypb-bg"><div class="mypb-bg-inner"></div></div>
</div>
