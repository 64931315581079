import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';
import { MypbContentDeliveryMediaService } from '../_generated/mypagebuilder-rest-api';

@Pipe({ name: 'getImageFromUri' })
export class GetImageFromUri implements PipeTransform {
  transform(uri: string | undefined, width?: number, height?: number, intelligentCrop?: boolean) {

    if (uri && uri.indexOf('http') === -1) {

      // Remove 'data' from string, since this is just server side information.
      if (uri.indexOf('/data') === 0) {
        uri = uri.substring('/data'.length + 1);
      }

      // Construct URL query parameters.
      let parameters: URLSearchParams = new URLSearchParams();

      if (width && width > 0) {
        parameters.set('width', width + '');
      }
      if (height && height > 0) {
        parameters.set('height', height + '');
      }
      if (intelligentCrop) {
        parameters.set('intelligentCrop', intelligentCrop + '');
      }

      const path = MypbContentDeliveryMediaService.MediaDeliveryControllerDownloadImagePath.substring(
        0,
        MypbContentDeliveryMediaService.MediaDeliveryControllerDownloadImagePath.indexOf('/uploads'),
      );

      // Return complete URI including parameters.
      return `${environment.apiConfiguration.baseUrl}${path}/${uri}?${parameters.toString()}`;
    }
    return uri || '';
  }
}
