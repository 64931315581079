/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MypbAuthDto } from '../models/mypb-auth-dto';
import { MypbProfileDto } from '../models/mypb-profile-dto';
import { MypbRefreshtokenDto } from '../models/mypb-refreshtoken-dto';
import { MypbTokenResponseDto } from '../models/mypb-token-response-dto';

@Injectable({
  providedIn: 'root',
})
export class MypbAuthenticationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation authenticationControllerLogin
   */
  static readonly AuthenticationControllerLoginPath = '/v1/authentication/login';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerLogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerLogin$Response(params: {
    context?: HttpContext
    body: MypbAuthDto
  }
): Observable<StrictHttpResponse<MypbTokenResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, MypbAuthenticationService.AuthenticationControllerLoginPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MypbTokenResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerLogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerLogin(params: {
    context?: HttpContext
    body: MypbAuthDto
  }
): Observable<MypbTokenResponseDto> {

    return this.authenticationControllerLogin$Response(params).pipe(
      map((r: StrictHttpResponse<MypbTokenResponseDto>) => r.body as MypbTokenResponseDto)
    );
  }

  /**
   * Path part for operation authenticationControllerRefreshToken
   */
  static readonly AuthenticationControllerRefreshTokenPath = '/v1/authentication/refreshTokens';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerRefreshToken()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerRefreshToken$Response(params: {
    context?: HttpContext
    body: MypbRefreshtokenDto
  }
): Observable<StrictHttpResponse<MypbTokenResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, MypbAuthenticationService.AuthenticationControllerRefreshTokenPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MypbTokenResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerRefreshToken$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerRefreshToken(params: {
    context?: HttpContext
    body: MypbRefreshtokenDto
  }
): Observable<MypbTokenResponseDto> {

    return this.authenticationControllerRefreshToken$Response(params).pipe(
      map((r: StrictHttpResponse<MypbTokenResponseDto>) => r.body as MypbTokenResponseDto)
    );
  }

  /**
   * Path part for operation authenticationControllerLogout
   */
  static readonly AuthenticationControllerLogoutPath = '/v1/authentication/logout';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerLogout()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationControllerLogout$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MypbAuthenticationService.AuthenticationControllerLogoutPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerLogout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationControllerLogout(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.authenticationControllerLogout$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation authenticationControllerProfile
   */
  static readonly AuthenticationControllerProfilePath = '/v1/authentication/profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationControllerProfile$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<MypbProfileDto>> {

    const rb = new RequestBuilder(this.rootUrl, MypbAuthenticationService.AuthenticationControllerProfilePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MypbProfileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationControllerProfile(params?: {
    context?: HttpContext
  }
): Observable<MypbProfileDto> {

    return this.authenticationControllerProfile$Response(params).pipe(
      map((r: StrictHttpResponse<MypbProfileDto>) => r.body as MypbProfileDto)
    );
  }

}
