import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable()
export class AppInitializer {
  constructor(
    private httpClient: HttpClient,
    private router: Router,
  ) {
  }

  initialize() {
    return new Promise<void>((resolve, reject) => {
      resolve();
    });
  }
}
