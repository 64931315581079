<section id="main-profile" class="mypb-profile main-profile">
  <div class="col-profile" *ngIf="profile">

    <div class="tpl-wrap">

      <header class="tpl-header">
        <div class="tpl-logo" (click)="goToHome()">
          <img src="../../../../assets/templates/{{template.technicalName}}/img/logo.png"
               [alt]="template.name"></div>
      </header>

      <div class="tpl-body">

        <div class="tpl-portrait">
          <div class="tpl-portrait-name" dynamicFirstnameAndLastnameSize titleDynamicFontSize [titleMaxFontSize]="35">
            <div class="tpl-name-line">
              <span class="firstname">{{ profile.firstname }}</span>
            </div>
            <div class="tpl-name-line">
              <span class="lastname">{{ profile.lastname }}</span>
            </div>
            <div class="tpl-name-line">
              <span class="title">{{ profile.contentTitle }}</span>
            </div>
          </div>
          <div *ngIf="profile.contentImage"
               class="tpl-portrait-image"
               style="background-image: url({{profile.contentImage.uri | getImageFromUri:1100:1100:true}})">
            <div *ngIf="isMyPage$ | async" class="tpl-potrait-image-upload">
              <mypb-profile-app-picture [profile]="profile"></mypb-profile-app-picture>
            </div>
          </div>
          <div *ngIf="!profile.contentImage"
               class="tpl-portrait-image">
            <div *ngIf="isMyPage$ | async" class="tpl-potrait-image-upload">
              <mypb-profile-app-picture [profile]="profile"></mypb-profile-app-picture>
            </div>
          </div>
        </div>

        <div class="tpl-links">
          <ul>
            <li *ngFor="let link of profile.links">
              <a [href]="link.value" target="_blank" [title]="link.label"
                 class="tpl-icon-brand {{link.linktype | getIconForLinkTypePipe}}"></a>
            </li>
          </ul>
        </div>

        <div class="tpl-lead-wrap">
          <div class="tpl-portrait-video" *ngIf="profile.contentVideo">
            <a (click)="showVideo = true" class="tpl-play-btn tpl-icon tpl-icon-play"></a>
          </div>
          <div class="tpl-lead">
            <p [innerHTML]="profile.contentLeadtext"></p>
          </div>
        </div>
      </div>

      <mypb-video-player *ngIf="profile.contentVideo && showVideo"
                         (videoStopped)="showVideo = false"
                         [videoUri]="profile.contentVideo.uri"></mypb-video-player>

    </div>
  </div>
</section>
