/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class MypbContentDeliveryMediaService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation mediaDeliveryControllerDownloadImage
   */
  static readonly MediaDeliveryControllerDownloadImagePath = '/v1/content-delivery/media/image/uploads/{profileId}/{fileName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mediaDeliveryControllerDownloadImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaDeliveryControllerDownloadImage$Response(params: {
    profileId: string;
    fileName: string;
    intelligentCrop: string;
    width: string;
    height: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MypbContentDeliveryMediaService.MediaDeliveryControllerDownloadImagePath, 'get');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.path('fileName', params.fileName, {});
      rb.query('intelligentCrop', params.intelligentCrop, {});
      rb.query('width', params.width, {});
      rb.query('height', params.height, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mediaDeliveryControllerDownloadImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaDeliveryControllerDownloadImage(params: {
    profileId: string;
    fileName: string;
    intelligentCrop: string;
    width: string;
    height: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.mediaDeliveryControllerDownloadImage$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation mediaDeliveryControllerDownloadFile
   */
  static readonly MediaDeliveryControllerDownloadFilePath = '/v1/content-delivery/media/file/uploads/{profileId}/{fileName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mediaDeliveryControllerDownloadFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaDeliveryControllerDownloadFile$Response(params: {
    profileId: string;
    fileName: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MypbContentDeliveryMediaService.MediaDeliveryControllerDownloadFilePath, 'get');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.path('fileName', params.fileName, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mediaDeliveryControllerDownloadFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaDeliveryControllerDownloadFile(params: {
    profileId: string;
    fileName: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.mediaDeliveryControllerDownloadFile$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation mediaDeliveryControllerDownloadVideo
   */
  static readonly MediaDeliveryControllerDownloadVideoPath = '/v1/content-delivery/media/video/uploads/{profileId}/{fileName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mediaDeliveryControllerDownloadVideo()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaDeliveryControllerDownloadVideo$Response(params: {
    profileId: string;
    fileName: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MypbContentDeliveryMediaService.MediaDeliveryControllerDownloadVideoPath, 'get');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.path('fileName', params.fileName, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mediaDeliveryControllerDownloadVideo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaDeliveryControllerDownloadVideo(params: {
    profileId: string;
    fileName: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.mediaDeliveryControllerDownloadVideo$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
