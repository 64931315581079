import { AfterViewInit, Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[dynamicFirstnameAndLastnameSize]',
})
export class DynamicFirstnameAndLastnameSizeDirective implements AfterViewInit {
  @Input() firstnameAndLastnameMinFontSize: number = 10; // Default minimum font size
  @Input() firstnameAndLastnameMaxFontSize: number = 70; // Default maximum font size
  @Input() firstnameMargin: string = '0px'; // Default margin for firstname
  @Input() lastnameMargin: string = '100px'; // Default margin for lastname
  private defaultFontSize: number = 70; // Default starting font size
  private viewportThreshold: number = 1100; // Viewport width threshold

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  ngAfterViewInit() {
    this.adjustFontSize();
  }

  @HostListener('window:resize')
  onResize() {
    this.adjustFontSize();
  }

  private adjustFontSize() {
    const containerElement = this.el.nativeElement;
    const firstNameElement = containerElement.querySelector('.firstname');
    const lastNameElement = containerElement.querySelector('.lastname');

    if (!firstNameElement || !lastNameElement) return;

    let fontSize = this.defaultFontSize;
    this.setElementFontSize(firstNameElement, lastNameElement, fontSize);

    // Adjust for margins if viewport is less than the threshold
    const containerWidth = containerElement.offsetWidth;
    const currentViewportWidth = window.innerWidth;
    let availableWidth = containerWidth;

    if (currentViewportWidth < this.viewportThreshold) {
      const firstNameMargin = this.parseMargin(this.firstnameMargin);
      const lastNameMargin = this.parseMargin(this.lastnameMargin);
      availableWidth -= Math.max(firstNameMargin, lastNameMargin);
    }

    // Adjust font size based on available width
    while ((firstNameElement.scrollWidth > availableWidth || lastNameElement.scrollWidth > availableWidth) && fontSize > this.firstnameAndLastnameMinFontSize) {
      fontSize--;
      this.setElementFontSize(firstNameElement, lastNameElement, fontSize);
    }
  }

  private setElementFontSize(firstNameElement: HTMLElement, lastNameElement: HTMLElement, fontSize: number) {
    this.renderer.setStyle(firstNameElement, 'font-size', `${fontSize}px`);
    this.renderer.setStyle(lastNameElement, 'font-size', `${fontSize}px`);
  }

  private parseMargin(margin: string): number {
    return parseInt(margin.replace('px', ''));
  }
}
