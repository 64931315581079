import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { LoggerService } from '../../_common/logger/logger.service';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { AppStateBackdropClicked, AppStateToggleBackdrop } from '../../state/app/app.actions';

@Component({
  selector: 'mypb-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['video-player.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class VideoPlayerComponent implements OnInit {
  @ViewChild('videoPlayer') videoPlayer: ElementRef | undefined;
  @Input() videoUri: string | undefined;
  @Output() videoStopped: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private store: Store,
    private actions: Actions,
  ) {
    this.store.dispatch(new AppStateToggleBackdrop(true));
    this.actions.pipe(
      ofActionDispatched(AppStateBackdropClicked),
    ).subscribe(() => {
      this.stopAndHideVideo();
    });
  }

  ngOnInit(): void {
    try {
      document.onkeydown = (evt) => {
        evt = evt || window.event;
        let isEscape = false;
        if ('key' in evt) {
          isEscape = (evt.key === 'Escape' || evt.key === 'Esc');
        } else {
          isEscape = (evt.keyCode === 27);
        }
        if (isEscape) {
          this.stopAndHideVideo();
        }
      };
    } catch (noEscapeKeyException) {
      LoggerService.LOG(this, 'noEscapeKeyException', noEscapeKeyException);
    }
  }

  ngAfterViewInit() {
    if (this.videoPlayer) {
      if (this.videoPlayer.nativeElement.paused) {
        this.videoPlayer.nativeElement.play();
      }
    }
  }

  public stopAndHideVideo() {
    if (this.videoPlayer) {
      if (!this.videoPlayer.nativeElement.paused) {
        this.videoPlayer.nativeElement.pause();
      }
    }
    this.videoStopped.emit();
    this.store.dispatch(new AppStateToggleBackdrop(false));
  }
}
