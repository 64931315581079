/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MypbWpUser } from '../models/mypb-wp-user';

@Injectable({
  providedIn: 'root',
})
export class MypbAdminWpUsersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation wpUsersAdminControllerFindAll
   */
  static readonly WpUsersAdminControllerFindAllPath = '/v1/admin/wp-users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `wpUsersAdminControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  wpUsersAdminControllerFindAll$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<MypbWpUser>>> {

    const rb = new RequestBuilder(this.rootUrl, MypbAdminWpUsersService.WpUsersAdminControllerFindAllPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MypbWpUser>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `wpUsersAdminControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  wpUsersAdminControllerFindAll(params?: {
    context?: HttpContext
  }
): Observable<Array<MypbWpUser>> {

    return this.wpUsersAdminControllerFindAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MypbWpUser>>) => r.body as Array<MypbWpUser>)
    );
  }

  /**
   * Path part for operation wpUsersAdminControllerFindOne
   */
  static readonly WpUsersAdminControllerFindOnePath = '/v1/admin/wp-users/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `wpUsersAdminControllerFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  wpUsersAdminControllerFindOne$Response(params: {
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<MypbWpUser>> {

    const rb = new RequestBuilder(this.rootUrl, MypbAdminWpUsersService.WpUsersAdminControllerFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MypbWpUser>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `wpUsersAdminControllerFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  wpUsersAdminControllerFindOne(params: {
    id: number;
    context?: HttpContext
  }
): Observable<MypbWpUser> {

    return this.wpUsersAdminControllerFindOne$Response(params).pipe(
      map((r: StrictHttpResponse<MypbWpUser>) => r.body as MypbWpUser)
    );
  }

}
