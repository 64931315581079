import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'mypb-dataprotection',
  templateUrl: './dataprotection.component.html',
  styleUrls: ['dataprotection.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class DataprotectionComponent implements OnInit, OnDestroy {
  public stylesheets: SafeResourceUrl[] = [];

  constructor() {
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {

  }
}
