import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { MypbProfileDto } from '../../../_generated/mypagebuilder-rest-api';
import { AppState } from '../../../state/app/app.state';

@Component({
  selector: 'mypb-subscriptions-app-page',
  templateUrl: './subscriptions-app.page.html',
  styleUrls: ['subscriptions-app.page.less'],
  encapsulation: ViewEncapsulation.None,
})
export class SubscriptionsAppPage implements OnInit {
  public technicalProfile: MypbProfileDto = {} as any;

  constructor(
    private store: Store,
  ) {

  }

  ngOnInit() {
    this.store.select(AppState.technicalProfile).subscribe(technicalProfile => {
      if (technicalProfile) {
        this.technicalProfile = technicalProfile;
      }
    });
  }
}
