import { Component, ViewEncapsulation } from '@angular/core';
import { Actions, Store } from '@ngxs/store';
import { AppState } from '../../state/app/app.state';
import { StateClear } from 'ngxs-reset-plugin';
import { Router } from '@angular/router';
import { RoutingHelperService } from '../../services/routing-helper.service';

@Component({
  selector: 'mypb-apps-page',
  templateUrl: './apps.page.html',
  styleUrls: ['apps.page.less'],
  encapsulation: ViewEncapsulation.None,
})
export class AppsPage {
  public profile$ = this.store.select(AppState.profile);

  constructor(
    private readonly store: Store,
    private readonly actions: Actions,
    private readonly router: Router,
    private readonly routingHelperService: RoutingHelperService,
  ) {

  }

  public async logout() {
    const redirectUrl = this.routingHelperService.getRootUrlOfCurrentlySelectedPage(true);
    this.store.dispatch(new StateClear());
    await this.router.navigateByUrl(redirectUrl);
  }
}
