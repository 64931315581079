import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiModule } from './_generated/mypagebuilder-rest-api';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { environment } from '../environments/environment';
import { LoginButtonComponent } from './components/login-button/login-button.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ApiJsonInterceptor } from './_common/api-json.interceptor';
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { AppState } from './state/app/app.state';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { ApiErrorInterceptor } from './_common/api-error.interceptor';
import { AppInitializer } from './app.initializer';
import { ApiTokenInterceptor } from './_common/api-token.interceptor';
import { PageComponent } from './pages/page/page.component';
import { ProfileComponent } from './components/profile/profile.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { ModeSwitcherComponent } from './components/mode-switcher/mode-switcher.component';
import { TrixEditorComponent } from './components/trix-editor/trix-editor.component';
import { IfLoggedIn } from './guards/if-logged-in.guard';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { TransferHttpCacheModule } from '@nguniversal/common';
import * as moment from 'moment';
import { DATE_PIPE_DEFAULT_TIMEZONE, DatePipe } from '@angular/common';
import '@angular/common/locales/global/de-CH';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { AppsHeaderComponent } from './components/apps-header/apps-header.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { GetImageFromUri } from './pipes/get-image-from-uri.pipe';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { ProfileAppLinksComponent } from './pages/apps/profile-app/profile-app-links/profile-app-links.component';
import { GetIconForLinkTypePipe } from './pipes/get-icon-for-linktype.pipe';
import { GetVideoFromUri } from './pipes/get-video-from-uri.pipe';
import { LightboxModule } from 'ngx-lightbox';
import { GetFileFromUriPipe } from './pipes/get-file-from-uri.pipe';
import { AutoResizeTextareaDirective } from './directives/auto-resize-textarea.directive';
import { ModalModule } from './components/modal/modal.module';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { ServerErrorComponent } from './pages/server-error/server-error.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { FitTextToContainerDirective } from './directives/fit-text-to-container.directive';
import { DataprotectionComponent } from './pages/dataprotection/dataprotection.component';
import { FooterComponent } from './components/footer/footer.component';
import { BackdropComponent } from './components/backdrop/backdrop.component';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
import { NgxMatomoRouterModule } from '@ngx-matomo/router';
import { NgChartsModule } from 'ng2-charts';
import { UploadComponent } from './components/upload/upload.component';
import { NgxVcardModule } from 'ngx-vcard';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { AddressesEditorComponent } from './components/addresses-editor/addresses-editor.component';
import { PhoneNumbersEditorComponent } from './components/phone-numbers-editor/phone-numbers-editor.component';
import { EmailAddressesEditorComponent } from './components/email-addresses-editor/email-addresses-editor.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { CountUpModule } from 'ngx-countup';
import { ScrollWatcherDirective } from './directives/scroll-watcher.directive';
import { CategoriesComponent } from './components/categories/categories.component';
import { LoginPage } from './pages/login/login.page';
import { AppsPage } from './pages/apps/apps.page';
import { PageSelectorPage } from './pages/page-selector/page-selector.page';
import { RedirectIfLoggedIn } from './guards/redirect-if-logged-in.guard';
import { AppsButtonComponent } from './components/apps-button/apps-button.component';
import { ProfileAppPage } from './pages/apps/profile-app/profile-app.page';
import { AnalyticsAppPage } from './pages/apps/analytics-app/analytics-app.page';
import { ProfileAppBaseComponent } from './pages/apps/profile-app/profile-app-base/profile-app-base.component';
import { ProfileAppPictureComponent } from './pages/apps/profile-app/profile-app-picture/profile-app-picture.component';
import { ProfileAppVideoComponent } from './pages/apps/profile-app/profile-app-video/profile-app-video.component';
import { ProfileAppContactComponent } from './pages/apps/profile-app/profile-app-contact/profile-app-contact.component';
import { SubscriptionsAppPage } from './pages/apps/subscriptions-app/subscriptions-app.page';
import { PageMetadataService } from './services/page-metadata.service';
import { RoutingHelperService } from './services/routing-helper.service';
import { SubpageComponent } from './pages/subpage/subpage.component';
import { BlockComponent } from './components/block/block.component';
import { BlockTextComponent } from './components/block-text/block-text.component';
import { AddBlockComponent } from './components/add-block/add-block.component';
import { AddSubpageComponent } from './components/add-subpage/add-subpage.component';
import { BlockstyleSelectorComponent } from './components/blockstyle-selector/blockstyle-selector.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ImageGalleryComponent } from './components/image-gallery/image-gallery.component';
import { BlockGalleryComponent } from './components/block-gallery/block-gallery.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SavingIndicatorComponent } from './components/saving-indicator/saving-indicator.component';
import { BlockTextImageComponent } from './components/block-text-image/block-text-image.component';
import { OnboardingPage } from './pages/onboarding/onboarding.page';
import { OnboardingTemplatePage } from './pages/onboarding/onboarding-template/onboarding-template.page';
import { OnboardingChooseNamePage } from './pages/onboarding/onboarding-choose-name/onboarding-choose-name.page';
import { OnboardingDetailsPage } from './pages/onboarding/onboarding-details/onboarding-details.page';
import { OnboardingState } from './state/onboarding/onboarding.state';
import { ApiServerHelperService } from './services/api-server-helper.service';
import { OnboardingProfilePage } from './pages/onboarding/onboarding-profile/onboarding-profile.page';
import { HomeButtonComponent } from './components/home-button/home-button.component';
import { SubscriptionsBannerComponent } from './components/subscriptions-banner/subscriptions-banner.component';
import { BlockContactComponent } from './components/block-contact/block-contact.component';
import { BlogEntryPreviewComponent } from './components/blog-entry-preview/blog-entry-preview.component';
import { ProfileDesign1Component } from './components/profile/profile-design-1/profile-design-1.component';
import { ProfileDesign2Component } from './components/profile/profile-design-2/profile-design-2.component';
import { LinksEditorComponent } from './components/links-editor/links-editor.component';
import { SubpagesAppPage } from './pages/apps/subpages-app/subpages-app.page';
import { DynamicFirstnameAndLastnameSizeDirective } from './directives/dynamic-firstname-and-lastname-size.directive';
import { TitleDynamicFontSizeDirective } from './directives/dynamic-title-size.directive';
import { AutofocusDirective } from './directives/autofocus.directive';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function initializeApp(appInitializer: AppInitializer) {
  return (): Promise<any> => {
    return appInitializer.initialize();
  };
}

const timezoneFactory = () => {
  const isSommerzeit = moment().isDST();
  return isSommerzeit ? 'CEST' : 'CET';
};

const editorDeclarations = [
  AddBlockComponent,
  AddSubpageComponent,
  AppsButtonComponent,
  BlockComponent,
  BlockTextComponent,
  BlockstyleSelectorComponent,
  AnalyticsAppPage,
  SubscriptionsAppPage,
  ProfileAppPage,
  ProfileAppBaseComponent,
  ProfileAppPictureComponent,
  ProfileAppVideoComponent,
  ProfileAppContactComponent,
  ModeSwitcherComponent,
  AppsHeaderComponent,
  TrixEditorComponent,
  UploadComponent,
  ProfileAppLinksComponent,
  AddressesEditorComponent,
  PhoneNumbersEditorComponent,
  EmailAddressesEditorComponent,
  AutoResizeTextareaDirective,
  ScrollWatcherDirective,
  DynamicFirstnameAndLastnameSizeDirective,
  TitleDynamicFontSizeDirective,
  AutofocusDirective,
  HomeButtonComponent,
  SubscriptionsBannerComponent,
  LinksEditorComponent,
  SubpagesAppPage,
];

@NgModule({
  declarations: [
    AppComponent,
    AppsPage,
    SubpageComponent,
    LoginButtonComponent,
    LoginPage,
    PageComponent,
    DataprotectionComponent,
    LandingPageComponent,
    ProfileComponent,
    NavigationComponent,
    NotFoundComponent,
    GetImageFromUri,
    GetFileFromUriPipe,
    GetVideoFromUri,
    GetIconForLinkTypePipe,
    VideoPlayerComponent,
    FitTextToContainerDirective,
    ServerErrorComponent,
    MaintenanceComponent,
    FooterComponent,
    BackdropComponent,
    ContactFormComponent,
    CategoriesComponent,
    PageSelectorPage,
    ImageGalleryComponent,
    BlockGalleryComponent,
    BlockTextImageComponent,
    SavingIndicatorComponent,
    OnboardingPage,
    OnboardingTemplatePage,
    OnboardingChooseNamePage,
    OnboardingDetailsPage,
    OnboardingProfilePage,
    BlockContactComponent,
    BlogEntryPreviewComponent,
    ProfileDesign1Component,
    ProfileDesign2Component,
    ...editorDeclarations,
    BreadcrumbComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    TransferHttpCacheModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    DragDropModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    ApiModule.forRoot({ rootUrl: environment.apiConfiguration.baseUrl }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      },
      defaultLanguage: 'de',
    }),
    NgxsModule.forRoot([
      AppState,
      OnboardingState,
    ], {
      developmentMode: !environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      storage: StorageOption.LocalStorage,
      key: ['MYPB__AppState', 'MYPB__OnboardingState'],
    }),
    NgxsLoggerPluginModule.forRoot({
      disabled: !environment.isLoggingEnabled.ngxs,
    }),
    NgxsResetPluginModule.forRoot(),
    SweetAlert2Module.forRoot(),
    LightboxModule,
    ModalModule,
    NgxMatomoTrackerModule.forRoot({
      trackerUrl: 'https://analytics.mypagebuilder.ch',
      siteId: '1',
      disabled: false,
    }),
    NgxMatomoRouterModule,
    NgChartsModule,
    NgxVcardModule,
    NgxQRCodeModule,
    CountUpModule,
  ],
  providers: [
    AppInitializer,
    IfLoggedIn,
    RedirectIfLoggedIn,
    GetImageFromUri,
    PageMetadataService,
    RoutingHelperService,
    ApiServerHelperService,
    DatePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitializer],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiJsonInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiErrorInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'de-CH',
    },
    {
      provide: DATE_PIPE_DEFAULT_TIMEZONE,
      useFactory: timezoneFactory,
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'CHF',
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}
