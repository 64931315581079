import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MypbContentManagementEmailAddressesService, MypbCreateEmailAddressDto, MypbEmailAddress, MypbProfile, MypbUpdateEmailAddressDto } from '../../_generated/mypagebuilder-rest-api';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoggerService } from '../../_common/logger/logger.service';
import { AppStateIsSaving, AppStateRefreshPageData } from '../../state/app/app.actions';
import { Store } from '@ngxs/store';
import { AppState } from '../../state/app/app.state';
import swal from 'sweetalert2';
import { debounceTime, Subscription } from 'rxjs';

@Component({
  selector: 'mypb-email-addresses-editor',
  templateUrl: './email-addresses-editor.component.html',
  styleUrls: ['email-addresses-editor.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class EmailAddressesEditorComponent implements OnInit {
  @Input('autoSave') autoSave: boolean = false;
  public form: FormGroup;
  public entities: Partial<MypbEmailAddress>[] = [];
  public randomId: string = Math.random().toString(36).substring(2, 9);
  private profile: MypbProfile | undefined;
  private formChanges$: Subscription | undefined;

  constructor(
    private store: Store,
    private mypbContentManagementEmailAddressesService: MypbContentManagementEmailAddressesService,
  ) {
    this.form = this.getFormGroup();
    this.store.select(AppState.profile).subscribe(profile => this.profile = profile);
  }

  ngOnInit() {
    if (this.profile?.id) {
      this.mypbContentManagementEmailAddressesService.emailAddressesControllerFindAllByProfileId({
        profileId: this.profile?.id,
      }).subscribe({
        next: entities => {
          if (entities && entities.length === 0) {
            this.addNew();
          } else {
            this.entities = entities;
            this.form = this.getFormGroup();
          }
          this.initializeAutoSave();
        },
        error: noEntitiesException => {
          LoggerService.ERROR(this, 'noEntitiesException', noEntitiesException);
        },
      });
    }
  }

  ngOnDestroy() {
    try {
      this.formChanges$?.unsubscribe();
      this.formChanges$ = undefined;
    } finally {
    }
  }

  public addNew() {
    if (this.profile?.id) {
      this.entities.push({
        value: '',
        isPublic: true,
        profileId: this.profile?.id,
      });
      this.form = this.getFormGroup();
      this.initializeAutoSave();
    }
  }

  public deleteAtIndex(index: number) {
    const entityToDelete = this.entities[index];
    if (entityToDelete && entityToDelete.id) {
      this.mypbContentManagementEmailAddressesService.emailAddressesControllerRemove({
        id: entityToDelete.id,
      }).subscribe({
        next: () => {
          this.entities.splice(index, 1);
          this.store.dispatch(new AppStateRefreshPageData());
          this.form = this.getFormGroup();
          this.initializeAutoSave();
          swal.fire('E-Mail Adresse wurde gelöscht.', '', 'success').then();
        },
      });
    }
  }

  public save() {
    for (let index = 0; index < this.entities.length; index++) {
      const wasModified = !this.form.controls[`entity${index}value`].pristine || !this.form.controls[`entity${index}isPublic`].pristine;
      if (!wasModified) {
        continue;
      }
      if (this.form.value[`entity${index}id`]) {
        this.mypbContentManagementEmailAddressesService.emailAddressesControllerUpdate({
          id: this.form.value[`entity${index}id`],
          body: this.getBodyFromFormValueForIndex(index) as MypbUpdateEmailAddressDto,
        }).subscribe({
          next: updatedEntity => {
            this.store.dispatch(new AppStateRefreshPageData());
            this.entities[index] = updatedEntity;
            this.form = this.getFormGroup();
            this.initializeAutoSave();
            this.onSave();
          },
          error: entityNotUpdatedException => {
            LoggerService.ERROR(this, 'entityNotUpdatedException', entityNotUpdatedException);
          },
        });
      } else {
        this.mypbContentManagementEmailAddressesService.emailAddressesControllerCreate({
          body: this.getBodyFromFormValueForIndex(index) as MypbCreateEmailAddressDto,
        }).subscribe({
          next: createdEntity => {
            this.store.dispatch(new AppStateRefreshPageData());
            this.entities[index] = createdEntity;
            this.form = this.getFormGroup();
            this.initializeAutoSave();
            this.onSave();
          },
          error: entityNotUpdatedException => {
            LoggerService.ERROR(this, 'entityNotUpdatedException', entityNotUpdatedException);
          },
        });
      }
    }
  }

  private getFormGroup(): FormGroup {
    const group: any = {};
    for (let index = 0; index < this.entities.length; index++) {
      console.log('this.entities', this.entities);
      group[`entity${index}id`] = new FormControl(this.entities[index].id || '', []);
      group[`entity${index}value`] = new FormControl(this.entities[index].value || '', [Validators.required]);
      group[`entity${index}profileId`] = new FormControl(this.entities[index].profileId || this.profile?.id, [Validators.required]);
      group[`entity${index}isPublic`] = new FormControl(this.entities[index].isPublic, [Validators.required]);
    }
    return new FormGroup(group);
  }

  private getBodyFromFormValueForIndex(index: number): Partial<MypbEmailAddress> {
    return {
      id: this.form.value[`entity${index}id`],
      value: this.form.value[`entity${index}value`],
      profileId: this.form.value[`entity${index}profileId`],
      isPublic: this.form.value[`entity${index}isPublic`],
    };
  }

  private onSave() {
    this.store.dispatch(new AppStateIsSaving(true));
    this.store.dispatch(new AppStateIsSaving(false));
  }

  private initializeAutoSave() {
    if (this.autoSave) {
      if (this.formChanges$) {
        this.formChanges$?.unsubscribe();
      }
      this.formChanges$ = this.form.valueChanges
        .pipe(
          debounceTime(1000),
        )
        .subscribe(() => {
          if (this.form.valid && !this.form.pristine) {
            this.save();
          }
        });
    }
  }
}
