/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MypbCreateTemplateDto } from '../models/mypb-create-template-dto';
import { MypbTemplate } from '../models/mypb-template';
import { MypbUpdateTemplateDto } from '../models/mypb-update-template-dto';

@Injectable({
  providedIn: 'root',
})
export class MypbAdminTemplatesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation templatesAdminControllerFindAll
   */
  static readonly TemplatesAdminControllerFindAllPath = '/v1/admin/templates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesAdminControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesAdminControllerFindAll$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<MypbTemplate>>> {

    const rb = new RequestBuilder(this.rootUrl, MypbAdminTemplatesService.TemplatesAdminControllerFindAllPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MypbTemplate>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesAdminControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesAdminControllerFindAll(params?: {
    context?: HttpContext
  }
): Observable<Array<MypbTemplate>> {

    return this.templatesAdminControllerFindAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MypbTemplate>>) => r.body as Array<MypbTemplate>)
    );
  }

  /**
   * Path part for operation templatesAdminControllerCreate
   */
  static readonly TemplatesAdminControllerCreatePath = '/v1/admin/templates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesAdminControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  templatesAdminControllerCreate$Response(params: {
    context?: HttpContext
    body: MypbCreateTemplateDto
  }
): Observable<StrictHttpResponse<MypbTemplate>> {

    const rb = new RequestBuilder(this.rootUrl, MypbAdminTemplatesService.TemplatesAdminControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MypbTemplate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesAdminControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  templatesAdminControllerCreate(params: {
    context?: HttpContext
    body: MypbCreateTemplateDto
  }
): Observable<MypbTemplate> {

    return this.templatesAdminControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<MypbTemplate>) => r.body as MypbTemplate)
    );
  }

  /**
   * Path part for operation templatesAdminControllerFindOne
   */
  static readonly TemplatesAdminControllerFindOnePath = '/v1/admin/templates/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesAdminControllerFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesAdminControllerFindOne$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<MypbTemplate>> {

    const rb = new RequestBuilder(this.rootUrl, MypbAdminTemplatesService.TemplatesAdminControllerFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MypbTemplate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesAdminControllerFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesAdminControllerFindOne(params: {
    id: string;
    context?: HttpContext
  }
): Observable<MypbTemplate> {

    return this.templatesAdminControllerFindOne$Response(params).pipe(
      map((r: StrictHttpResponse<MypbTemplate>) => r.body as MypbTemplate)
    );
  }

  /**
   * Path part for operation templatesAdminControllerDelete
   */
  static readonly TemplatesAdminControllerDeletePath = '/v1/admin/templates/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesAdminControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesAdminControllerDelete$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MypbAdminTemplatesService.TemplatesAdminControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesAdminControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesAdminControllerDelete(params: {
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.templatesAdminControllerDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation templatesAdminControllerUpdate
   */
  static readonly TemplatesAdminControllerUpdatePath = '/v1/admin/templates/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesAdminControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  templatesAdminControllerUpdate$Response(params: {
    id: string;
    context?: HttpContext
    body: MypbUpdateTemplateDto
  }
): Observable<StrictHttpResponse<MypbTemplate>> {

    const rb = new RequestBuilder(this.rootUrl, MypbAdminTemplatesService.TemplatesAdminControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MypbTemplate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesAdminControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  templatesAdminControllerUpdate(params: {
    id: string;
    context?: HttpContext
    body: MypbUpdateTemplateDto
  }
): Observable<MypbTemplate> {

    return this.templatesAdminControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<MypbTemplate>) => r.body as MypbTemplate)
    );
  }

}
