<article class="mypb-subpage" [class.mypb-subpage--is-active]="subpage.isActive" *ngIf="subpage">
  <div class="col-block" autofocusFirstInput>

    <ng-container *ngIf="(isEditModeEnabled$ | async) && (isMyPage$ | async)">
      <div class="mypb-subpage-toolbar">

        <div class="mypb-subpage-toolbar--col">
          <p *ngIf="subpage.isActive">Sichtbarkeit: <span>Veröffentlicht</span></p>
          <p *ngIf="!subpage.isActive">Sichtbarkeit: <span>Entwurf</span></p>
        </div>
        <div class="mypb-subpage-toolbar--col">
          <button *ngIf="!subpage.isActive" (click)="setSubpageAsActive(true)">
            Seite veröffentlichen
          </button>
          <button *ngIf="subpage.isActive" (click)="setSubpageAsActive(false)">
            Seite verbergen
          </button>
        </div>

      </div>
    </ng-container>

    <ng-container>
      <ng-container
        *ngIf="subpage.subpageType && subpage.subpageType.technicalName === subpageTechnicalNameEnum.BlogEntry">
        <mypb-breadcrumb backroute="blog"></mypb-breadcrumb>

        <ng-container *ngIf="(isEditModeEnabled$ | async) && (isMyPage$ | async); else showTitleAsH1">
          <label class="mypb-subpage__title-label" for="subpagetitleinput">Titel</label><input
            class="mypb-subpage__title-input" type="text"
            id="subpagetitleinput"
            [(ngModel)]="blogPostTitle"
            [value]="blogPostTitle"
            (blur)="saveBlogPostTitle()">
        </ng-container>
        <ng-template #showTitleAsH1>
          <div class="block-content"><h1>{{ subpage.name }}</h1></div>
        </ng-template>
      </ng-container>
      <div [ngClass]="{'edit-mode edit-trix': (isEditModeEnabled$ | async) && (isMyPage$ | async)}">

        <mypb-add-block *ngIf="(isEditModeEnabled$ | async) && (isMyPage$ | async)"
                        (addBlock)="addBlockAtPosition($event, 0)"></mypb-add-block>


        <div *ngFor="let block of subpage.blocks; let index = index;" class="mypb-subpage--block">

          <div class="mypb-subpage--block-toolbar" *ngIf="(isEditModeEnabled$ | async) && (isMyPage$ | async)">
            <button (click)="moveBlockUp(index)" [disabled]="index === 0"><i class="fas fa-chevron-up"></i></button>
            <button (click)="moveBlockDown(index)" [disabled]="index === subpage.blocks.length - 1"><i
              class="fas fa-chevron-down"></i></button>
            <button
              [swal]="{ title: 'Möchtest du diesen Block wirklich löschen?', text: '', showCancelButton: true, cancelButtonText: 'Nein', confirmButtonText: 'Ja' }"
              (confirm)="delete(block)"><i class="fas fa-trash"></i>
            </button>
            <!-- <mypb-blockstyle-selector [preselected]="block.blockStyle"
                                      (selected)="setBlockStyle($event, block)"></mypb-blockstyle-selector> -->
          </div>

          <div class="mypb-subpage--block--inner">

            <ng-container [ngSwitch]="block.blockType.technicalName">
              <ng-container *ngSwitchCase="'TEXT'">
                <mypb-block-text [block]="block" [subpage]="subpage"></mypb-block-text>
              </ng-container>
              <ng-container *ngSwitchCase="'GALLERY'">
                <mypb-block-gallery [block]="block"></mypb-block-gallery>
              </ng-container>
              <ng-container *ngSwitchCase="'TEXT_IMAGE'">
                <mypb-block-text-image [block]="block"></mypb-block-text-image>
              </ng-container>
            </ng-container>

          </div>

          <mypb-add-block *ngIf="(isEditModeEnabled$ | async) && (isMyPage$ | async)"
                          (addBlock)="addBlockAtPosition($event, index + 1)"></mypb-add-block>
        </div>

      </div>
    </ng-container>

    <ng-container *ngIf="subpage.subpageType && subpage.subpageType.technicalName === subpageTechnicalNameEnum.Blog">
      <mypb-add-subpage (add)="addBlogPost($event)"
                        [createWithRandomName]="true"
                        *ngIf="(isMyPage$ | async) && (isEditModeEnabled$ | async)"
                        [subpageTechnicalNameEnum]="subpageTechnicalNameEnum.BlogEntry"></mypb-add-subpage>
      <ng-container *ngFor="let child of subpage.children">
        <mypb-blog-entry-preview [subpage]="child"
                                 link="/{{rootPath$ | async}}/{{subpage.slug}}/{{child.slug}}"></mypb-blog-entry-preview>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="subpage.subpageType && subpage.subpageType.technicalName === subpageTechnicalNameEnum.Contact">
      <mypb-block-contact [profile]="profile"></mypb-block-contact>
    </ng-container>
  </div>
</article>
