import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';
import { LoggerService } from '../_common/logger/logger.service';

@Directive({
  selector: '[autofocusFirstInput]',
})
export class AutofocusDirective implements AfterViewInit {
  constructor(private readonly elementRef: ElementRef, private readonly renderer2: Renderer2) {
  }

  ngAfterViewInit(): void {
    try {
      setTimeout(() => {
        const inputElement = this.elementRef.nativeElement.querySelector('input');
        if (inputElement) {
          this.renderer2.selectRootElement(inputElement).focus();
        }
      }, 100);
    } catch (noNativeElement) {
      LoggerService.LOG(this, 'autofocus directive not working on this of element.');
    }
  }
}
