/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MypbTemplate } from '../models/mypb-template';

@Injectable({
  providedIn: 'root',
})
export class MypbContentDeliveryTemplatesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation templatesDeliveryControllerFindAll
   */
  static readonly TemplatesDeliveryControllerFindAllPath = '/v1/content-delivery/templates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesDeliveryControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesDeliveryControllerFindAll$Response(params?: {

    /**
     * This request requires a valid API key.
     */
    'x-api-key'?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<MypbTemplate>>> {

    const rb = new RequestBuilder(this.rootUrl, MypbContentDeliveryTemplatesService.TemplatesDeliveryControllerFindAllPath, 'get');
    if (params) {
      rb.header('x-api-key', params['x-api-key'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MypbTemplate>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesDeliveryControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesDeliveryControllerFindAll(params?: {

    /**
     * This request requires a valid API key.
     */
    'x-api-key'?: string;
    context?: HttpContext
  }
): Observable<Array<MypbTemplate>> {

    return this.templatesDeliveryControllerFindAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MypbTemplate>>) => r.body as Array<MypbTemplate>)
    );
  }

  /**
   * Path part for operation templatesDeliveryControllerFindAllByDomainName
   */
  static readonly TemplatesDeliveryControllerFindAllByDomainNamePath = '/v1/content-delivery/templates/byDomainName/{domainName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesDeliveryControllerFindAllByDomainName()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesDeliveryControllerFindAllByDomainName$Response(params: {
    domainName: string;

    /**
     * This request requires a valid API key.
     */
    'x-api-key'?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<MypbTemplate>>> {

    const rb = new RequestBuilder(this.rootUrl, MypbContentDeliveryTemplatesService.TemplatesDeliveryControllerFindAllByDomainNamePath, 'get');
    if (params) {
      rb.path('domainName', params.domainName, {});
      rb.header('x-api-key', params['x-api-key'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MypbTemplate>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesDeliveryControllerFindAllByDomainName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesDeliveryControllerFindAllByDomainName(params: {
    domainName: string;

    /**
     * This request requires a valid API key.
     */
    'x-api-key'?: string;
    context?: HttpContext
  }
): Observable<Array<MypbTemplate>> {

    return this.templatesDeliveryControllerFindAllByDomainName$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MypbTemplate>>) => r.body as Array<MypbTemplate>)
    );
  }

}
