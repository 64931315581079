import { Injectable } from '@angular/core';
import { Action, NgxsAfterBootstrap, Selector, State, StateContext } from '@ngxs/store';
import { AppStateModel } from './app.state.model';
import {
  AppStateBackdropClicked,
  AppStateHasPageChanged,
  AppStateIsMyPage,
  AppStateIsSaving,
  AppStateSetAuthentication,
  AppStateSetDomainAndPage,
  AppStateSetProfile,
  AppStateSetRouteParams,
  AppStateSetTechnicalProfile,
  AppStateToggleBackdrop,
  AppStateToggleEditMode,
} from './app.actions';
import { MypbAuthenticationService, MypbContentManagementProfilesService, MypbProfile, MypbProfileDto, MypbWpPmsMemberSubscription } from '../../_generated/mypagebuilder-rest-api';
import { Params } from '@angular/router';

const STATE_NAME = 'MYPB__AppState';
const DEFAULT_STATE: AppStateModel = {
  domainName: '',
  domainId: '',
  pageName: '',
  pageId: '',
  isEditModeEnabled: false,
  isBackdropVisible: false,
  authentication: {
    accessToken: '',
    refreshToken: '',
  },
  technicalProfile: undefined,
  profile: undefined,
  profiles: [],
  routeParams: undefined,
  isSaving: false,
  isMyPage: false,
};

@State<AppStateModel>({
  name: STATE_NAME,
  defaults: DEFAULT_STATE,
})
@Injectable()
export class AppState implements NgxsAfterBootstrap {
  constructor(
    private readonly mypbAuthenticationService: MypbAuthenticationService,
    private readonly mypbContentManagementProfilesService: MypbContentManagementProfilesService,
  ) {
  }

  @Selector()
  static subscription(state: AppStateModel): MypbWpPmsMemberSubscription | undefined {
    if (state.technicalProfile && state.isMyPage && !!(state.authentication && state.authentication.accessToken)) {
      try {
        return state.technicalProfile.meta.subscriptions[0];
      } catch (noSubscriptions) {
        return undefined;
      }
    }
    return undefined;
  }

  @Selector()
  static isMyPage(state: AppStateModel): boolean {
    return state.isMyPage && !!(state.authentication && state.authentication.accessToken);
  }

  @Selector()
  static isSaving(state: AppStateModel): boolean {
    return state.isSaving;
  }

  @Selector()
  static rootPath(state: AppStateModel): string {
    return `/${state.pageName}`;
  }

  @Selector()
  static pageName(state: AppStateModel): string {
    return state.pageName;
  }

  @Selector()
  static pageId(state: AppStateModel): string {
    return state.pageId;
  }

  @Selector()
  static domainName(state: AppStateModel): string {
    return state.domainName;
  }

  @Selector()
  static domainId(state: AppStateModel): string {
    return state.domainId;
  }

  @Selector()
  static isLoggedIn(state: AppStateModel): boolean {
    return !!(state.authentication && state.authentication.accessToken);
  }

  @Selector()
  static isEditModeEnabled(state: AppStateModel): boolean {
    return state.isEditModeEnabled && state.isMyPage && !!(state.authentication && state.authentication.accessToken);
  }

  @Selector()
  static isBackdropVisible(state: AppStateModel): boolean {
    return state.isBackdropVisible;
  }

  @Selector()
  static refreshToken(state: AppStateModel): string {
    return state.authentication.refreshToken;
  }

  @Selector()
  static accessToken(state: AppStateModel): string {
    return state.authentication.accessToken;
  }

  @Selector()
  static technicalProfile(state: AppStateModel): MypbProfileDto | undefined {
    return state.technicalProfile;
  }

  @Selector()
  static profile(state: AppStateModel): MypbProfile | undefined {
    return state.profile;
  }

  @Selector()
  static profiles(state: AppStateModel): MypbProfile[] {
    return state.profiles;
  }

  @Selector()
  static routeParams(state: AppStateModel): Params | undefined {
    return state.routeParams;
  }

  @Action(AppStateToggleEditMode)
  appStateToggleEditMode(ctx: StateContext<AppStateModel>) {
    const isEditModeEnabled = ctx.getState().isEditModeEnabled;
    return ctx.patchState({
      isEditModeEnabled: !isEditModeEnabled,
    });
  }

  @Action(AppStateToggleBackdrop)
  appStateToggleBackdrop(ctx: StateContext<AppStateModel>, action: AppStateToggleBackdrop) {
    return ctx.patchState({
      isBackdropVisible: action.isVisible,
    });
  }

  @Action(AppStateSetDomainAndPage)
  appStateSetDomainAndPage(ctx: StateContext<AppStateModel>, action: AppStateSetDomainAndPage) {
    return ctx.patchState({
      domainName: action.domainName,
      domainId: action.domainId,
      pageName: action.pageName,
      pageId: action.pageId,
    });
  }

  @Action(AppStateSetAuthentication)
  appStateSetAuthentication(ctx: StateContext<AppStateModel>, action: AppStateSetAuthentication) {
    ctx.patchState({
      authentication: {
        accessToken: action.accessToken,
        refreshToken: action.refreshToken,
      },
    });
    return this.loadAndSetProfile(ctx);
  }

  @Action(AppStateSetTechnicalProfile)
  appStateSetTechnicalProfile(ctx: StateContext<AppStateModel>, action: AppStateSetTechnicalProfile) {
    return ctx.patchState({
      technicalProfile: action.technicalProfile,
    });
  }

  @Action(AppStateSetProfile)
  appStateSetProfile(ctx: StateContext<AppStateModel>, action: AppStateSetProfile) {
    return ctx.patchState({
      profile: action.profile,
    });
  }

  @Action(AppStateBackdropClicked)
  appStateBackdropClicked(ctx: StateContext<AppStateModel>, action: AppStateBackdropClicked) {
    return ctx.patchState({
      isBackdropVisible: false,
    });
  }

  @Action(AppStateSetRouteParams)
  appStateRouteHasChanged(ctx: StateContext<AppStateModel>, action: AppStateSetRouteParams) {
    return ctx.patchState({
      routeParams: action.routeParams,
    });
  }

  @Action(AppStateIsSaving)
  appStateIsSaving(ctx: StateContext<AppStateModel>, action: AppStateIsSaving) {
    return ctx.patchState({
      isSaving: action.isSaving,
    });
  }

  @Action(AppStateHasPageChanged)
  appStateHasPageLoaded(ctx: StateContext<AppStateModel>) {
    return true;
  }

  @Action(AppStateIsMyPage)
  appStateIsMyPage(ctx: StateContext<AppStateModel>, action: AppStateIsMyPage) {
    return ctx.patchState({
      isMyPage: action.isMyPage,
    });
  }


  ngxsAfterBootstrap(ctx: StateContext<AppStateModel>) {
    this.setDefaults(ctx);
    this.loadAndSetProfile(ctx);
  }

  private loadAndSetProfile(ctx: StateContext<AppStateModel>) {
    if (ctx.getState().authentication && ctx.getState().authentication.accessToken) {
      this.mypbAuthenticationService.authenticationControllerProfile().subscribe({
        next: technicalProfile => {
          if (technicalProfile) {
            ctx.dispatch(new AppStateSetTechnicalProfile(technicalProfile));
          }
        },
      });
      this.mypbContentManagementProfilesService.profilesControllerFindAll().subscribe({
        next: profiles => {
          ctx.patchState({ profiles: profiles });
        },
      });
    }
  }

  private setDefaults(ctx: StateContext<AppStateModel>) {
    ctx.patchState({
      isBackdropVisible: false,
      isSaving: false,
    });
  }
}
