/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MypbCreateVideoDto } from '../models/mypb-create-video-dto';
import { MypbVideo } from '../models/mypb-video';

@Injectable({
  providedIn: 'root',
})
export class MypbContentManagementVideosService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation videosControllerCreate
   */
  static readonly VideosControllerCreatePath = '/v1/content-management/videos';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videosControllerCreate()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  videosControllerCreate$Response(params: {
    context?: HttpContext
    body: MypbCreateVideoDto
  }
): Observable<StrictHttpResponse<MypbVideo>> {

    const rb = new RequestBuilder(this.rootUrl, MypbContentManagementVideosService.VideosControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MypbVideo>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videosControllerCreate$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  videosControllerCreate(params: {
    context?: HttpContext
    body: MypbCreateVideoDto
  }
): Observable<MypbVideo> {

    return this.videosControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<MypbVideo>) => r.body as MypbVideo)
    );
  }

  /**
   * Path part for operation videosControllerRemove
   */
  static readonly VideosControllerRemovePath = '/v1/content-management/videos/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videosControllerRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  videosControllerRemove$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MypbContentManagementVideosService.VideosControllerRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videosControllerRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  videosControllerRemove(params: {
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.videosControllerRemove$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
