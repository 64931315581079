import { Inject, Injectable, Optional } from '@angular/core';
import { MypbContentDeliveryPagesService, MypbPage, MypbSubpage } from '../_generated/mypagebuilder-rest-api';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { LoggerService } from '../_common/logger/logger.service';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { GetImageFromUri } from '../pipes/get-image-from-uri.pipe';
import { lastValueFrom } from 'rxjs';
import { Store } from '@ngxs/store';
import { AppState } from '../state/app/app.state';
import { AppStateIsMyPage, AppStateSetProfile } from '../state/app/app.actions';

export interface SsrPageResolverResponse {
  page: MypbPage,
  subpages: MypbSubpage[],
  currentSubpage: MypbSubpage,
}

@Injectable({
  providedIn: 'root',
})
export class SsrPageResolverService {
  constructor(
    private store: Store,
    private router: Router,
    private mypbContentDeliveryPagesService: MypbContentDeliveryPagesService,
    private title: Title,
    private meta: Meta,
    private getImageFromUri: GetImageFromUri,
    @Optional() @Inject('X_FORWARDED_HOST') private xForwardedHost: string,
    @Optional() @Inject('X_API_SECRET') private xApiSecret: string,
  ) {
    LoggerService.LOG(this, 'X_FORWARDED_HOST', xForwardedHost);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<SsrPageResolverResponse> {
    return new Promise<SsrPageResolverResponse>(async (resolve, reject) => {
      /**
       * Read routing parameters for fetching the correct data.
       */
      const domainName = route.params['domainName'] || this.getDomainForXForwardedHost();
      const pageName = route.params['pageName'];
      let slug = route.params['subslug'] || route.params['slug'] || '';

      /**
       * If it is "my page", skip the content delivery requests and open page directly,
       * where all information will be fetched via content management API.
       */
      if (this.setIsMyPage(pageName, domainName)) {
        resolve({
          page: {} as any,
          subpages: [],
          currentSubpage: {} as any,
        });
        return;
      }

      try {
        /**
         * Fetch all data of the page.
         */
        const page = await lastValueFrom(this.mypbContentDeliveryPagesService.pagesDeliveryControllerFindOneByDomainNameAndPageName({
          domainName: domainName,
          pageName: pageName,
          'x-api-secret': this.getXApiSecret(),
        }));

        /**
         * Set HTML meta tages based on page and domain.
         */
        this.setMetaTags(page, domainName);

        if (this.store.selectSnapshot(AppState.isMyPage)) {
          resolve({ page: page, subpages: [], currentSubpage: {} as any });
          return;
        }

        /**
         * Fetch navigation for this page.
         */
        const subpages = await lastValueFrom(this.mypbContentDeliveryPagesService.pagesDeliveryControllerGetNavigationByDomainNameAndPageName({
          domainName: domainName,
          pageName: pageName,
          'x-api-secret': this.getXApiSecret(),
        }));

        /**
         * Fetch navigation for this page.
         */
        if (!slug && subpages && subpages.length) {
          slug = subpages[0].slug;
        }
        const currentSubpage = await lastValueFrom(this.mypbContentDeliveryPagesService.pagesDeliveryControllerGetSubpage({
          domainName: domainName,
          pageName: pageName,
          slug: slug,
          'x-api-secret': this.getXApiSecret(),
        }));

        /**
         * Fetch children.
         */
        currentSubpage.children = await lastValueFrom(this.mypbContentDeliveryPagesService.pagesDeliveryControllerGetSubpageChildren({
          domainName: domainName,
          pageName: pageName,
          slug: currentSubpage.slug,
          'x-api-secret': this.getXApiSecret(),
        }));

        /**
         * Resolve if everything went well so far.
         */
        resolve({
          page: page,
          subpages: subpages,
          currentSubpage: currentSubpage,
        });

      } catch (pageResolvingException) {
        LoggerService.ERROR(this, 'pageResolvingException', pageResolvingException);
        this.handlePageDeliveryException(pageResolvingException, domainName, pageName);
        reject('pageResolvingException');
      }
    });
  }

  /**
   * Set meta tags based on page and domain information.
   *
   * @param page
   * @param domainName
   * @private
   */
  private setMetaTags(page: MypbPage, domainName: string) {
    this.title.setTitle(`${page.profile.firstname} ${page.profile.lastname} - ${page.profile.contentTitle} - ${domainName}`);
    this.meta.addTag({
      property: 'og:title',
      content: `${page.profile.firstname} ${page.profile.lastname} - ${page.profile.contentTitle} - ${domainName}`,
    });
    if (page.profile.contentImage) {
      this.meta.addTag({
        property: 'og:image',
        content: this.getImageFromUri.transform(page.profile.contentImage.uri, 1200),
      });
    }
    this.meta.addTag({
      property: 'og:description',
      content: `${page.profile.contentLeadtext}. Die ${domainName} von ${page.profile.firstname} ${page.profile.lastname}.`,
    });
    this.meta.addTag({
      name: 'description',
      content: `${page.profile.contentLeadtext}. Die ${domainName} von ${page.profile.firstname} ${page.profile.lastname}.`,
    });
    this.meta.addTag({
      name: 'keywords',
      content: `${domainName}, ${page.profile.firstname} ${page.profile.lastname}, ${page.template.name}`,
    });
  }

  /**
   * The XForwardedHost is the actual hostname of the system where the application
   * is currently running on. Can be localhost, or some NodeJS server somewhere in the cloud.
   *
   * By default, it will just use the actual host (like 'polit.page' or 'partei.app').
   * For specific test systems or similar, we have to map it manually.
   *
   * @private
   */
  public getDomainForXForwardedHost(): string {
    if (!this.xForwardedHost) {
      return 'polit.page';
    }
    if (this.xForwardedHost.indexOf('politpage') > -1 || this.xForwardedHost.indexOf('polit.page') > -1) {
      return 'polit.page';
    }
    if (this.xForwardedHost.indexOf('parteiapp') > -1 || this.xForwardedHost.indexOf('partei.app') > -1) {
      return 'partei.app';
    }
    if (this.xForwardedHost.indexOf('paolopage') > -1 || this.xForwardedHost.indexOf('paolo.page') > -1) {
      return 'paolo.page';
    }
    return 'polit.page';
  }

  /**
   * Returns the API Secret from node environment (SSR) or angular environment (CSR).
   *
   * @private
   */
  private getXApiSecret(): string {
    if (this.xApiSecret) {
      return this.xApiSecret;
    }
    return environment.apiConfiguration.apiSecret;
  }

  /**
   * This method redirects to the appropriate error/maintenance page based on exception.
   *
   * @param exception
   * @param domainname
   * @param pagename
   * @private
   */
  private handlePageDeliveryException(exception: any, domainname: string = '', pagename: string = '') {
    try {
      switch (exception.status) {
        case 400:
        case 401:
        case 402:
        case 403:
        case 404:
        case '400':
        case '401':
        case '402':
        case '403':
        case '404':
          this.router.navigateByUrl(`/s/404?domain=${domainname}&page=${pagename}`).then();
          break;
        case 500:
        case 501:
        case 503:
        case 504:
        case '500':
        case '501':
        case '502':
        case '503':
        case '504':
          this.router.navigateByUrl(`/s/500?domain=${domainname}&page=${pagename}`).then();
          break;
        case 502:
          this.router.navigateByUrl(`/s/maintenance?domain=${domainname}&page=${pagename}`).then();
          break;
        default:
          this.router.navigateByUrl(`/s/404?domain=${domainname}&page=${pagename}`).then();
          break;
      }
    } catch (httpStatusNotRecognizedException) {
      this.router.navigateByUrl(`/?domain=${domainname}&page=${pagename}`).then();
    }
  }

  /**
   * Sets the flag 'isMyPage' if the page matches the profiles of the logged
   * in user.
   *
   * @param pageName
   * @param domainName
   * @private
   */
  private setIsMyPage(pageName: string, domainName: string) {
    const profiles = this.store.selectSnapshot(AppState.profiles);
    let isMyPage = false;
    try {
      profiles.map(profile => {
        profile.pages.map(page => {
          if (page.name === pageName && page.domain.name === domainName) {
            isMyPage = true;
            this.store.dispatch(new AppStateSetProfile(profile));
          }
        });
      });
      this.store.dispatch(new AppStateIsMyPage(isMyPage));
    } catch (notMyPageException) {
      this.store.dispatch(new AppStateIsMyPage(false));
    }
    return isMyPage;
  }
}
