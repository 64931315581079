<div class="mypb-upload">
  <ngx-dropzone (change)="onSelect($event)"
                [multiple]="allowMultiple"
                [accept]="allowedMimetypesString"
                [maxFileSize]="maxFilesizeInMegabytes * 1024 * 1024">
    <ngx-dropzone-label>Hier klicken oder {{allowedFilesString}} hierhin ziehen</ngx-dropzone-label>
    <ng-container *ngFor="let file of files">
      <ngx-dropzone-image-preview *ngIf="getTypeForFile(file) === 'image'"
                                  ngProjectAs="ngx-dropzone-preview"
                                  [file]="file"
                                  [removable]="true"
                                  (removed)="onRemove(file)">
      </ngx-dropzone-image-preview>
      <ngx-dropzone-video-preview *ngIf="getTypeForFile(file) === 'video'"
                                  ngProjectAs="ngx-dropzone-preview"
                                  [file]="file"
                                  [removable]="true"
                                  (removed)="onRemove(file)">
      </ngx-dropzone-video-preview>
      <ngx-dropzone-preview *ngIf="getTypeForFile(file) === 'document'"
                            ngProjectAs="ngx-dropzone-preview"
                            [file]="file"
                            [removable]="true"
                            (removed)="onRemove(file)">
        <ngx-dropzone-label>{{ file.name }}</ngx-dropzone-label>
      </ngx-dropzone-preview>
    </ng-container>
  </ngx-dropzone>
  <ng-container *ngIf="!autoUpload">
    <button class="btn btn-primary"
            (click)="save()"
            [disabled]="!files.length || isUploadingCount > 0">
      {{ 'global.ui.upload' | translate }}
      <i class="fal fa-spinner-third fa-spin" *ngIf="isUploadingCount > 0"></i>
    </button>
  </ng-container>
  <p *ngIf="isUploadingCount > 0" class="ngx-dropzone-uploading">
    <small>
      <div><i class="fal fa-spinner-third fa-spin" *ngIf="isUploadingCount > 0"></i></div>
      Das Hochladen von Dateien kann einen Moment dauern, bitte haben Sie einen Moment Geduld.
    </small>
  </p>
</div>
