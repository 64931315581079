<div class="mypb-page">
  <ng-container *ngFor="let stylesheet of stylesheets">
    <link rel="stylesheet" [href]="stylesheet">
  </ng-container>

  <!--Profile Designs-->
  <ng-container *ngIf="page.template" [ngSwitch]="page.template.profiledesign">
    <ng-container *ngSwitchCase="profileDesignEnum.$1">
      <mypb-profile-design-1 [profile]="page.profile" [template]="page.template"></mypb-profile-design-1>
    </ng-container>
    <ng-container *ngSwitchCase="profileDesignEnum.$2">
      <mypb-profile-design-2 [profile]="page.profile" [template]="page.template"></mypb-profile-design-2>
    </ng-container>
  </ng-container>

  <section id="mypb-page-main-block" class="mypb-page-main-block">
    <mypb-navigation [subpages]="subpagesForNavigation"></mypb-navigation>
    <mypb-subpage [subpage]="currentSubpage" [profile]="page.profile"></mypb-subpage>
  </section>
  <mypb-login-button></mypb-login-button>
  <div class="mypb-page-buttons">
    <mypb-apps-button></mypb-apps-button>
    <mypb-mode-switcher></mypb-mode-switcher>
    <mypb-home-button></mypb-home-button>
  </div>
  <mypb-footer></mypb-footer>
</div>
