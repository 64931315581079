import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { MatomoTracker } from '@ngx-matomo/tracker';
import { Store } from '@ngxs/store';
import { AppStateSetRouteParams } from '../state/app/app.actions';

@Injectable({
  providedIn: 'root',
})
export class PageMetadataService {

  constructor(
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private readonly matomoTracker: MatomoTracker,
  ) {
  }

  public listenForRouteChanges(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
        if (child) {
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
        }
        this.trackPageView(child?.snapshot.params);
        this.store.dispatch(new AppStateSetRouteParams(child?.snapshot.params));
        return '';
      }),
    ).subscribe((ttl: string) => {
      if (ttl) {
        this.titleService.setTitle(ttl);
      }
    });
  }


  private trackPageView(routeParams: Params | undefined) {
    if (routeParams) {
      try {
        let path = `${routeParams['pageName']}`;
        if (routeParams['slug']) {
          path += `/${routeParams['slug']}`;
        }
        if (routeParams['subslug']) {
          path += `/${routeParams['subslug']}`;
        }
        this.matomoTracker.setCustomUrl(path);
        this.matomoTracker.trackPageView();
      } catch (noPath) {
        // Nothing to do.
      }
    }
  }
}
