<header class="mypb-navigation mypb-navigation-sticky mypb-page-grid"
        #navigationElement
        [class.nav-more]="showMoreSubpagesVisible"
        [class.nav-more-active]="showMoreSubpagesVisibleActive">
  <div class="nav-more-close btn-close" (click)="toggleShowMoreSubpagesVisibleActive()"></div>
  <nav #navElement class="mypb-navigation-nav">
    <ul #ulElement
        cdkDropList
        cdkDropListOrientation="horizontal"
        (cdkDropListDropped)="sortSubpages($event)"
        [cdkDropListDisabled]="!(isEditModeEnabled$ | async) || !(isMyPage$ | async)"
        cdkDropListLockAxis="x">
      <li #liElementList cdkDrag routerLinkActive="active"
          *ngFor="let subpage of subpages; let index = index;"
          [class.hidden-li]="shouldBeHidden(index)"
          [class.active]="index === 0 && isRootPage">
        <a routerLink="/{{rootPath$ | async}}/{{subpage.slug}}"
           (click)="scrollNavigationToTop(); toggleShowMoreSubpagesVisibleActive(true)">
          {{ subpage?.name }}
        </a>
        <ng-template cdkDragPlaceholder>
          <div class="drag-placeholder">
            {{ subpage?.name }}
          </div>
        </ng-template>
        <ng-template cdkDragPreview></ng-template>
      </li>
      <li #navMoreElement class="nav-more-element" (click)="toggleShowMoreSubpagesVisibleActive()">
        <div class="nav-more-btn"><i class="fal fa-ellipsis-h"></i></div>
      </li>
    </ul>
    <ng-container *ngIf="(isMyPage$ | async) && (isEditModeEnabled$ | async)">
      <div #navEditElement class="nav-edit" [class.active]="addSubpageMenuVisible"
           *ngIf="(isMyPage$ | async) && (isEditModeEnabled$ | async)">
        <mypb-add-subpage (add)="addSubpage($event)" [(showMenu)]="addSubpageMenuVisible"></mypb-add-subpage>
        <button class="btn-edit-nav btn-small-secondary" routerLink="/apps/seiten"><i class="fal fa-pencil"></i>
          Bearbeiten
        </button>
      </div>
    </ng-container>
  </nav>
</header>
