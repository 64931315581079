<div class="mypb-standalone-page">
  <div class="mypb-page-selector">
    <h2>Welche Seite möchtest du bearbeiten?</h2>
    <ul>
      <li *ngFor="let page of pages">
        <a [routerLink]="routingHelperService.getUrlForPage(page.name, page.domain.name, true)">
          {{ page.profile?.firstname }} {{ page.profile?.lastname }} - {{ page.domain.name }}/{{ page.name }}
        </a>
      </li>
    </ul>
  </div>
</div>
