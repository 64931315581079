import { GLOBALS } from './global.environment';

export const environment = GLOBALS;

// Overwrite settings for this environment.
environment.name = 'test';
environment.apiConfiguration = {
  baseUrl: 'https://test-api.mypagebuilder.ch',
  apiSecret: '3b49f89d5b650101a3da0ff7a8ae1bf42bcac2fcec452e8af094c1ed29f3825a842a27354ea8fc2d2712ea064fa90577',
  apiKey: 'd6b20907fad24401a9b1f7d86eff6a4dbd4adfe4',
};
environment.isLoggingEnabled.ngxs = true;
environment.isLoggingEnabled.logger = true;
environment.production = false;
