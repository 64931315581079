<div class="mypb-profile-app-video">
  <div *ngIf="profile.contentVideo" class="mypb-profile-app__upload">
    <span>Aktuelles Video ({{ profile.contentVideo.size / 1024 / 1024 | number:'1.0-1' }} MByte)</span>
    <div class="mypb-profile-app__upload-actions">
      <i class="fal fa-play" (click)="showVideo = true"></i>
      <i class="fal fa-trash" (click)="deleteVideo()"></i>
    </div>
  </div>
  <mypb-video-player *ngIf="profile.contentVideo && showVideo"
                     (videoStopped)="showVideo = false"
                     [videoUri]="profile.contentVideo.uri"></mypb-video-player>
  
  <div class="form-dropzone-field">               
    <mypb-upload [allowVideo]="true" (uploadedVideo)="onVideoUploaded($event)" [autoUpload]="true"></mypb-upload>
  </div>
</div>
