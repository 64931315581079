import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
  MypbBlockToImage,
  MypbContentManagementBlocksService,
  MypbSubpage,
} from '../../_generated/mypagebuilder-rest-api';
import { Store } from '@ngxs/store';

@Component({
  selector: 'mypb-blog-entry-preview',
  templateUrl: './blog-entry-preview.component.html',
  styleUrls: ['blog-entry-preview.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class BlogEntryPreviewComponent implements OnInit {
  @Input() subpage: MypbSubpage = {} as any;
  @Input() link = '#';
  public previewImage: MypbBlockToImage | undefined;

  constructor(
    private store: Store,
    private mypbContentManagementBlocksService: MypbContentManagementBlocksService,
  ) {
  }

  ngOnInit(): void {
    this.setPreviewImage();
  }

  private setPreviewImage() {
    try {
      for (const block of this.subpage.blocks) {
        if (block.images && block.images.length) {
          this.previewImage = block.images[0];
          return;
        }
      }
    } catch (noImages) {
    }
  }
}
