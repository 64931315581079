import { Component, Inject, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { AppState } from '../../state/app/app.state';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'mypb-login-button',
  templateUrl: './login-button.component.html',
  styleUrls: ['login-button.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginButtonComponent {
  public isLoggedIn$ = this.store.select(AppState.isLoggedIn);

  constructor(
    private readonly store: Store,
    private readonly router: Router,
  ) {
  }

  /**
   * Returns the full URL of the current location used for redirection after login.
   *
   * @private
   */
  get fullUrl() {
    return this.router.url;
  }
}
