<div class="mypb-apps-header">
  <div class="mypb-apps-header__back" [class.not-visible]="!canGoBack">
    <a (click)="goBack()" class="btn-back"></a>
  </div>
  <div class="mypb-apps-header__title" [class.not-visible]="!title">
    {{ title }}
  </div>
  <div class="mypb-apps-header__close" [class.not-visible]="!canClose">
    <a (click)="close()" class="btn-close"></a>
  </div>
</div>
