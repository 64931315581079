import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import {
  MypbContentManagementProfilesService,
  MypbImage,
  MypbProfile,
  MypbUpdateProfileDto,
} from '../../../_generated/mypagebuilder-rest-api';
import { Router } from '@angular/router';
import { ApiServerHelperService } from '../../../services/api-server-helper.service';
import { AppStateRefreshPageData, AppStateSetProfile } from '../../../state/app/app.actions';
import { LoggerService } from '../../../_common/logger/logger.service';
import { AppState } from '../../../state/app/app.state';

@Component({
  selector: 'mypb-onboarding-profile-page',
  templateUrl: './onboarding-profile.page.html',
  styleUrls: ['onboarding-profile.page.less'],
  encapsulation: ViewEncapsulation.None,
})
export class OnboardingProfilePage implements OnInit {
  public profileForm: FormGroup;
  public profile: MypbProfile | undefined;

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly apiServerHelperService: ApiServerHelperService,
    private readonly mypbContentManagementProfilesService: MypbContentManagementProfilesService,
  ) {
    this.profileForm = new FormGroup({
      contentTitle: new FormControl('', [Validators.required]),
      contentLeadtext: new FormControl('', [Validators.required]),
      contentImageId: new FormControl('', []),
    });
  }

  async ngOnInit() {
    this.store.select(AppState.profile).subscribe({
      next: profile => {
        if (profile) {
          this.profileForm.patchValue(profile);
          this.profile = profile;
        }
      },
    });
  }

  ngOnDestroy() {
  }

  public onImageUploaded(image: MypbImage) {
    this.profileForm.patchValue({ contentImageId: image.id });
    if (this.profile) {
      this.profile.contentImage = image;
    }
  }

  saveProfile() {
    const profile = this.store.selectSnapshot(AppState.profile);
    if (this.profileForm.valid && profile) {
      const dto: MypbUpdateProfileDto = {
        ...this.profileForm.value,
      };
      this.mypbContentManagementProfilesService.profilesControllerUpdate({
        id: profile.id,
        body: dto,
      }).subscribe({
        next: async updatedProfile => {
          this.store.dispatch(new AppStateRefreshPageData());
          this.store.dispatch(new AppStateSetProfile(updatedProfile));
          await this.router.navigateByUrl('/page-selector');
        },
        error: profileUpdateException => {
          LoggerService.ERROR(this, 'profileUpdateException', profileUpdateException);
        },
      });
    }
  }
}
