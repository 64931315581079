import { Component, ElementRef, EventEmitter, HostListener, Inject, OnInit, Optional, Output, ViewEncapsulation } from '@angular/core';
import { MypbBlockType, MypbContentDeliveryBlockTypesService } from '../../_generated/mypagebuilder-rest-api';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'mypb-add-block',
  templateUrl: './add-block.component.html',
  styleUrls: ['add-block.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class AddBlockComponent implements OnInit {
  @Output() addBlock: EventEmitter<MypbBlockType> = new EventEmitter<MypbBlockType>();
  static BLOCK_TYPES: MypbBlockType[] = [];
  public showMenu = false;
  public blockTypes: MypbBlockType[] = [];

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.showMenu = false;
    }
  }

  constructor(
    private mypbContentDeliveryBlockTypesService: MypbContentDeliveryBlockTypesService,
    @Optional() @Inject('X_API_KEY') private xApiKey: string,
    private elementRef: ElementRef,
  ) {
  }

  ngOnInit() {
    if (!AddBlockComponent.BLOCK_TYPES.length) {
      this.initializeBlockTypes();
    } else {
      this.blockTypes = AddBlockComponent.BLOCK_TYPES;
    }
  }

  public toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  public addBlockOfType(blockType: MypbBlockType) {
    this.addBlock.emit(blockType);
    this.showMenu = false;
  }

  private initializeBlockTypes() {
    this.mypbContentDeliveryBlockTypesService.blockTypesDeliveryControllerFindAll({
      'x-api-key': this.getXApiKey(),
    })
      .subscribe({
        next: blocktypes => {
          this.blockTypes = blocktypes;
          AddBlockComponent.BLOCK_TYPES = blocktypes;
        },
      });
  }

  /**
   * Returns the API Secret from node environment (SSR) or angular environment (CSR).
   *
   * @private
   */
  private getXApiKey(): string {
    if (this.xApiKey) {
      return this.xApiKey;
    }
    return environment.apiConfiguration.apiKey;
  }
}
