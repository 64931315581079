/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MypbSendMessageDto } from '../models/mypb-send-message-dto';

@Injectable({
  providedIn: 'root',
})
export class MypbContentDeliveryFormsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation formsDeliveryControllerSendMessage
   */
  static readonly FormsDeliveryControllerSendMessagePath = '/v1/content-delivery/forms/sendMessage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formsDeliveryControllerSendMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  formsDeliveryControllerSendMessage$Response(params: {

    /**
     * This request requires a valid API key.
     */
    'x-api-key'?: string;
    context?: HttpContext
    body: MypbSendMessageDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MypbContentDeliveryFormsService.FormsDeliveryControllerSendMessagePath, 'post');
    if (params) {
      rb.header('x-api-key', params['x-api-key'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `formsDeliveryControllerSendMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  formsDeliveryControllerSendMessage(params: {

    /**
     * This request requires a valid API key.
     */
    'x-api-key'?: string;
    context?: HttpContext
    body: MypbSendMessageDto
  }
): Observable<boolean> {

    return this.formsDeliveryControllerSendMessage$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
