import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { LoggerService } from '../../_common/logger/logger.service';

@Component({
  selector: 'mypb-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['maintenance.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class MaintenanceComponent implements OnInit {
  public retryLink = 'https://mypagebuilder.ch';

  constructor(
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      try {
        this.retryLink = `https://${params['domain']}/${params['page']}`;
      } catch (noParamsException) {
        LoggerService.ERROR(this, 'noParamsException', noParamsException);
      }
    });
  }
}
