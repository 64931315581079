import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mypb-profile-app-links',
  templateUrl: './profile-app-links.component.html',
  styleUrls: ['profile-app-links.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileAppLinksComponent {
  constructor() {
  }
}
