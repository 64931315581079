/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MypbPage } from '../models/mypb-page';

@Injectable({
  providedIn: 'root',
})
export class MypbContentManagementPagesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation pagesControllerFindAll
   */
  static readonly PagesControllerFindAllPath = '/v1/content-management/pages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pagesControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  pagesControllerFindAll$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<MypbPage>>> {

    const rb = new RequestBuilder(this.rootUrl, MypbContentManagementPagesService.PagesControllerFindAllPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MypbPage>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `pagesControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pagesControllerFindAll(params?: {
    context?: HttpContext
  }
): Observable<Array<MypbPage>> {

    return this.pagesControllerFindAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MypbPage>>) => r.body as Array<MypbPage>)
    );
  }

  /**
   * Path part for operation pagesControllerFindOneById
   */
  static readonly PagesControllerFindOneByIdPath = '/v1/content-management/pages/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pagesControllerFindOneById()` instead.
   *
   * This method doesn't expect any request body.
   */
  pagesControllerFindOneById$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<MypbPage>> {

    const rb = new RequestBuilder(this.rootUrl, MypbContentManagementPagesService.PagesControllerFindOneByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MypbPage>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `pagesControllerFindOneById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pagesControllerFindOneById(params: {
    id: string;
    context?: HttpContext
  }
): Observable<MypbPage> {

    return this.pagesControllerFindOneById$Response(params).pipe(
      map((r: StrictHttpResponse<MypbPage>) => r.body as MypbPage)
    );
  }

  /**
   * Path part for operation pagesControllerFindOneByDomainAndPageName
   */
  static readonly PagesControllerFindOneByDomainAndPageNamePath = '/v1/content-management/pages/{domainName}/{pageName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pagesControllerFindOneByDomainAndPageName()` instead.
   *
   * This method doesn't expect any request body.
   */
  pagesControllerFindOneByDomainAndPageName$Response(params: {
    domainName: string;
    pageName: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<MypbPage>> {

    const rb = new RequestBuilder(this.rootUrl, MypbContentManagementPagesService.PagesControllerFindOneByDomainAndPageNamePath, 'get');
    if (params) {
      rb.path('domainName', params.domainName, {});
      rb.path('pageName', params.pageName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MypbPage>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `pagesControllerFindOneByDomainAndPageName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pagesControllerFindOneByDomainAndPageName(params: {
    domainName: string;
    pageName: string;
    context?: HttpContext
  }
): Observable<MypbPage> {

    return this.pagesControllerFindOneByDomainAndPageName$Response(params).pipe(
      map((r: StrictHttpResponse<MypbPage>) => r.body as MypbPage)
    );
  }

}
