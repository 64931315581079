import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MypbContentManagementProfilesService, MypbProfile } from '../../../../_generated/mypagebuilder-rest-api';
import { LoggerService } from '../../../../_common/logger/logger.service';
import { AppStateIsSaving, AppStateRefreshPageData } from '../../../../state/app/app.actions';
import { Store } from '@ngxs/store';

@Component({
  selector: 'mypb-profile-app-base',
  templateUrl: './profile-app-base.component.html',
  styleUrls: ['profile-app-base.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileAppBaseComponent {
  @Input() profile: MypbProfile = {} as any;
  public form: FormGroup;

  constructor(
    private store: Store,
    private mypbContentManagementProfilesService: MypbContentManagementProfilesService,
  ) {
    this.form = new FormGroup({
      firstname: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(50),
      ]),
      lastname: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(50),
      ]),
      contentTitle: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100),
      ]),
      contentLeadtext: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(250),
      ]),
    });
  }

  ngOnInit() {
    if (this.profile) {
      this.form.patchValue(this.profile);
    }
  }

  public saveProfile() {
    if (this.profile && this.profile.id && this.form.valid) {
      this.store.dispatch(new AppStateIsSaving(true));
      this.mypbContentManagementProfilesService.profilesControllerUpdate({
        id: this.profile.id,
        body: this.form.value,
      }).subscribe({
        next: profileUpdatedResult => {
          LoggerService.LOG(this, 'profileUpdatedResult', profileUpdatedResult);
          this.store.dispatch(new AppStateRefreshPageData());
        },
        error: profileUpdateException => {
          LoggerService.ERROR(this, 'profileUpdateException', profileUpdateException);
        },
        complete: () => {
          this.store.dispatch(new AppStateIsSaving(false));
        },
      });
    }
  }
}
