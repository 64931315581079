<a class="mypb-blog-entry-preview" [routerLink]="link">
  <div class="mypb-blog-entry-preview-image-wrap">
    <img *ngIf="previewImage && previewImage.image"
         [src]="previewImage.image.uri | getImageFromUri:160:160:true"
         [alt]="previewImage.image.caption"
         class="mypb-blog-entry-preview-image">
  </div>
  <div class="mypb-blog-entry-preview-content">
    <div class="mypb-blog-entry-preview__date">{{ subpage.createdAt | date }}</div>
    <h2 class="mypb-blog-entry-preview__title">{{ subpage.name }}</h2>
  </div>
</a>
