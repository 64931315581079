import { Component, ElementRef, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalService } from './modal.service';
import { isPlatformBrowser } from '@angular/common';
import { AppComponent } from '../../app.component';
import { Store } from '@ngxs/store';
import { AppStateToggleBackdrop } from '../../state/app/app.actions';

@Component({
  selector: 'mypb-modal',
  templateUrl: 'modal.component.html',
  styleUrls: ['modal.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() id: string = '';
  private element: any;
  private isOpen: boolean = false;

  constructor(
    private modalService: ModalService,
    private el: ElementRef,
    private store: Store,
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    // ensure id attribute exists
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    if (isPlatformBrowser(AppComponent.PLATFORM_ID)) {
      document.body.appendChild(this.element);
    }

    // close modal on background click
    this.element.addEventListener('click', (el: { target: { className: string; }; }) => {
      if (el.target.className === 'mypb-modal') {
        this.close();
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
  }

  // remove self from modal service when component is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
    if (this.isOpen) {
      this.store.dispatch(new AppStateToggleBackdrop(false));
    }
  }

  // open modal
  open(): void {
    this.element.style.display = 'block';
    this.isOpen = true;
    if (isPlatformBrowser(AppComponent.PLATFORM_ID)) {
      document.body.classList.add('mypb-modal-open');
      this.store.dispatch(new AppStateToggleBackdrop(true));
    }
  }

  // close modal
  close(): void {
    this.element.style.display = 'none';
    this.isOpen = false;
    if (isPlatformBrowser(AppComponent.PLATFORM_ID)) {
      document.body.classList.remove('mypb-modal-open');
      this.store.dispatch(new AppStateToggleBackdrop(false));
    }
  }
}
