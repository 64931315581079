<div class="mypb-onboarding-profile-page">
  <form [formGroup]="profileForm" (ngSubmit)="saveProfile()" novalidate>
    <mypb-profile-app-picture *ngIf="profile" [profile]="profile"></mypb-profile-app-picture>
    <div class="form-group form-group-large">
      <label for="contentTitle">{{ 'profile.contentTitle' | translate }}</label>
      <input type="text" id="contentTitle" class="form-control" formControlName="contentTitle"
             placeholder="{{ 'profile.contentTitle' | translate }}">
      <small class="form-text text-muted"
             *ngIf="profileForm.controls['contentTitle'].touched && profileForm.controls['contentTitle'].hasError('required')">
        {{ 'profile.contentTitle' | translate }}
      </small>
    </div>
    <div class="form-group form-group-large">
      <label for="contentLeadtext">{{ 'profile.contentLeadtext' | translate }}</label>
      <input type="text" id="contentLeadtext" class="form-control" formControlName="contentLeadtext"
             placeholder="{{ 'profile.contentLeadtext' | translate }}">
      <small class="form-text text-muted"
             *ngIf="profileForm.controls['contentLeadtext'].touched && profileForm.controls['contentLeadtext'].hasError('required')">
        {{ 'profile.contentLeadtext' | translate }}
      </small>
    </div>
    <button type="submit"
            class="btn btn-primary"
            [disabled]="!profileForm.valid">
      Meine Polit-Page öffnen
    </button>
  </form>
</div>
