/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MypbAddImageToBlockDto } from '../models/mypb-add-image-to-block-dto';
import { MypbBlockToImage } from '../models/mypb-block-to-image';
import { MypbRemoveImageFromBlockDto } from '../models/mypb-remove-image-from-block-dto';

@Injectable({
  providedIn: 'root',
})
export class MypbContentManagementBlockToImageService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation blockToImageControllerAdd
   */
  static readonly BlockToImageControllerAddPath = '/v1/content-management/block-to-image';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `blockToImageControllerAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  blockToImageControllerAdd$Response(params: {
    context?: HttpContext
    body: MypbAddImageToBlockDto
  }
): Observable<StrictHttpResponse<MypbBlockToImage>> {

    const rb = new RequestBuilder(this.rootUrl, MypbContentManagementBlockToImageService.BlockToImageControllerAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MypbBlockToImage>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `blockToImageControllerAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  blockToImageControllerAdd(params: {
    context?: HttpContext
    body: MypbAddImageToBlockDto
  }
): Observable<MypbBlockToImage> {

    return this.blockToImageControllerAdd$Response(params).pipe(
      map((r: StrictHttpResponse<MypbBlockToImage>) => r.body as MypbBlockToImage)
    );
  }

  /**
   * Path part for operation blockToImageControllerRemove
   */
  static readonly BlockToImageControllerRemovePath = '/v1/content-management/block-to-image';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `blockToImageControllerRemove()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  blockToImageControllerRemove$Response(params: {
    context?: HttpContext
    body: MypbRemoveImageFromBlockDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MypbContentManagementBlockToImageService.BlockToImageControllerRemovePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `blockToImageControllerRemove$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  blockToImageControllerRemove(params: {
    context?: HttpContext
    body: MypbRemoveImageFromBlockDto
  }
): Observable<void> {

    return this.blockToImageControllerRemove$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation blockToImageControllerUpdate
   */
  static readonly BlockToImageControllerUpdatePath = '/v1/content-management/block-to-image';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `blockToImageControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  blockToImageControllerUpdate$Response(params: {
    context?: HttpContext
    body: MypbAddImageToBlockDto
  }
): Observable<StrictHttpResponse<MypbBlockToImage>> {

    const rb = new RequestBuilder(this.rootUrl, MypbContentManagementBlockToImageService.BlockToImageControllerUpdatePath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MypbBlockToImage>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `blockToImageControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  blockToImageControllerUpdate(params: {
    context?: HttpContext
    body: MypbAddImageToBlockDto
  }
): Observable<MypbBlockToImage> {

    return this.blockToImageControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<MypbBlockToImage>) => r.body as MypbBlockToImage)
    );
  }

}
