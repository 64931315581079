<div class="mypb-standalone-page">
  <mypb-apps-header></mypb-apps-header>
  <div class="mypb-analytics-app  mypb-standalone-page__margin">

    <div class="mypb-grid-small mypb-dashboard-lead">
      <h1>Statistiken</h1>
    </div>

    <div class="mypb-grid-small">
      <h2>Besucher</h2>
      <ng-container *ngIf="analyticsData">
        <canvas baseChart
                [type]="'line'"
                [data]="lineChartData"
                [options]="lineChartOptions"
                [legend]="lineChartLegend">
        </canvas>
      </ng-container>
      <h2>Aufenthaltsdauer</h2>
      <ng-container *ngIf="analyticsData">
        <canvas baseChart
                [type]="'line'"
                [data]="lineChartDataAverageTimes"
                [options]="lineChartOptions"
                [legend]="lineChartLegend">
        </canvas>
      </ng-container>
    </div>
    
  </div>

  <div class="mypb-bg"><div class="mypb-bg-inner"></div></div>
</div>
