<div class="mypb-standalone-page">
  <div class="mypb-dataprotection">
    <div class="mypb-grid-wide">
      <h2>Impressum</h2>

      <p>
        Polit-Page ist ein Angebot der <a href="https://www.berchtold-marketing.ch/" target="_blank">Berchtold Marketing
        GmbH</a> in Kooperation mit der <a href="https://digitalemenschen.ch/" target="_blank">Digitale Menschen GmbH</a>.
        <br>
        <br>
        <b>Kontakt:</b><br>
        <a href="mailto:info@berchtold-marketing.ch">info@berchtold-marketing.ch</a><br>
        <br>
        <i>Kontaktadresse</i><br>
        Berchtold Marketing GmbH<br>
        Gasstrasse 16<br>
        4410 Liestal<br>
        Schweiz<br>
        <br>
        Vertretungsberechtigte Personen<br>
        Rico Berchtold<br>
        <br>
        Handelsregistereintrag<br>
        Eingetragener Firmenname:<br>
        Berchtold Marketing GmbH<br>
        Handelsregister-Nummer: CH-280.4.020.751-7<br>
        UID/MWST: CHE-229.799.626<br>

      </p>

      <h2>Datenschutzerklärung </h2>

      <p>Diese Datenschutzerklärung klärt über die Art, den Umfang und Zweck der Verarbeitung (u.a. Erhebung,
        Verarbeitung und Nutzung sowie Einholung von Einwilligungen) von personenbezogenen Daten innerhalb unseres
        Onlineangebotes und der mit ihm verbundenen Webseiten, Funktionen und Inhalte (nachfolgend gemeinsam
        bezeichnet als „Onlineangebot“ oder „Website“) auf. Die Datenschutzerklärung gilt unabhängig von den
        verwendeten Domains, Systemen, Plattformen und Geräten (z.B. Desktop oder Mobile) auf denen das
        Onlineangebot ausgeführt wird.</p>

      <p>Der Begriff „Nutzer“ umfasst alle Kunden und Besucher unseres Onlineangebotes. Die verwendeten
        Begrifflichkeiten, wie z.B. „Nutzer“ sind geschlechtsneutral zu verstehen.</p>

      <p>Für die Kontaktmöglichkeiten verweisen wir auf unser Impressum.</p>

      <p><strong>Grundsätzliche Angaben zur Datenverarbeitung</strong><br>Wir verarbeiten personenbezogene Daten der
        Nutzer nur unter Einhaltung der einschlägigen Datenschutzbestimmungen entsprechend den Geboten der
        Datensparsamkeit- und Datenvermeidung. Das bedeutet die Daten der Nutzer werden nur beim Vorliegen einer
        gesetzlichen Erlaubnis, insbesondere wenn die Daten zur Erbringung unserer vertraglichen Leistungen sowie
        Online-Services erforderlich, bzw. gesetzlich vorgeschrieben sind oder beim Vorliegen einer Einwilligung
        verarbeitet.</p>

      <p>Wir treffen organisatorische, vertragliche und technische Sicherheitsmassnahmen entsprechend dem Stand der
        Technik, um sicher zu stellen, dass die Vorschriften der Datenschutzgesetze eingehalten werden und um damit
        die durch uns verarbeiteten Daten gegen zufällige oder vorsätzliche Manipulationen, Verlust, Zerstörung oder
        gegen den Zugriff unberechtigter Personen zu schützen.</p>

      <p>Sofern im Rahmen dieser Datenschutzerklärung Inhalte, Werkzeuge oder sonstige Mittel von anderen Anbietern
        (nachfolgend gemeinsam bezeichnet als „Dritt-Anbieter“) eingesetzt werden und deren genannter Sitz im
        Ausland ist, ist davon auszugehen, dass ein Datentransfer in die Sitzstaaten der Dritt-Anbieter stattfindet.
        Die Übermittlung von Daten in Drittstaaten erfolgt entweder auf Grundlage einer gesetzlichen Erlaubnis,
        einer Einwilligung der Nutzer oder spezieller Vertragsklauseln, die eine gesetzlich vorausgesetzte
        Sicherheit der Daten gewährleisten.</p>

      <p><strong>Verarbeitung personenbezogener Daten</strong><br>Personenbezogene Daten speichern wir vornehmlich
        zur Geschäfts- und Bestellabwicklung. Die personenbezogenen Daten werden, neben den ausdrücklich in dieser
        Datenschutzerklärung genannten Verwendung, für die folgenden Zwecke auf Grundlage gesetzlicher Erlaubnisse
        oder Einwilligungen der Nutzer verarbeitet:<br>– Die Zurverfügungstellung, Ausführung, Pflege, Optimierung
        und Sicherung unserer Dienste-, Service- und Nutzerleistungen;<br>– Die Gewährleistung eines effektiven
        Kundendienstes und technischen Supports.</p>

      <p>Wir übermitteln die Daten der Nutzer an Dritte nur, wenn dies für Abrechnungszwecke notwendig ist (z.B. an
        einen Zahlungs-/Versanddienstleiter) oder für andere Zwecke, wenn diese notwendig sind, um unsere
        vertraglichen Verpflichtungen gegenüber den Nutzern zu erfüllen (z.B. Adressmitteilung an Lieferanten).</p>

      <p>Bei der Kontaktaufnahme mit uns (per Kontaktformular oder E-Mail) werden die Angaben des Nutzers zwecks
        Bearbeitung der Anfrage sowie für den Fall, dass Anschlussfragen entstehen, gespeichert.<br>Personenbezogene
        Daten werden gelöscht, sofern sie ihren Verwendungszweck erfüllt haben und der Löschung keine
        Aufbewahrungspflichten entgegenstehen.</p>

      <p><strong>Erhebung von Zugriffsdaten</strong><br>Wir erheben Daten über jeden Zugriff auf den Server, auf dem
        sich dieser Dienst befindet (so genannte Serverlogfiles). Zu den Zugriffsdaten gehören Name der abgerufenen
        Webseite, Datei, Datum und Uhrzeit des Abrufs, übertragene Datenmenge, Meldung über erfolgreichen Abruf,
        Browsertyp nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite),
        IP-Adresse und der anfragende Provider.</p>

      <p>Wir verwenden die Protokolldaten ohne Zuordnung zur Person des Nutzers oder sonstiger Profilerstellung
        entsprechend den gesetzlichen Bestimmungen nur für statistische Auswertungen zum Zweck des Betriebs, der
        Sicherheit und der Optimierung unseres Onlineangebotes. Wir behalten uns jedoch vor, die Protokolldaten
        nachträglich zu überprüfen, wenn aufgrund konkreter Anhaltspunkte der berechtigte Verdacht einer
        rechtswidrigen Nutzung besteht.</p>

      <p><strong>Cookies &amp; Reichweitenmessung</strong><br>Cookies sind Informationen, die von unserem Webserver
        oder Webservern Dritter an die Web-Browser der Nutzer übertragen und dort für einen späteren Abruf
        gespeichert werden. Über den Einsatz von Cookies im Rahmen pseudonymer Reichweitenmessung werden die Nutzer
        im Rahmen dieser Datenschutzerklärung informiert.</p>

      <p>Die Betrachtung dieses Onlineangebotes ist auch unter Ausschluss von Cookies möglich. Falls die Nutzer
        nicht möchten, dass Cookies auf ihrem Rechner gespeichert werden, werden sie gebeten die entsprechende
        Option in den Systemeinstellungen ihres Browsers zu deaktivieren. Gespeicherte Cookies können in den
        Systemeinstellungen des Browsers gelöscht werden. Der Ausschluss von Cookies kann zu
        Funktionseinschränkungen dieses Onlineangebotes führen.</p>

      <p>Sie können dem Einsatz von Cookies, die der Reichweitenmessung und Werbezwecken dienen, über die
        Deaktivierungsseite der Netzwerkwerbeinitiative (<a href="http://optout.networkadvertising.org/">http://optout.networkadvertising.org/</a>)
        und zusätzlich die US-amerikanische Webseite (<a href="http://www.aboutads.info/choices">http://www.aboutads.info/choices</a>)&nbsp;oder
        die europäische Webseite&nbsp;(<a href="http://www.youronlinechoices.com/uk/your-ad-choices/">http://www.youronlinechoices.com/uk/your-ad-choices/</a>)
        widersprechen.</p>

      <p><strong>Matomo</strong><br>Wir setzen Matomo, einen Webanalysedienst des Unternehmens InnoCraft Ltd, 7 Waterloo
        Quay PO625, 6140 Wellington, Neuseeland ein.
        Mehr über die Verwendung der Daten die verarbeitet werden, erfahren Sie in der Privacy Policy auf <a
          href="https://matomo.org/privacy-policy/" target="_blank" rel="noopener">https://matomo.org/privacy-policy/</a>.
      </p>

      <p><strong>Newsletter</strong><br>Mit den nachfolgenden Hinweisen klären wir Sie über die Inhalte unseres
        Newsletters sowie das Anmelde-, Versand- und das statistische Auswertungsverfahren sowie Ihre
        Widerspruchsrechte auf. Indem Sie unseren Newsletter abonnieren, erklären Sie sich mit dem Empfang und den
        beschriebenen Verfahren einverstanden.</p>

      <p>Inhalt des Newsletters: Wir versenden Newsletter, E-Mails und weitere elektronische Benachrichtigungen mit
        werblichen Informationen (nachfolgend „Newsletter“) nur mit der Einwilligung der Empfänger oder einer
        gesetzlichen Erlaubnis. Sofern im Rahmen einer Anmeldung zum Newsletter dessen Inhalte konkret umschrieben
        werden, sind sie für die Einwilligung der Nutzer massgeblich. Im Übrigen enthalten unsere Newsletter
        folgende Informationen: Unsere Produkte, Angebote, Aktionen, News und Infos zu unser Unternehmen.</p>

      <p>Die E-Mail-Adressen unserer Newsletterempfänger, als auch deren weitere, im Rahmen dieser Hinweise
        beschriebenen Daten, werden auf den Servern des Versanddienstleisters gespeichert. Der Versanddienstleister
        verwendet diese Informationen zum Versand und zur Auswertung der Newsletter in unserem Auftrag. Des Weiteren
        kann der Versanddienstleister nach eigenen Informationen diese Daten zur Optimierung oder Verbesserung der
        eigenen Services nutzen, z.B. zur technischen Optimierung des Versandes und der Darstellung der Newsletter
        oder für wirtschaftliche Zwecke, um zu bestimmen aus welchen Ländern die Empfänger kommen. Der
        Versanddienstleister nutzt die Daten unserer Newsletterempfänger jedoch nicht, um diese selbst anzuschreiben
        oder an Dritte weiterzugeben.</p>


      <p>Anmeldedaten: Um sich für den Newsletter anzumelden, reicht es aus, wenn Sie Ihre E-Mailadresse
        angeben.</p>

      <p>Statistische Erhebung und Analysen – Die Newsletter enthalten einen sog. „web-beacon“, d.h. eine
        pixelgrosse Datei, die beim Öffnen des Newsletters von dem Server des Versanddienstleisters abgerufen wird.
        Im Rahmen dieses Abrufs werden zunächst technische Informationen, wie Informationen zum Browser und Ihrem
        System, als auch Ihre IP-Adresse und Zeitpunkt des Abrufs erhoben. Diese Informationen werden zur
        technischen Verbesserung der Services anhand der technischen Daten oder der Zielgruppen und ihres
        Leseverhaltens anhand derer Abruforte (die mit Hilfe der IP-Adresse bestimmbar sind) oder der Zugriffszeiten
        genutzt. Zu den statistischen Erhebungen gehört ebenfalls die Feststellung, ob die Newsletter geöffnet
        werden, wann sie geöffnet werden und welche Links geklickt werden. Diese Informationen können aus
        technischen Gründen zwar den einzelnen Newsletterempfängern zugeordnet werden. Es ist jedoch weder unser
        Bestreben, noch das des Versanddienstleisters, einzelne Nutzer zu beobachten. Die Auswertungen dienen uns
        viel mehr dazu, die Lesegewohnheiten unserer Nutzer zu erkennen und unsere Inhalte auf sie anzupassen oder
        unterschiedliche Inhalte entsprechend den Interessen unserer Nutzer zu versenden.</p>

      <p>Kündigung/Widerruf – Sie können den Empfang unseres Newsletters jederzeit kündigen, d.h. Ihre
        Einwilligungen widerrufen. Damit erlöschen gleichzeitig Ihre Einwilligungen in dessen Versand durch den
        Versanddienstleister und die statistischen Analysen. Ein getrennter Widerruf des Versandes durch den
        Versanddienstleister oder die statistische Auswertung ist leider nicht möglich. Einen Link zur Kündigung des
        Newsletters finden Sie am Ende eines jeden Newsletters.</p>

      <p><em>Einsatz des Versanddienstleisters „MailChimp“</em><br>Der Versand der Newsletter erfolgt mittels
        „MailChimp“, einer Newsletter-Versandplattform des US-Anbieters Rocket Science Group, LLC, 675 Ponce De Leon
        Ave NE #5000, Atlanta, GA 30308, USA. Die E-Mail-Adressen unserer Newsletterempfänger als auch deren
        weitere, im Rahmen dieser Hinweise beschriebenen Daten, werden auf den Servern von MailChimp in den USA
        gespeichert. MailChimp verwendet diese Informationen zum Versand und zur Auswertung der Newsletter in
        unserem Auftrag. Des Weiteren kann MailChimp nach eigenen Informationen diese Daten zur Optimierung oder
        Verbesserung der eigenen Services nutzen, zum Beispiel zur technischen Optimierung des Versandes und der
        Darstellung der Newsletter oder für wirtschaftliche Zwecke, um zu bestimmen, aus welchen Ländern die
        Empfänger kommen. MailChimp nutzt die Daten unserer Newsletter-Empfänger jedoch nicht, um diese selbst
        anzuschreiben oder an Dritte weiterzugeben. Wir vertrauen auf die Zuverlässigkeit und die IT- sowie
        Datensicherheit von MailChimp. MailChimp ist unter dem US-EU-Datenschutzabkommen&nbsp;Privacy Shield“
        zertifiziert und verpflichtet sich damit, die EU-Datenschutzvorgaben einzuhalten. Des Weiteren haben wir mit
        MailChimp ein „Data-Processing-Agreement“ abgeschlossen. Dabei handelt es sich um einen Vertrag, in dem sich
        MailChimp dazu verpflichtet, die Daten unserer Nutzer zu schützen, entsprechend dessen
        Datenschutzbestimmungen in unserem Auftrag zu verarbeiten und insbesondere nicht an Dritte weiter zu geben.
        Die Datenschutzbestimmungen von MailChimp können Sie hier&nbsp;einsehen.</p>


      <p><strong>Einbindung von Diensten und Inhalten Dritter</strong><br>Es kann vorkommen, dass innerhalb unseres
        Onlineangebotes Inhalte oder Dienste von Dritt-Anbietern, wie zum Beispiel Stadtpläne oder Schriftarten von
        anderen Webseiten eingebunden werden. Die Einbindung von Inhalten der Dritt-Anbieter setzt immer voraus,
        dass die Dritt-Anbieter die IP-Adresse der Nutzer wahrnehmen, da sie ohne die IP-Adresse die Inhalte nicht
        an den Browser der Nutzer senden könnten. Die IP-Adresse ist damit für die Darstellung dieser Inhalte
        erforderlich. Des Weiteren können die Anbieter der Dritt-Inhalte eigene Cookies setzen und die Daten der
        Nutzer für eigene Zwecke verarbeiten. Dabei können aus den verarbeiteten Daten Nutzungsprofile der Nutzer
        erstellt werden. Wir werden diese Inhalte möglichst datensparsam und datenvermeidend einsetzen sowie im
        Hinblick auf die Datensicherheit zuverlässige Dritt-Anbieter wählen.</p>


      <p>Die nachfolgende Darstellung bietet eine Übersicht von Dritt-Anbietern sowie ihrer Inhalte, nebst Links zu
        deren Datenschutzerklärungen, welche weitere Hinweise zur Verarbeitung von Daten und, z.T. bereits hier
        genannt, Widerspruchsmöglichkeiten (sog. Opt-Out) enthalten:</p>


      <p>– Externe Schriftarten von Google, Inc.,&nbsp;<a
        href="https://www.google.com/fonts">https://www.google.com/fonts</a>&nbsp;(„Google
        Fonts“). Die Einbindung der Google Fonts erfolgt durch einen Serveraufruf bei Google (in der Regel in den
        USA). Datenschutzerklärung:&nbsp;<a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>,
        Opt-Out:&nbsp;<a href="https://www.google.com/settings/ads/">https://www.google.com/settings/ads/</a>.</p>


      <p>– Landkarten des Dienstes „Google Maps“ des Dritt-Anbieters Google Inc., 1600 Amphitheatre Parkway,
        Mountain View, CA 94043, USA, gestellt. Datenschutzerklärung:&nbsp;<a
          href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>, Opt-Out:&nbsp;<a
          href="https://www.google.com/settings/ads/">https://www.google.com/settings/ads/</a>.</p>


      <p>– Videos der Plattform „YouTube“ des Dritt-Anbieters Google Inc., 1600 Amphitheatre Parkway, Mountain View,
        CA 94043, USA. Datenschutzerklärung:&nbsp;<a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>,
        Opt-Out:&nbsp;<a href="https://www.google.com/settings/ads/">https://www.google.com/settings/ads/</a>.</p>


      <p>– Videos der Plattform „Vimeo“ des Dritt-Anbieters Vimeo, Inc., 555 West 18th Street, New York, New York
        10011 Datenschutzerklärung:&nbsp;<a href="https://vimeo.com/privacy">https://vimeo.com/privacy</a>.</p>


      <p><strong>Rechte der Nutzer und Löschung von Daten</strong><br>Nutzer haben das Recht, auf Antrag
        unentgeltlich Auskunft zu erhalten über die personenbezogenen Daten, die von uns über sie gespeichert
        wurden.<br>Zusätzlich haben die Nutzer das Recht auf Berichtigung unrichtiger Daten, Widerruf von
        Einwilligungen, Sperrung und Löschung ihrer personenbezogenen Daten sowie das Recht, im Fall der Annahme
        einer unrechtmässigen Datenverarbeitung eine Beschwerde bei der zuständigen Aufsichtsbehörde einzureichen.
      </p>


      <p>Die bei uns gespeicherten Daten werden gelöscht, sobald sie für ihre Zweckbestimmung nicht mehr
        erforderlich sind und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen.</p>


      <p><strong>Änderungen der Datenschutzerklärung</strong><br>Wir behalten uns vor, die Datenschutzerklärung zu
        ändern, um sie an geänderte Rechtslagen, oder bei Änderungen des Dienstes sowie der Datenverarbeitung
        anzupassen. Dies gilt jedoch nur im Hinblick auf Erklärungen zur Datenverarbeitung. Sofern Einwilligungen
        der Nutzer erforderlich sind oder Bestandteile der Datenschutzerklärung Regelungen des Vertragsverhältnisses
        mit den Nutzern enthalten, erfolgen die Änderungen nur mit Zustimmung der Nutzer.<br>Die Nutzer werden
        gebeten sich regelmässig über den Inhalt der Datenschutzerklärung zu informieren.</p>


      <p>Stand 01.01.2024</p>


    </div>
  </div>
</div>
