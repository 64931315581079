<div class="mypb-login-page mypb-grid-small mypb-grid-modal mypb-admin-style">
  <div class="mypb-login-page-top">
    <h2>Polit-Page</h2>
    <p>Bist du Politiker und möchtest dich online präsentieren? Mit unserem Webbaukasten bist du in 15 Minuten
      startklar. <a routerLink="/onboarding">Jetzt starten!</a></p>
  </div>

  <form [formGroup]="loginForm" (ngSubmit)="login()" novalidate>
    <div class="form-group form-group-large">
      <label for="username">{{ 'login.username' | translate }}</label>
      <input type="text" id="username" class="form-control" formControlName="username"
             placeholder="{{ 'login.username' | translate }}">
      <small class="form-text text-muted"
             *ngIf="loginForm.controls['username'].touched && loginForm.controls['username'].hasError('required')">
        {{ 'login.username.required' | translate }}
      </small>
    </div>
    <div class="form-group form-group-large">
      <label for="password">{{ 'login.password' | translate }}</label>
      <input type="password" id="password" class="form-control" formControlName="password"
             placeholder="{{ 'login.password' | translate }}">
      <small class="form-text text-muted"
             *ngIf="loginForm.controls['password'].touched && loginForm.controls['password'].hasError('required')">
        {{ 'login.password.required' | translate }}
      </small>
    </div>
    <button type="submit"
            class="btn btn-primary"
            [disabled]="!loginForm.valid">{{ 'login.submit' | translate }}</button>
  </form>
</div>
