import { Inject, Injectable, Optional } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiServerHelperService {

  constructor(
    @Optional() @Inject('X_API_KEY') private xApiKey: string,
    @Optional() @Inject('X_FORWARDED_HOST') private xForwardedHost: string,
  ) {
  }

  /**
   * Returns the API Secret from node environment (SSR) or angular environment (CSR).
   *
   * @private
   */
  public getXApiKey(): string {
    if (this.xApiKey) {
      return this.xApiKey;
    }
    return environment.apiConfiguration.apiKey;
  }

  /**
   * The XForwardedHost is the actual hostname of the system where the application
   * is currently running on. Can be localhost, or some NodeJS server somewhere in the cloud.
   *
   * By default, it will just use the actual host (like 'polit.page' or 'partei.app').
   * For specific test systems or similar, we have to map it manually.
   *
   * @private
   */
  public getDomainForXForwardedHost(): string {
    if (!this.xForwardedHost) {
      return 'polit.page';
    }
    if (this.xForwardedHost.indexOf('politpage') > -1 || this.xForwardedHost.indexOf('polit.page') > -1) {
      return 'polit.page';
    }
    if (this.xForwardedHost.indexOf('parteiapp') > -1 || this.xForwardedHost.indexOf('partei.app') > -1) {
      return 'partei.app';
    }
    if (this.xForwardedHost.indexOf('paolopage') > -1 || this.xForwardedHost.indexOf('paolo.page') > -1) {
      return 'paolo.page';
    }
    return 'polit.page';
  }
}
