import { Component, Input, ViewEncapsulation } from '@angular/core';
import {
  MypbContentManagementProfilesService,
  MypbContentManagementVideosService,
  MypbProfile,
  MypbVideo,
} from '../../../../_generated/mypagebuilder-rest-api';
import { AppStateRefreshPageData } from '../../../../state/app/app.actions';
import swal from 'sweetalert2';
import { LoggerService } from '../../../../_common/logger/logger.service';
import { Store } from '@ngxs/store';

@Component({
  selector: 'mypb-profile-app-video',
  templateUrl: './profile-app-video.component.html',
  styleUrls: ['profile-app-video.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileAppVideoComponent {
  @Input() profile: MypbProfile = {} as any;
  public showVideo: boolean = false;

  constructor(
    private store: Store,
    private mypbContentManagementVideosService: MypbContentManagementVideosService,
    private mypbContentManagementProfilesService: MypbContentManagementProfilesService,
  ) {
  }

  public onVideoUploaded(video: MypbVideo) {
    this.mypbContentManagementProfilesService.profilesControllerUpdate({
      id: this.profile.id,
      body: {
        contentVideoId: video.id,
      },
    }).subscribe({
      next: profileUpdatedResult => {
        this.store.dispatch(new AppStateRefreshPageData());
        swal.fire('Video wurde gespeichert.', '', 'success').then();
      },
      error: profileUpdateException => {
        LoggerService.ERROR(this, 'profileUpdateException', profileUpdateException);
      },
    });
  }

  public deleteVideo() {
    if (this.profile.contentVideo && this.profile.contentVideo.id) {
      this.mypbContentManagementVideosService.videosControllerRemove({
        id: this.profile.contentVideo.id,
      }).subscribe({
        next: () => {
          swal.fire('Video wurde gelöscht.', '', 'success').then();
          this.store.dispatch(new AppStateRefreshPageData());
        },
        error: () => {
          swal.fire('Oops!', 'Video konnte nicht gelöscht werden.', 'error').then();
        },
      });
    }
  }
}
