import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MypbBlockToImage } from '../../_generated/mypagebuilder-rest-api';
import { Lightbox } from 'ngx-lightbox';
import { GetImageFromUri } from '../../pipes/get-image-from-uri.pipe';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

interface ImageGalleryImage {
  src: string;
  caption: string;
  thumb: string;
  original: MypbBlockToImage;
}

@Component({
  selector: 'mypb-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['image-gallery.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ImageGalleryComponent {
  @Input() imagesOfBlock: MypbBlockToImage[] = [];
  @Input() isEditModeEnabled: boolean | null = false;
  @Output() onRemoveImage: EventEmitter<MypbBlockToImage> = new EventEmitter<MypbBlockToImage>();
  @Output() onChangeOrder: EventEmitter<MypbBlockToImage[]> = new EventEmitter<MypbBlockToImage[]>();
  public imageGalleryImages: ImageGalleryImage[] = [];
  public draggingIndex: number | undefined;
  public dragging = false;

  constructor(
    private lightbox: Lightbox,
    private getImageFromUri: GetImageFromUri,
  ) {
  }

  ngDoCheck(): void {
    if (this.imagesOfBlock && this.imagesOfBlock.length && this.imagesOfBlock.length !== this.imageGalleryImages.length) {
      this.initializeImageGallery();
    }
  }

  public removeImage(imageGalleryImage: ImageGalleryImage) {
    this.imageGalleryImages.splice(this.imageGalleryImages.indexOf(imageGalleryImage), 1);
    this.onRemoveImage.emit(imageGalleryImage.original);
  }

  public open(index: number): void {
    this.lightbox.open(this.imageGalleryImages, index);
  }

  public close(): void {
    this.lightbox.close();
  }

  onDragStart(fromIndex: number): void {
    console.log('===onDragStart');
    this.draggingIndex = fromIndex;
  }

  onDragEnter(toIndex: number): void {
    if (this.draggingIndex !== undefined && this.draggingIndex !== toIndex) {
      this.reorderImage(this.draggingIndex, toIndex);
    }
  }

  onDragEnd(): void {
    this.draggingIndex = undefined;
  }

  sortImages(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.imageGalleryImages, event.previousIndex, event.currentIndex);
  }

  private reorderImage(fromIndex: number, toIndex: number): void {
    const itemToBeReordered = this.imageGalleryImages.splice(fromIndex, 1)[0];
    this.imageGalleryImages.splice(toIndex, 0, itemToBeReordered);
    this.draggingIndex = toIndex;
    const itemToBeReorderedOriginal = this.imagesOfBlock.splice(fromIndex, 1)[0];
    this.imagesOfBlock.splice(toIndex, 0, itemToBeReorderedOriginal);
    this.onChangeOrder.emit(this.imagesOfBlock);
  }

  private initializeImageGallery() {
    if (this.imagesOfBlock && this.imagesOfBlock.length) {
      this.imageGalleryImages = [];
      for (const imageOfBlock of this.imagesOfBlock) {
        if (imageOfBlock.image) {
          const imageGalleryImage = {
            src: this.getImageFromUri.transform(imageOfBlock.image.uri, 1200),
            caption: imageOfBlock.image.caption || '',
            thumb: this.getImageFromUri.transform(imageOfBlock.image.uri, 400),
            original: imageOfBlock,
          };
          this.imageGalleryImages.push(imageGalleryImage);
        }
      }
    }
  }
}
