import { MypbProfile, MypbProfileDto } from '../../_generated/mypagebuilder-rest-api';
import { Params } from '@angular/router';

export class AppStateSetAuthentication {
  static readonly type = '[app] Set authentication';

  constructor(public accessToken: string, public refreshToken: string) {
  }
}

export class AppStateSetTechnicalProfile {
  static readonly type = '[app] Set technical profile';

  constructor(public technicalProfile: MypbProfileDto) {
  }
}

export class AppStateSetProfile {
  static readonly type = '[app] Set profile';

  constructor(public profile: MypbProfile) {
  }
}

export class AppStateSetDomainAndPage {
  static readonly type = '[app] Set domain and page';

  constructor(public domainName: string, public domainId: string, public pageName: string, public pageId: string) {
  }
}

export class AppStateToggleEditMode {
  static readonly type = '[app] Toggle edit mode';
}

export class AppStateBackdropClicked {
  static readonly type = '[app] Backdrop clicked';
}

export class AppStateToggleBackdrop {
  static readonly type = '[app] Toggle back drop';

  constructor(public isVisible: boolean) {
  }
}

export class AppStateRefreshPageData {
  static readonly type = '[app] Refresh page data';
}

export class AppStateSetRouteParams {
  static readonly type = '[app] New route params set';

  constructor(public routeParams: Params | undefined) {
  }
}

export class AppStateIsSaving {
  static readonly type = '[app] Is saving';

  constructor(public isSaving: boolean) {
  }
}

export class AppStateIsMyPage {
  static readonly type = '[app] Is my page';

  constructor(public isMyPage: boolean) {
  }
}

export class AppStateHasPageChanged {
  static readonly type = '[app] Has page changed';
}
