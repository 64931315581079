import { MypbTemplate } from '../../_generated/mypagebuilder-rest-api';

export class OnboardingStateSetTemplate {
  static readonly type = '[onboarding] Set template';

  constructor(public template: MypbTemplate, public domainId: string) {
  }
}

export class OnboardingStateSetPageName {
  static readonly type = '[onboarding] Set name for page';

  constructor(public pageName: string) {
  }
}

export class OnboardingStateSetDetails {
  static readonly type = '[onboarding] Set firstname, lastname and email';

  constructor(public firstname: string, public lastname: string, public email: string) {
  }
}
