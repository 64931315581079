<div class="mypb-categories">
  <ng-container *ngFor="let category of categories">
    <ng-container *ngIf="isEditModeEnabled$ | async; else viewMode">
      <div class="mypb-categories-category"
           [class.mypb-categories-category--active]="selectedCategory === category"
           (click)="selectCategory(category)">
        {{ category.name }}
        <span class="mypb-categories-category__counter" *ngIf="category.count">
        {{category.count}}
      </span>
        <i class="fas fa-times" title="Löschen" *ngIf="category.count === 0"
           (click)="delete(category)"></i>
      </div>
    </ng-container>
    <ng-template #viewMode>
      <div class="mypb-categories-category" *ngIf="category.count > 0"
           [class.mypb-categories-category--active]="selectedCategory === category"
           (click)="selectCategory(category)">
        {{ category.name }}
        <span class="mypb-categories-category__counter">{{category.count}}</span>
      </div>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="isEditModeEnabled$ | async">
    <div class="mypb-categories-category mypb-categories-category--action" (click)="create()">
      <input type="text" [size]="newCategory.length || 2"
             (keyup.enter)="create()"
             [(ngModel)]="newCategory"
             [hidden]="!showNewCategory" #newCategoryInput>
      <i class="fas fa-plus"></i>
    </div>
  </ng-container>
</div>
