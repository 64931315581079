import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { MypbContentManagementSubpagesService, MypbSubpage, MypbSubpageTechnicalNameEnum } from '../../../_generated/mypagebuilder-rest-api';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { lastValueFrom } from 'rxjs';
import { AppStateIsSaving } from '../../../state/app/app.actions';
import { AppState } from '../../../state/app/app.state';
import { AddSubpageEvent } from '../../../components/add-subpage/add-subpage.component';

@Component({
  selector: 'mypb-subpages-app-page',
  templateUrl: './subpages-app.page.html',
  styleUrls: ['subpages-app.page.less'],
  encapsulation: ViewEncapsulation.None,
})
export class SubpagesAppPage implements OnInit {
  public subpages: MypbSubpage[] = [];
  public subpageTechnicalNameEnum = MypbSubpageTechnicalNameEnum;

  constructor(
    private store: Store,
    private mypbContentManagementSubpagesService: MypbContentManagementSubpagesService,
  ) {

  }

  async ngOnInit() {
    await this.initializeSubpages();
  }

  public async drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.subpages, event.previousIndex, event.currentIndex);
    await this.save();
  }

  public async toggleIsActive(subpage: MypbSubpage, isActive: boolean) {
    this.store.dispatch(new AppStateIsSaving(true));
    await lastValueFrom(this.mypbContentManagementSubpagesService.subpagesControllerUpdate({
      id: subpage.id,
      body: {
        id: subpage.id,
        isActive: isActive,
      },
    }));
    subpage.isActive = isActive;
    this.store.dispatch(new AppStateIsSaving(false));
  }

  public async deleteSubpage(subpage: MypbSubpage) {
    this.store.dispatch(new AppStateIsSaving(true));
    await lastValueFrom(this.mypbContentManagementSubpagesService.subpagesControllerRemove({ id: subpage.id }));
    await this.initializeSubpages();
    this.store.dispatch(new AppStateIsSaving(false));
  }

  public async changeSubpageName(event: Event, subpage: MypbSubpage) {
    const newSubpageName = (event.target as HTMLInputElement).value;
    if (newSubpageName !== subpage.name) {
      this.store.dispatch(new AppStateIsSaving(true));
      await lastValueFrom(this.mypbContentManagementSubpagesService.subpagesControllerUpdate({
        id: subpage.id,
        body: {
          id: subpage.id,
          name: newSubpageName,
        },
      }));
      this.store.dispatch(new AppStateIsSaving(false));
    }
  }

  public async addSubpage(addSubpageEvent: AddSubpageEvent) {
    const pageId = this.store.selectSnapshot(AppState.pageId);
    this.mypbContentManagementSubpagesService.subpagesControllerCreate({
      body: {
        subpageTypeId: addSubpageEvent.type.id,
        pageId: pageId,
        name: addSubpageEvent.name,
        position: this.subpages.length + 1,
        isActive: false,
      },
    }).subscribe({
      next: () => {
        this.initializeSubpages();
      },
    });
  }

  private async initializeSubpages() {
    const profile = this.store.selectSnapshot(AppState.profile);
    if (profile && profile.id) {
      this.mypbContentManagementSubpagesService.subpagesControllerFindAll({ profileId: profile.id }).subscribe({
        next: subpages => {
          if (subpages && subpages.length) {
            this.subpages = subpages.filter(subpage => !subpage.parentId).sort((a, b) => a.position - b.position);
          }
        },
      });
    }
  }

  private async save() {
    this.store.dispatch(new AppStateIsSaving(true));
    this.subpages.map(async (subpage, index) => {
      await lastValueFrom(this.mypbContentManagementSubpagesService.subpagesControllerUpdate({
        id: subpage.id,
        body: {
          id: subpage.id,
          position: index + 1,
        },
      }));
    });
    this.store.dispatch(new AppStateIsSaving(false));
  }
}
