import { Component, Input, ViewEncapsulation } from '@angular/core';
import {
  MypbContentManagementImagesService,
  MypbContentManagementProfilesService,
  MypbImage,
  MypbProfile,
} from '../../../../_generated/mypagebuilder-rest-api';
import { AppStateRefreshPageData } from '../../../../state/app/app.actions';
import swal from 'sweetalert2';
import { LoggerService } from '../../../../_common/logger/logger.service';
import { Store } from '@ngxs/store';

@Component({
  selector: 'mypb-profile-app-picture',
  templateUrl: './profile-app-picture.component.html',
  styleUrls: ['profile-app-picture.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileAppPictureComponent {
  @Input() profile: MypbProfile = {} as any;

  constructor(
    private store: Store,
    private mypbContentManagementImagesService: MypbContentManagementImagesService,
    private mypbContentManagementProfilesService: MypbContentManagementProfilesService,
  ) {
  }

  public onImageUploaded(image: MypbImage) {
    this.mypbContentManagementProfilesService.profilesControllerUpdate({
      id: this.profile.id,
      body: {
        contentImageId: image.id,
      },
    }).subscribe({
      next: profileUpdatedResult => {
        this.store.dispatch(new AppStateRefreshPageData());
        swal.fire('Bild wurde gespeichert.', '', 'success').then();
      },
      error: profileUpdateException => {
        LoggerService.ERROR(this, 'profileUpdateException', profileUpdateException);
      },
    });
  }

  public deleteImage() {
    if (this.profile.contentImage && this.profile.contentImage.id) {
      this.mypbContentManagementImagesService.imagesControllerRemove({
        id: this.profile.contentImage.id,
      }).subscribe({
        next: () => {
          swal.fire('Bild wurde gelöscht.', '', 'success').then();
          this.store.dispatch(new AppStateRefreshPageData());
        },
        error: () => {
          swal.fire('Oops!', 'Bild konnte nicht gelöscht werden.', 'error').then();
        },
      });
    }
  }
}
