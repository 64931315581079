<div class="mypb-onboarding-choose-name-page">
  <h3>Ist dein Wunsch-Name noch frei?</h3>
  <div *ngIf="pattern">
    <label for="pageName">{{ apiServerHelperService.getDomainForXForwardedHost() }}/</label>
    <input type="text" name="pageName" id="pageName" [pattern]="pattern.pattern" [(ngModel)]="pageName"
           (keyup)="onKeyup($event)">
    <ng-container *ngIf="checkFreeResponseDto">
      <i class="fal fa-check"
         *ngIf="checkFreeResponseDto && checkFreeResponseDto.isFree && checkFreeResponseDto.isValid && isMatchingPattern; else issue"></i>
      <ng-template #issue>
        <i class="fal fa-times"></i>
        <span *ngIf="!checkFreeResponseDto.isFree">Name bereits vergeben.</span>
        <span *ngIf="!checkFreeResponseDto.isValid || !isMatchingPattern">Ungültige Zeichen.</span>
      </ng-template>
    </ng-container>
  </div>
  <ul>
    <li>
      <i class="fal fa-check"
         *ngIf="pageName && pageName.length >= 3 && pageName.length <= 30; else invalidLength"></i>
      <ng-template #invalidLength>
        <i class="fal fa-times"></i>
      </ng-template>
      <p>Dein Wunschname muss mindestens 3 und maximal 30 Zeichen lang sein.</p>
    </li>
    <li>
      <i class="fal fa-check" *ngIf="isMatchingPattern; else invalidCharacters"></i>
      <ng-template #invalidCharacters>
        <i class="fal fa-times"></i>
      </ng-template>
      <p>Erlaubt sind Buchstaben (a-z), Zahlen, Punkt und Strich.</p>
    </li>
    <li>
      <i class="fal fa-check" *ngIf="isMatchingPattern; else startsOrEndsWithDot"></i>
      <ng-template #startsOrEndsWithDot>
        <i class="fal fa-times"></i>
      </ng-template>
      <p>Ein Punkt oder Strich darf nicht am Anfang oder Ende stehen.</p>
    </li>
  </ul>

  <button class="btn btn-primary"
          (click)="continue()"
          [disabled]="!pageName || !isMatchingPattern || !checkFreeResponseDto?.isFree || !checkFreeResponseDto?.isValid">
    {{ 'global.ui.continue' | translate }}
  </button>
</div>
