import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';
import { MypbContentDeliveryMediaService } from '../_generated/mypagebuilder-rest-api';

@Pipe({ name: 'getVideoFromUri' })
export class GetVideoFromUri implements PipeTransform {
  transform(uri: string | undefined) {
    if (uri && uri.indexOf('http') === -1) {
      if (uri.indexOf('/data') === 0) {
        uri = uri.substring('/data'.length + 1);
      }

      const path = MypbContentDeliveryMediaService.MediaDeliveryControllerDownloadVideoPath.substring(
        0,
        MypbContentDeliveryMediaService.MediaDeliveryControllerDownloadVideoPath.indexOf('/uploads'),
      );

      return `${environment.apiConfiguration.baseUrl}${path}/${uri}`;
    }
    return uri;
  }
}
