import { Component, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { MypbProfile } from '../../../_generated/mypagebuilder-rest-api';
import { AppState } from '../../../state/app/app.state';

@Component({
  selector: 'mypb-profile-app-page',
  templateUrl: './profile-app.page.html',
  styleUrls: ['profile-app.page.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileAppPage {
  public profile: MypbProfile = {} as any;

  constructor(
    private store: Store,
  ) {
  }

  ngOnInit() {
    this.store.select(AppState.profile).subscribe(profile => {
      if (profile) {
        this.profile = profile;
      }
    });
  }
}
