<div class="mypb-standalone-page">
  <mypb-apps-header [canGoBack]="false"></mypb-apps-header>
  <div class="mypb-apps-page mypb-standalone-page__margin">
    
    <div class="mypb-grid-small text-center mypb-dashboard-lead">
      <h1>Dashboard</h1>
      <p class="text-lead">Verwalte dein Profil.</p>
    </div>
    
    <div class="mypb-grid-wide text-center mypb-dashboard-apps mypb-dashboard-apps-3">
      <ul>
        <li><a routerLink="/apps/profil"><span><i class="fal fa-user"></i></span>Profil</a></li>
        <li><a routerLink="/apps/seiten"><span><i class="fal fa-bars"></i></span>Seiten</a></li>
        <li><a routerLink="/apps/statistik"><span><i class="fal fa-chart-bar"></i></span>Statistiken</a></li>
        <li><a routerLink="/apps/feedback"><span><i class="fal fa-map-marker-question"></i></span>Feedback & Hilfe</a></li>
        <li><a routerLink="/apps/abos"><span><i class="fal fa-cog"></i></span>Abos</a></li>
      </ul>
    </div>

    <div class="mypb-grid-small text-center mypb-dashboard-actions">
      <div class="gap100"></div>
      <a (click)="logout()" class="btn-link btn-small">Abmelden</a>
    </div>
    
  </div>
  <div class="mypb-bg"><div class="mypb-bg-inner"></div></div>
</div>
