import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  MypbAddImageToBlockDto,
  MypbBlock,
  MypbBlockToImage,
  MypbContentManagementBlocksService,
  MypbContentManagementBlockToImageService,
  MypbImage,
} from '../../_generated/mypagebuilder-rest-api';
import { Store } from '@ngxs/store';
import { BlockComponent } from '../block/block.component';
import { AppStateIsSaving } from '../../state/app/app.actions';
import { lastValueFrom } from 'rxjs';
import { GetImageFromUri } from '../../pipes/get-image-from-uri.pipe';

@Component({
  selector: 'mypb-block-text-image',
  templateUrl: './block-text-image.component.html',
  styleUrls: [
    'block-text-image.component.less',
    '../block/block.component.less',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class BlockTextImageComponent extends BlockComponent implements OnInit {
  public newImagesToSave: MypbAddImageToBlockDto[] = [];
  public imagesToDelete: MypbAddImageToBlockDto[] = [];

  constructor(
    protected override store: Store,
    protected override mypbContentManagementBlocksService: MypbContentManagementBlocksService,
    protected mypbContentManagementBlockToImageService: MypbContentManagementBlockToImageService,
    private getImageFromUri: GetImageFromUri,
  ) {
    super(
      store,
      mypbContentManagementBlocksService,
    );
  }

  override ngOnInit(): void {
    super.ngOnInit();
    if (this.block) {
      this.newData = this.block;
    } else {
      this.newData = {};
    }
  }

  public updateText(text: string) {
    this.newData.text = text;
  }

  removeImage(blockToImage: MypbBlockToImage) {
    this.imagesToDelete.push(blockToImage);
    if (this.block) {
      const indexOfBlockToImage = this.block.images.findIndex(image => image.id === blockToImage.id);
      if (indexOfBlockToImage > -1) {
        this.block.images.splice(indexOfBlockToImage, 1);
      }
    }
  }

  async onImageUploaded(image: MypbImage) {
    if (this.block && this.block.id) {
      const blockToImage = {
        imageId: image.id,
        image: image,
        blockId: this.block.id,
        position: (this.block.images && this.block.images.length) ? this.block.images.length + 1 : 1,
      };
      this.newImagesToSave.push(blockToImage);
      if (this.block.images && this.block.images.length) {
        this.removeImage(this.block.images[0]);
        this.block.images.push(blockToImage);
      } else {
        this.block.images = [blockToImage];
      }
      await this.saveImage();
    }
  }

  async updateBlockTextImage(newData: Partial<MypbBlock>) {
    this.store.dispatch(new AppStateIsSaving(true));
    await this.saveImage();
    await this.updateBlock(newData);
    this.store.dispatch(new AppStateIsSaving(false));
  }

  async saveImage() {
    for (const newImageToSave of this.newImagesToSave) {
      await lastValueFrom(this.mypbContentManagementBlockToImageService.blockToImageControllerAdd({
        body: newImageToSave,
      }));
    }
    for (const imageToDelete of this.imagesToDelete) {
      await lastValueFrom(this.mypbContentManagementBlockToImageService.blockToImageControllerRemove({
        body: imageToDelete,
      }));
    }
  }

  async cancelBlockTextImage() {
    this.newImagesToSave = [];
    this.imagesToDelete = [];
    this.cancel();
  }
}
