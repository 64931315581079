<div class="mypb-links-editor">
  <form [formGroup]="form" (ngSubmit)="save()" novalidate>
    <div class="mypb-links-editor-entry" *ngFor="let entity of entities; let index = index;">
      <div class="form-group">
        <label for="entity{{index}}label{{randomId}}">{{ 'global.ui.label' | translate }}</label>
        <input type="text" id="entity{{index}}label{{randomId}}" class="form-control"
               [formControl]="$any(form).controls['entity'+index+'label']"
               placeholder="{{ 'global.ui.label' | translate }}">
        <small class="form-text text-muted"
               *ngIf="form.controls['entity'+index+'label'].touched && form.controls['entity'+index+'label'].hasError('required')">
          {{ 'global.ui.label' | translate }} {{ 'global.ui.isRequired' | translate }}
        </small>
      </div>
      <div class="form-group">
        <label for="entity{{index}}value{{randomId}}">{{ 'global.ui.link' | translate }}</label>
        <input type="text" id="entity{{index}}value{{randomId}}" class="form-control"
               [formControl]="$any(form).controls['entity'+index+'value']"
               placeholder="{{ 'global.ui.link' | translate }}">
        <small class="form-text text-muted"
               *ngIf="form.controls['entity'+index+'value'].touched && form.controls['entity'+index+'value'].hasError('required')">
          {{ 'global.ui.link' | translate }} {{ 'global.ui.isRequired' | translate }}
        </small>
      </div>
      <ng-container *ngIf="allowMultiple">
        <a class="mypb-links-editor-entry__delete" (click)="deleteAtIndex(index)">Löschen</a>
      </ng-container>
    </div>
    <ng-container *ngIf="allowMultiple">
      <button class="btn-small-primary" (click)="$event.preventDefault(); addNew()">
        {{ 'actions.new.link' | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="!autoSave">
      <button type="submit"
              class="btn btn-primary"
              [disabled]="!form.valid || form.pristine">
        {{ 'global.ui.save' | translate }}
      </button>
      <div class="gap10"></div>
    </ng-container>
  </form>
</div>
