<div class="mypb-blockstyle-selector">
  <button (click)="showMenu = !showMenu">Stil auswählen</button>
  <div *ngIf="showMenu">
    <ul>
      <li *ngFor="let blockStyle of blockStyles">
        <button (click)="select(blockStyle)">{{ blockStyle.name }} ({{ blockStyle.description }})</button>
      </li>
    </ul>
  </div>
</div>

