/**
 * This global environment variables are equal to production.
 * The GLOBAL constant shouldn't be imported directly. Only import them via an environment variable.
 */
export const GLOBALS = {
  name: 'production',

  /**
   * prod, abna, entw, qa etc. Can be used to implement specific functionality for dedicated environments.
   * the production domain gets set within the page resolving based on the current domain.
   */
  apiConfiguration: {
    baseUrl: 'https://api.mypagebuilder.ch',
    apiSecret: '',
    apiKey: '',
  },

  /**
   * This is an ionic internal flag which states how to compile the app.
   */
  production: true,

  /**
   * Logging settings for NGXS and general.
   */
  isLoggingEnabled: {
    logger: false,
    ngxs: false,
  },

  /**
   * Upload configuration.
   */
  upload: {
    image: {
      maxFilesizeInMegabytes: 25,
      allowedMimetypes: [
        'image/jpeg',
        'image/jpg',
        'image/png',
      ],
    },
    video: {
      maxFilesizeInMegabytes: 100,
      allowedMimetypes: [
        'video/mpeg',
        'video/mp4',
        'video/quicktime',
      ],
    },
    document: {
      maxFilesizeInMegabytes: 10,
      allowedMimetypes: [
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/mspowerpoint',
        'application/vnd.ms-excel',
        'application/msexcel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ],
    },
  },
};
