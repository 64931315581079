import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, concatMap, retryWhen, share, switchMap, tap } from 'rxjs/operators';
import { MypbAuthenticationService } from '../_generated/mypagebuilder-rest-api';
import { Store } from '@ngxs/store';
import { AppStateSetAuthentication } from '../state/app/app.actions';
import { AppState } from '../state/app/app.state';
import { StateClear } from 'ngxs-reset-plugin';
import { LoggerService } from './logger/logger.service';
import { isPlatformBrowser } from '@angular/common';
import { AppComponent } from '../app.component';

@Injectable()
export class ApiTokenInterceptor implements HttpInterceptor {
  private retryRequest = Symbol('reload');
  private refreshToken = this.store.select(AppState.refreshToken)
    .pipe(
      concatMap(refreshToken => this.mypbAuthenticationService
        .authenticationControllerRefreshToken({ body: { refreshToken } })
        .pipe(
          tap(newTokenResponse => {
            if (newTokenResponse.access_token && newTokenResponse.refresh_token) {
              this.store.dispatch(new AppStateSetAuthentication(newTokenResponse.access_token, newTokenResponse.refresh_token));
              throw this.retryRequest;
            }
          }),
          share(),
        ) as Observable<any>),
    );

  constructor(
    private store: Store,
    private mypbAuthenticationService: MypbAuthenticationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const request$ = new Observable<HttpRequest<any>>(observer => {
      observer.next(this.addToken(request));
      observer.complete();
    });

    // @ts-ignore
    return request$.pipe(
      switchMap(req => next.handle(req)),
      catchError((error: Error) => {
        LoggerService.LOG(this, 'HttpErrorResponse', error);
        if (error instanceof HttpErrorResponse && isPlatformBrowser(AppComponent.PLATFORM_ID)) {
          // Catch refresh token error, which does a logout.
          if (error.url && error.url.indexOf(MypbAuthenticationService.AuthenticationControllerRefreshTokenPath) > -1) {
            this.store.dispatch(new StateClear());
            this.router.navigateByUrl(this.activatedRoute.snapshot.params['pageName']).then();
            LoggerService.ERROR(this, 'error', 'Aufgrund zu langer Inaktivität wurden Sie automatisch abgemeldet.');
            throw error;
          }
          switch (error.status) {
            case 401:
              return this.refreshToken;
            default:
              throw error;
          }
        } else {
          throw error;
        }
      }),
      retryWhen(err$ =>
        err$.pipe(
          tap(err => {
            if (err === this.retryRequest) {
              return;
            }
            throw err;
          }),
        ),
      ),
    );
  }

  /**
   * Adds the bearer token to the http request.
   *
   * @param req
   * @private
   */
  private addToken(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + this.store.selectSnapshot(AppState.accessToken),
      },
    });
  }
}
