import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MypbContentDeliveryTemplatesService, MypbTemplate } from '../../../_generated/mypagebuilder-rest-api';
import { Store } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';
import { OnboardingStateSetTemplate } from '../../../state/onboarding/onboarding.actions';
import { OnboardingState } from '../../../state/onboarding/onboarding.state';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiServerHelperService } from '../../../services/api-server-helper.service';

@Component({
  selector: 'mypb-onboarding-template-page',
  templateUrl: './onboarding-template.page.html',
  styleUrls: ['onboarding-template.page.less'],
  encapsulation: ViewEncapsulation.None,
})
export class OnboardingTemplatePage implements OnInit {
  public templates: MypbTemplate[] = [];
  public selectedTemplate: MypbTemplate | undefined;

  constructor(
    private readonly apiServerHelperService: ApiServerHelperService,
    private readonly store: Store,
    private readonly mypbContentDeliveryTemplatesService: MypbContentDeliveryTemplatesService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {
  }

  async ngOnInit() {
    this.templates = await lastValueFrom(this.mypbContentDeliveryTemplatesService.templatesDeliveryControllerFindAllByDomainName({
      domainName: this.apiServerHelperService.getDomainForXForwardedHost(),
      'x-api-key': this.apiServerHelperService.getXApiKey(),
    }));
    this.store.select(OnboardingState.template).subscribe({
      next: template => {
        this.selectedTemplate = template;
      },
    });
    this.activatedRoute.queryParams.subscribe({
      next: queryParams => {
        if (queryParams && queryParams['template']) {
          const template = this.templates.find(template => template.technicalName === queryParams['template']);
          if (template) {
            this.selectTemplate(template);
            this.continue();
          }
        }
      },
    });
  }

  ngOnDestroy() {
  }

  public selectTemplate(template: MypbTemplate) {
    this.store.dispatch(new OnboardingStateSetTemplate(template, this.getDomainIdBasedOnDomainName(template)));
  }

  async continue() {
    await this.router.navigateByUrl('/onboarding/details');
  }

  private getDomainIdBasedOnDomainName(template: MypbTemplate) {
    const domain = template.allowedDomains.find(domain => domain.name === this.apiServerHelperService.getDomainForXForwardedHost());
    if (domain) {
      return domain.id;
    }
    return '';
  }
}
