import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { AppState } from '../../state/app/app.state';
import { AppStateToggleEditMode } from '../../state/app/app.actions';
import { isPlatformBrowser } from '@angular/common';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'mypb-mode-switcher',
  templateUrl: './mode-switcher.component.html',
  styleUrls: ['mode-switcher.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ModeSwitcherComponent implements OnDestroy, OnInit {
  public isEditModeEnabled$ = this.store.select(AppState.isEditModeEnabled);
  public isMyPage$ = this.store.select(AppState.isMyPage);

  constructor(
    private readonly store: Store,
  ) {

  }

  ngOnInit(): void {
    if (isPlatformBrowser(AppComponent.PLATFORM_ID)) {
      this.isEditModeEnabled$.subscribe(isEditModeEnabled => {
        const body = document.querySelector('body');
        if (body) {
          if (isEditModeEnabled) {
            body.classList.add('mypb--is-edit-mode-enabled');
          } else {
            body.classList.remove('mypb--is-edit-mode-enabled');
          }
        }
      });
    }
  }

  ngOnDestroy(): void {
  }

  public toggleMode() {
    this.store.dispatch(new AppStateToggleEditMode());
  }
}
