import { Component, Inject, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { AppStateSetAuthentication } from '../../state/app/app.actions';
import swal from 'sweetalert2';
import { LoggerService } from '../../_common/logger/logger.service';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MypbAuthenticationService, MypbContentManagementPagesService } from '../../_generated/mypagebuilder-rest-api';
import { Actions, Store } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState } from '../../state/app/app.state';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'mypb-login-page',
  templateUrl: './login.page.html',
  styleUrls: ['login.page.less'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginPage implements OnInit {
  public loginForm: FormGroup;
  public isLoggedIn$ = this.store.select(AppState.isLoggedIn);
  private loginSubscription$: Subscription | undefined;
  private redirectUrl = '';

  constructor(
    private readonly store: Store,
    private readonly actions: Actions,
    private readonly mypbAuthenticationService: MypbAuthenticationService,
    private readonly mypbContentManagementPagesService: MypbContentManagementPagesService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams && queryParams['redirect']) {
        this.redirectUrl = queryParams['redirect'];
      }
    });
  }

  ngOnDestroy(): void {
    if (this.loginSubscription$) {
      this.loginSubscription$.unsubscribe();
    }
  }

  async login() {
    if (this.loginForm.valid) {
      this.loginSubscription$ = this.mypbAuthenticationService.authenticationControllerLogin({
        body: {
          username: this.loginForm.value.username,
          password: this.loginForm.value.password,
        },
      }).subscribe({
        next: async value => {
          await this.store.dispatch(new AppStateSetAuthentication(value.access_token, value.refresh_token));
          await swal.fire('Willkommen!', 'Du wurdest erfolgreich eingeloggt.', 'success');
          await this.redirectAfterLogin();
        },
        error: async loginException => {
          LoggerService.ERROR(this, 'loginException', loginException);
          await swal.fire('Oops!', 'Der Benutzername oder das Passwort ist falsch.', 'error');
        },
      });
    }
  }

  private async redirectAfterLogin() {
    if (this.redirectUrl) {
      await this.router.navigateByUrl(decodeURIComponent(this.redirectUrl));
    } else {
      await this.router.navigateByUrl('/page-selector');
    }
  }
}
