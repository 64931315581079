import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mypb-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['landing-page.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class LandingPageComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {
  }

}
