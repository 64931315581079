import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';
import { MypbContentDeliveryMediaService } from '../_generated/mypagebuilder-rest-api';

@Pipe({ name: 'getFileFromUri' })
export class GetFileFromUriPipe implements PipeTransform {
  transform(uri: string | undefined, width?: number) {
    if (uri && uri.indexOf('http') === -1) {
      if (uri.indexOf('/data') === 0) {
        uri = uri.substring('/data'.length + 1);
      }
      const path = MypbContentDeliveryMediaService.MediaDeliveryControllerDownloadFilePath.substring(
        0,
        MypbContentDeliveryMediaService.MediaDeliveryControllerDownloadFilePath.indexOf('/uploads'),
      );
      return `${environment.apiConfiguration.baseUrl}${path}${uri}`;
    }
    return uri || '';
  }
}
