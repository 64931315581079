import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';


@Component({
  selector: 'mypb-onboarding-page',
  templateUrl: './onboarding.page.html',
  styleUrls: ['onboarding.page.less'],
  encapsulation: ViewEncapsulation.None,
})
export class OnboardingPage implements OnInit {
  constructor(
    private readonly store: Store,
  ) {
  }

  async ngOnInit() {
  }

  ngOnDestroy() {
  }
}
