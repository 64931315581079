<div class="mypb-image-gallery" cdkDropList (cdkDropListDropped)="sortImages($event)">
  <ng-container *ngIf="isEditModeEnabled; else viewMode;">
    <div class="mypb-image-gallery-image-item"
         style="cursor: move"
         *ngFor="let imageGalleryImage of imageGalleryImages; let index = index;"
         [class.dragging]="index === this.draggingIndex"
         [draggable]="true" (dragstart)="onDragStart(index)" (dragenter)="onDragEnter(index)"
         (dragend)="onDragEnd()">
      <div class="mypb-image-gallery-image-remove" (click)="removeImage(imageGalleryImage)">
        <i class="fas fa-trash"></i>
      </div>
      <div class="mypb-image-gallery-image-wrap"
           style="background-image: url('{{imageGalleryImage.thumb}}')"
           (click)="open(index)">
      </div>
    </div>
    <ng-content></ng-content>
  </ng-container>
  <ng-template #viewMode>
    <div class="mypb-image-gallery-image-item" *ngFor="let imageGalleryImage of imageGalleryImages; let index = index;">
      <div class="mypb-image-gallery-image-wrap"
           style="background-image: url('{{imageGalleryImage.thumb}}')"
           (click)="open(index)">
      </div>
    </div>
  </ng-template>
</div>
