import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewEncapsulation } from '@angular/core';
import { MypbContentDeliverySubpageTypesService, MypbSubpageTechnicalNameEnum, MypbSubpageType } from '../../_generated/mypagebuilder-rest-api';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';

export interface AddSubpageEvent {
  type: MypbSubpageType,
  name: string,
}

@Component({
  selector: 'mypb-add-subpage',
  templateUrl: './add-subpage.component.html',
  styleUrls: ['add-subpage.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class AddSubpageComponent implements OnInit {
  @Input() title = 'Neu';
  @Input() createWithRandomName = false;
  @Input() subpageTechnicalNameEnum: MypbSubpageTechnicalNameEnum = MypbSubpageTechnicalNameEnum.Generic;
  @Input() showMenu = false;
  @Output() showMenuChange = new EventEmitter();
  @Output() add: EventEmitter<AddSubpageEvent> = new EventEmitter<AddSubpageEvent>();
  static SUBPAGE_TYPES: MypbSubpageType[] = [];
  public subpageTypes: MypbSubpageType[] = [];
  public selectedSubpageType: MypbSubpageType | undefined;
  public name = '';

  constructor(
    private mypbContentDeliverySubpageTypesService: MypbContentDeliverySubpageTypesService,
    @Optional() @Inject('X_API_KEY') private xApiKey: string,
  ) {
  }

  ngOnInit() {
    if (!AddSubpageComponent.SUBPAGE_TYPES.length) {
      this.initializeSubpageTypes();
    } else {
      this.subpageTypes = AddSubpageComponent.SUBPAGE_TYPES;
      this.setSubpageType();
    }
  }

  public create() {
    if (this.selectedSubpageType) {
      this.add.emit({ type: this.selectedSubpageType, name: this.name });
      this.name = '';
      this.toggleMenu(true);
    }
  }

  public toggleMenu(forceClose = false) {
    if (this.createWithRandomName) {
      this.createWithRandomName = false;
      this.name = `Neuer Blogpost vom ${moment().format('DD.MM.YYYY')}`;
      this.create();
    }
    if (forceClose) {
      this.showMenu = false;
    } else {
      this.showMenu = !this.showMenu;
    }
    this.showMenuChange.emit(this.showMenu);
  }

  private initializeSubpageTypes() {
    this.mypbContentDeliverySubpageTypesService.subpageTypesDeliveryControllerFindAll({
      'x-api-key': this.getXApiKey(),
    })
      .subscribe({
        next: subpageTypes => {
          this.subpageTypes = subpageTypes;
          AddSubpageComponent.SUBPAGE_TYPES = subpageTypes;
          this.setSubpageType();
        },
      });
  }

  private setSubpageType() {
    let subpageTechnicalEnum = MypbSubpageTechnicalNameEnum.Generic;
    if (this.subpageTechnicalNameEnum) {
      subpageTechnicalEnum = this.subpageTechnicalNameEnum;
    }
    this.selectedSubpageType = this.subpageTypes.find(subpageType => subpageType.technicalName === subpageTechnicalEnum);
  }

  /**
   * Returns the API Secret from node environment (SSR) or angular environment (CSR).
   *
   * @private
   */
  private getXApiKey(): string {
    if (this.xApiKey) {
      return this.xApiKey;
    }
    return environment.apiConfiguration.apiKey;
  }
}
