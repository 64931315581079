import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  MypbContentManagementAnalyticsService,
  MypbVisitsSummaryDto,
} from '../../../_generated/mypagebuilder-rest-api';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import { DatePipe } from '@angular/common';
import { AppState } from '../../../state/app/app.state';

@Component({
  selector: 'mypb-analytics-app-page',
  templateUrl: './analytics-app.page.html',
  styleUrls: ['analytics-app.page.less'],
  encapsulation: ViewEncapsulation.None,
})
export class AnalyticsAppPage implements OnInit {
  public analyticsData: {
    [key: string]: MypbVisitsSummaryDto;
  } | undefined;
  public lineChartData: ChartConfiguration<'line'>['data'] = {
    labels: [],
    datasets: [],
  };
  public lineChartDataAverageTimes: ChartConfiguration<'line'>['data'] = {
    labels: [],
    datasets: [],
  };
  public lineChartOptions: ChartOptions<'line'> = {
    responsive: true,
  };
  public lineChartLegend = true;
  private colors = ['#3d8aff', '#1D75FA', '#0857cd'];

  constructor(
    private store: Store,
    private mypbContentManagementAnalyticsService: MypbContentManagementAnalyticsService,
    private datePipe: DatePipe,
  ) {

  }

  ngOnInit() {
    this.mypbContentManagementAnalyticsService.analyticsControllerGetAnalytics(
      {
        domainName: this.store.selectSnapshot(AppState.domainName),
        pageName: this.store.selectSnapshot(AppState.pageName),
      },
    ).subscribe({
      next: data => {
        this.lineChartData.labels = Object.keys(data).map(date => this.datePipe.transform(date));
        this.lineChartDataAverageTimes.labels = Object.keys(data).map(date => this.datePipe.transform(date));
        this.lineChartData.datasets.push({
          data: Object.keys(data).map(key => data[key].nb_uniq_visitors || 0),
          label: 'Eindeutige Besucher',
          fill: false,
          tension: 0.5,
          pointBorderColor: this.colors[0],
          pointBackgroundColor: this.colors[0],
          borderColor: this.colors[0],
          backgroundColor: this.colors[0],
        });
        this.lineChartData.datasets.push({
          data: Object.keys(data).map(key => data[key].nb_visits || 0),
          label: 'Seitenaufrufe',
          fill: false,
          tension: 0.5,
          pointBorderColor: this.colors[1],
          pointBackgroundColor: this.colors[1],
          borderColor: this.colors[1],
          backgroundColor: this.colors[1],
        });
        this.lineChartDataAverageTimes.datasets.push({
          data: Object.keys(data).map(key => Math.floor((data[key].avg_time_on_site / 60)) || 0),
          label: 'Dauer in Minuten',
          fill: false,
          tension: 0.5,
          pointBorderColor: this.colors[1],
          pointBackgroundColor: this.colors[1],
          borderColor: this.colors[1],
          backgroundColor: this.colors[1],
        });
        this.analyticsData = data;
      },
    });
  }
}
