import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mypb-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['not-found.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class NotFoundComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
