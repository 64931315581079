import { Pipe, PipeTransform } from '@angular/core';

enum LINK_TYPE {
  GENERIC = 'GENERIC',
  FACEBOOK = 'FACEBOOK',
  LINKEDIN = 'LINKEDIN',
  INSTAGRAM = 'INSTAGRAM',
  TWITTER = 'TWITTER',
  TIKTOK = 'TIKTOK',
  YOUTUBE = 'YOUTUBE',
}

@Pipe({ name: 'getIconForLinkTypePipe' })
export class GetIconForLinkTypePipe implements PipeTransform {
  transform(linktype: string) {
    switch (linktype) {
      case LINK_TYPE.FACEBOOK:
        return 'tpl-icon-facebook';
      case LINK_TYPE.LINKEDIN:
        return 'tpl-icon-linkedin';
      case LINK_TYPE.INSTAGRAM:
        return 'tpl-icon-instagram';
      case LINK_TYPE.TWITTER:
        return 'tpl-icon-twitter';
      case LINK_TYPE.TIKTOK:
        return 'tpl-icon-tiktok';
      case LINK_TYPE.YOUTUBE:
        return 'tpl-icon-youtube';
      default:
        return 'tpl-icon-generic';
    }
  }
}
