import { Component, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { AppState } from '../../state/app/app.state';

@Component({
  selector: 'mypb-home-button',
  templateUrl: './home-button.component.html',
  styleUrls: ['./home-button.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeButtonComponent {
  public isMyPage$ = this.store.select(AppState.isMyPage);
  public isLoggedIn$ = this.store.select(AppState.isLoggedIn);
  public profile$ = this.store.select(AppState.profile);

  constructor(
    private store: Store,
  ) {
  }
}
