import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { MypbBlock, MypbContentManagementBlocksService, MypbCreateBlockDto, MypbSubpage, MypbUpdateBlockDto } from '../../_generated/mypagebuilder-rest-api';
import { AppStateIsSaving } from '../../state/app/app.actions';
import { AppState } from '../../state/app/app.state';
import { lastValueFrom } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'mypb-block',
  template: '',
  styleUrls: ['block.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class BlockComponent {
  @Input() block: MypbBlock | undefined;
  @Input() subpage: MypbSubpage | undefined;
  public isEditModeEnabled$ = this.store.select(AppState.isEditModeEnabled);
  public newData: Partial<MypbBlock> = {};
  public triggerReset = false;
  private copyOfOriginalBlock: MypbBlock | undefined;

  constructor(
    protected store: Store,
    protected mypbContentManagementBlocksService: MypbContentManagementBlocksService,
  ) {
  }

  ngOnInit() {
    this.copyOfOriginalBlock = JSON.parse(JSON.stringify(this.block));
  }

  public updateBlock(newData: Partial<MypbBlock>) {
    if (newData.id) {
      this.store.dispatch(new AppStateIsSaving(true));
      this.mypbContentManagementBlocksService.blocksControllerUpdate({
        id: newData.id,
        body: newData as MypbUpdateBlockDto,
      }).subscribe({
        next: () => {
          this.removeFocus();
        },
        complete: () => {
          this.store.dispatch(new AppStateIsSaving(false));
        },
      });
    }
  }

  async createBlock(newData: Partial<MypbBlock>): Promise<MypbBlock | undefined> {
    if (this.subpage) {
      this.removeFocus();
      newData.subpageId = this.subpage.id;
      return lastValueFrom(this.mypbContentManagementBlocksService.blocksControllerCreate({
        body: newData as MypbCreateBlockDto,
      }));
    }
    return undefined;
  }

  public cancel() {
    this.block = this.copyOfOriginalBlock;
    if (this.block) {
      this.newData = this.block;
    } else {
      this.newData = {};
    }

    // Quickly set this to true and false to trigger trix reset.
    this.triggerReset = true;
    if (isPlatformBrowser(AppComponent.PLATFORM_ID)) {
      setTimeout(() => {
        this.triggerReset = false;
        this.removeFocus();
      }, 1);
    }
  }

  private removeFocus() {
    if (isPlatformBrowser(AppComponent.PLATFORM_ID)) {
      if (document.activeElement) {
        (document.activeElement as HTMLElement).blur();
      }
    }
  }
}
