import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { LoggerService } from '../../_common/logger/logger.service';
import {
  MypbContentManagementImagesService,
  MypbContentManagementVideosService,
  MypbDocument,
  MypbImage,
  MypbVideo,
} from '../../_generated/mypagebuilder-rest-api';
import swal from 'sweetalert2';
import { Store } from '@ngxs/store';
import { AppState } from '../../state/app/app.state';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'mypb-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['upload.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class UploadComponent implements OnInit {
  @Input() autoUpload = false;
  @Input() allowImage = false;
  @Input() allowVideo = false;
  @Input() allowDocument = false;
  @Input() allowMultiple = false;
  @Output() uploadedImage: EventEmitter<MypbImage> = new EventEmitter<MypbImage>();
  @Output() uploadedVideo: EventEmitter<MypbVideo> = new EventEmitter<MypbVideo>();
  @Output() uploadedDocument: EventEmitter<MypbDocument> = new EventEmitter<MypbDocument>();
  public files: File[] = [];
  public isUploadingCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public isUploadingCount = 0;
  public maxFilesizeInMegabytes: number = 0;
  public allowedMimetypesString: string = '';
  public allowedFilesString: string = '';
  private allowedFiles: string[] = [];
  private allowedMimetypes: string[] = [];
  private uploadConfiguration = environment.upload;

  constructor(
    private mypbContentManagementImagesService: MypbContentManagementImagesService,
    private mypbContentManagementVideosService: MypbContentManagementVideosService,
    private store: Store,
  ) {
  }

  ngOnInit() {
    this.isUploadingCount$.subscribe(amountOfUploadsInProgress => {
      LoggerService.LOG(this, 'amountOfUploadsInProgress', amountOfUploadsInProgress);
    });
    this.initializeMimetypes();
  }

  private initializeMimetypes() {
    if (this.allowImage) {
      this.allowedMimetypes = this.allowedMimetypes.concat(this.uploadConfiguration.image.allowedMimetypes);
      this.updateMaxFilesize(this.uploadConfiguration.image.maxFilesizeInMegabytes);
      if (this.allowMultiple) {
        this.allowedFiles.push('Bilder');
      } else {
        this.allowedFiles.push('Bild');
      }
    }
    if (this.allowVideo) {
      this.allowedMimetypes = this.allowedMimetypes.concat(this.uploadConfiguration.video.allowedMimetypes);
      this.updateMaxFilesize(this.uploadConfiguration.video.maxFilesizeInMegabytes);
      if (this.allowMultiple) {
        this.allowedFiles.push('Videos');
      } else {
        this.allowedFiles.push('Video');
      }
    }
    if (this.allowDocument) {
      this.allowedMimetypes = this.allowedMimetypes.concat(this.uploadConfiguration.document.allowedMimetypes);
      this.updateMaxFilesize(this.uploadConfiguration.document.maxFilesizeInMegabytes);
      if (this.allowMultiple) {
        this.allowedFiles.push('Dokumente');
      } else {
        this.allowedFiles.push('Dokument');
      }
    }
    this.allowedMimetypesString = this.allowedMimetypes.join(',');
    if (this.allowedFiles.length > 1) {
      const last = this.allowedFiles.pop();
      this.allowedFilesString = this.allowedFiles.join(', ') + ' und ' + last;
    } else {
      this.allowedFilesString = this.allowedFiles.join(', ');
    }
  }

  private updateMaxFilesize(filesize: number) {
    if (filesize > this.maxFilesizeInMegabytes) {
      this.maxFilesizeInMegabytes = filesize;
    }
  }

  public getTypeForFile(file: any): 'image' | 'video' | 'document' | undefined {
    if (this.uploadConfiguration.image.allowedMimetypes.indexOf(file.type) > -1) {
      return 'image';
    }
    if (this.uploadConfiguration.video.allowedMimetypes.indexOf(file.type) > -1) {
      return 'video';
    }
    if (this.uploadConfiguration.document.allowedMimetypes.indexOf(file.type) > -1) {
      return 'document';
    }
    return undefined;
  }

  public onSelect(event: any) {
    for (const file of event.addedFiles) {
      const type = this.getTypeForFile(file);
      if (!type) continue;
      this.files.push(file);
    }
    if (this.autoUpload) {
      this.save();
    }

    /**
     * Handle rejected files.
     */
    for (const rejectedFile of event.rejectedFiles) {
      let labelForType: string;
      let maxSizeForType: number = 0;
      const type = this.getTypeForFile(rejectedFile);
      switch (type) {
        case 'image':
          labelForType = 'Bild';
          maxSizeForType = this.uploadConfiguration.image.maxFilesizeInMegabytes;
          break;
        case 'video':
          labelForType = 'Video';
          maxSizeForType = this.uploadConfiguration.video.maxFilesizeInMegabytes;
          break;
        case 'document':
          labelForType = 'Dokument';
          maxSizeForType = this.uploadConfiguration.document.maxFilesizeInMegabytes;
          break;
        default:
          labelForType = 'Datei';
      }

      const baseMessage = `Das ${labelForType} ${rejectedFile.name} konnte nicht hochgeladen werden`;
      switch (rejectedFile.reason) {
        case 'size':
          swal.fire('Oops!', `${baseMessage} weil es zu gross ist (maximal ${maxSizeForType} MegaBytes).`, 'error');
          break;
        default:
          swal.fire('Oops!', `${baseMessage} weil ein unbekannter Fehler aufgetreten ist.`, 'error');
          break;
      }
    }
  }

  /**
   * Iterates over all selected files and uploads them via API.
   */
  public save() {
    for (const file of this.files) {
      const type = this.getTypeForFile(file);
      if (!type) continue;
      this.isUploadingCount++;
      this.isUploadingCount$.next(this.isUploadingCount);
      switch (type) {
        case 'image':
          this.saveImage(file);
          break;
        case 'video':
          this.saveVideo(file);
          break;
        case 'document':
          this.saveDocument(file);
          break;
      }
    }
  }

  /**
   * Removes a file from the local array of files.
   *
   * @param file
   */
  public onRemove(file: File) {
    this.files.splice(this.files.indexOf(file), 1);
  }

  /**
   * Uplaod a file to the API and emit the response to the upper component.
   *
   * @param file
   * @private
   */
  private async saveImage(file: File) {
    const profile = this.store.selectSnapshot(AppState.profile);
    if (file && profile) {
      this.mypbContentManagementImagesService.imagesControllerCreate({
        body: {
          file: file,
          profileId: profile.id,
        },
      }).subscribe({
        next: response => {
          this.uploadedImage.emit(response);
          this.onRemove(file);
          this.isUploadingCount--;
          this.isUploadingCount$.next(this.isUploadingCount);
        },
        error: uploadException => {
          LoggerService.ERROR(this, 'uploadException', uploadException);
          swal.fire('Oops!', `Beim hochladen ist etwas schief gelaufen.`, 'error');
          this.isUploadingCount--;
          this.isUploadingCount$.next(this.isUploadingCount);
        },
      });
    } else {
      this.isUploadingCount--;
      this.isUploadingCount$.next(this.isUploadingCount);
      await swal.fire('Oops!', `Beim hochladen ist etwas schief gelaufen.`, 'error');
    }
  }

  /**
   * Uplaod a file to the API and emit the response to the upper component.
   *
   * @param file
   * @private
   */
  private saveVideo(file: File) {
    const profileId = this.store.selectSnapshot(AppState.profile)?.id;
    if (file && profileId) {
      this.mypbContentManagementVideosService.videosControllerCreate({
        body: {
          file: file,
          profileId: profileId,
        },
      }).subscribe({
        next: response => {
          this.uploadedVideo.emit(response);
          this.onRemove(file);
          this.isUploadingCount--;
          this.isUploadingCount$.next(this.isUploadingCount);
        },
        error: uploadException => {
          LoggerService.ERROR(this, 'uploadException', uploadException);
          swal.fire('Oops!', `Beim hochladen ist etwas schief gelaufen.`, 'error');
          this.isUploadingCount--;
          this.isUploadingCount$.next(this.isUploadingCount);
        },
      });
    }
  }

  /**
   * Uplaod a file to the API and emit the response to the upper component.
   *
   * @param file
   * @private
   */
  private saveDocument(file: File) {
    this.onRemove(file);
    this.isUploadingCount--;
    this.isUploadingCount$.next(this.isUploadingCount);
    // if (file) {
    //   this.mypbContentManagementImagesService.imagesControllerCreate({
    //     body: {
    //       file: file,
    //       profileId: this.store.selectSnapshot(AppState.profile)?.id,
    //     },
    //   }).subscribe({
    //     next: response => {
    //       this.uploadedImage.emit(response);
    //       this.onRemove(file);
    //       this.isUploadingCount--;
    //       this.isUploadingCount$.next(this.isUploadingCount);
    //     },
    //     error: uploadException => {
    //       LoggerService.ERROR(this, 'uploadException', uploadException);
    //       swal.fire('Oops!', `Beim hochladen ist etwas schief gelaufen.`, 'error');
    //       this.isUploadingCount--;
    //       this.isUploadingCount$.next(this.isUploadingCount);
    //     },
    //   });
    // }
  }
}
