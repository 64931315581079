/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MypbCreatePageDto } from '../models/mypb-create-page-dto';
import { MypbPage } from '../models/mypb-page';
import { MypbUpdatePageDto } from '../models/mypb-update-page-dto';

@Injectable({
  providedIn: 'root',
})
export class MypbAdminPagesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation pagesAdminControllerFindAll
   */
  static readonly PagesAdminControllerFindAllPath = '/v1/admin/pages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pagesAdminControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  pagesAdminControllerFindAll$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<MypbPage>>> {

    const rb = new RequestBuilder(this.rootUrl, MypbAdminPagesService.PagesAdminControllerFindAllPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MypbPage>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `pagesAdminControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pagesAdminControllerFindAll(params?: {
    context?: HttpContext
  }
): Observable<Array<MypbPage>> {

    return this.pagesAdminControllerFindAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MypbPage>>) => r.body as Array<MypbPage>)
    );
  }

  /**
   * Path part for operation pagesAdminControllerCreate
   */
  static readonly PagesAdminControllerCreatePath = '/v1/admin/pages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pagesAdminControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pagesAdminControllerCreate$Response(params: {
    context?: HttpContext
    body: MypbCreatePageDto
  }
): Observable<StrictHttpResponse<MypbPage>> {

    const rb = new RequestBuilder(this.rootUrl, MypbAdminPagesService.PagesAdminControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MypbPage>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `pagesAdminControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pagesAdminControllerCreate(params: {
    context?: HttpContext
    body: MypbCreatePageDto
  }
): Observable<MypbPage> {

    return this.pagesAdminControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<MypbPage>) => r.body as MypbPage)
    );
  }

  /**
   * Path part for operation pagesAdminControllerFindOne
   */
  static readonly PagesAdminControllerFindOnePath = '/v1/admin/pages/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pagesAdminControllerFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  pagesAdminControllerFindOne$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<MypbPage>> {

    const rb = new RequestBuilder(this.rootUrl, MypbAdminPagesService.PagesAdminControllerFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MypbPage>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `pagesAdminControllerFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pagesAdminControllerFindOne(params: {
    id: string;
    context?: HttpContext
  }
): Observable<MypbPage> {

    return this.pagesAdminControllerFindOne$Response(params).pipe(
      map((r: StrictHttpResponse<MypbPage>) => r.body as MypbPage)
    );
  }

  /**
   * Path part for operation pagesAdminControllerDelete
   */
  static readonly PagesAdminControllerDeletePath = '/v1/admin/pages/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pagesAdminControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  pagesAdminControllerDelete$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MypbAdminPagesService.PagesAdminControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `pagesAdminControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pagesAdminControllerDelete(params: {
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.pagesAdminControllerDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation pagesAdminControllerUpdate
   */
  static readonly PagesAdminControllerUpdatePath = '/v1/admin/pages/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pagesAdminControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pagesAdminControllerUpdate$Response(params: {
    id: string;
    context?: HttpContext
    body: MypbUpdatePageDto
  }
): Observable<StrictHttpResponse<MypbPage>> {

    const rb = new RequestBuilder(this.rootUrl, MypbAdminPagesService.PagesAdminControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MypbPage>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `pagesAdminControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pagesAdminControllerUpdate(params: {
    id: string;
    context?: HttpContext
    body: MypbUpdatePageDto
  }
): Observable<MypbPage> {

    return this.pagesAdminControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<MypbPage>) => r.body as MypbPage)
    );
  }

}
