import { Component, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { AppState } from '../../state/app/app.state';
import * as moment from 'moment';
import { isPlatformBrowser } from '@angular/common';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'mypb-subscriptions-banner',
  templateUrl: './subscriptions-banner.component.html',
  styleUrls: ['subscriptions-banner.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class SubscriptionsBannerComponent {
  public isLoggedIn$ = this.store.select(AppState.isLoggedIn);
  public subscription$ = this.store.select(AppState.subscription);
  public isVisible: boolean = false;
  public isTrial: boolean = false;
  public isExpiring: boolean = false;
  private trialEndDate: moment.Moment | undefined;
  private expirationDate: moment.Moment | undefined;

  constructor(
    private readonly store: Store,
  ) {

  }

  ngOnInit() {
    if (isPlatformBrowser(AppComponent.PLATFORM_ID)) {
      this.subscription$.subscribe({
        next: subscription => {
          setTimeout(() => {
            if (subscription && moment(subscription.trialEnd).isAfter(moment())) {
              this.trialEndDate = moment(subscription.trialEnd);
              this.isVisible = true;
              this.isTrial = true;
            } else if (subscription && moment(subscription.expirationDate).isAfter(moment())) {
              this.expirationDate = moment(subscription.expirationDate);
              this.isVisible = true;
              this.isExpiring = true;
            }
          }, 1500);
        },
      });
    }
  }

  get getLeftExpirationDay() {
    return this.expirationDate ? this.expirationDate.diff(moment(), 'days') : 0;
  }

  get getLeftTrialDays() {
    return this.trialEndDate ? this.trialEndDate.diff(moment(), 'days') : 0;
  }
}
