import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom, Subscription } from 'rxjs';
import { MypbContentManagementPagesService, MypbContentManagementSubpagesService, MypbPage, MypbProfileDesignEnum, MypbSubpage } from '../../_generated/mypagebuilder-rest-api';
import { Actions, ofActionCompleted, Store } from '@ngxs/store';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SsrPageResolverService } from '../../resolvers/ssr-page-resolver.service';
import { AppState } from '../../state/app/app.state';
import { AppStateHasPageChanged, AppStateRefreshPageData, AppStateSetAuthentication, AppStateSetDomainAndPage, AppStateSetProfile, AppStateSetRouteParams } from '../../state/app/app.actions';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'mypb-page',
  templateUrl: './page.component.html',
  styleUrls: ['page.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class PageComponent implements OnInit, OnDestroy {
  /**
   * These variables come pre-filled via SSR Page Resolver if not logged in.
   * Otherwise, they're empty objects and must be filled via Content Management API.
   */
  public page: MypbPage = {} as any;
  public subpages: MypbSubpage[] = [];
  public subpagesForNavigation: MypbSubpage[] = [];
  public currentSubpage: MypbSubpage = {} as any;

  /**
   * Stylesheets based on page template.
   */
  public stylesheets: SafeResourceUrl[] = [];

  /**
   * Profile design enums
   */
  public profileDesignEnum = MypbProfileDesignEnum;

  /**
   * These properties are used for fetching the correct subpage.
   */
  private pageName: string = '';
  private domainName: string = '';
  private slug = '';
  private subslug = '';

  /**
   * Here comes the rest.
   */
  private routeSubscription: Subscription | undefined;
  private scriptsLoaded = false;

  constructor(
    private store: Store,
    private actions: Actions,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private mypbContentManagementPagesService: MypbContentManagementPagesService,
    private mypbContentManagementSubpagesService: MypbContentManagementSubpagesService,
    private ssrPageResolverService: SsrPageResolverService,
    private renderer2: Renderer2,
    private elementRef: ElementRef,
  ) {
  }

  async ngOnInit() {
    this.actions.pipe(
      ofActionCompleted(AppStateRefreshPageData, AppStateSetAuthentication, AppStateSetRouteParams),
    ).subscribe(async () => {
      await this.initializePageData();
    });
    await this.initializePageData();
  }

  /**
   * Main function to initialize page data. Either fetch from content management API (if it is "my page"),
   * otherwise take the prefetched data from the resolver service aka content delivery API.
   */
  private async initializePageData() {
    this.setRouteParams();
    if (this.store.selectSnapshot(AppState.isMyPage)) {
      try {
        const page = await lastValueFrom(this.mypbContentManagementPagesService.pagesControllerFindOneByDomainAndPageName({
          domainName: this.domainName,
          pageName: this.pageName,
        }));

        let slug = this.subslug || this.slug || '';
        if (slug === '') {
          slug = page.subpages.filter(subpage => !subpage.parentId)[0].slug;
        }
        const currentSubpage = await lastValueFrom(this.mypbContentManagementSubpagesService.subpagesControllerFindOneBySlug({
          profileId: this.store.selectSnapshot(AppState.profile)?.id || '',
          slug: slug,
        }));

        this.setData(page, page.subpages, currentSubpage);
        this.store.dispatch(new AppStateSetProfile(this.page.profile));
      } catch (notMyPageException) {
        this.onIsNotMyPage();
      }
    } else {
      this.onIsNotMyPage();
    }
  }

  /**
   * If it is not "my page", just set the data based on the prefetched data
   * coming from the resolver service aka content delivery API.
   * @private
   */
  private onIsNotMyPage() {
    const prefetchedData = this.activatedRoute.snapshot.data['preFetchedData'];
    this.setData(prefetchedData['page'], prefetchedData['subpages'], prefetchedData['currentSubpage']);
  }

  /**
   * If stylesheets for this template are not loaded already, add them to the DOM.
   *
   * @private
   */
  private loadStylesheets() {
    if (!this.stylesheets.length && this.page?.template?.technicalName) {
      const cssBase = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.page?.template?.technicalName}.css`);
      this.stylesheets.push(cssBase);
      AppComponent.HIDE_LOADER();
    }
  }

  /**
   * If scripts for this template are not loaded already, add them to the DOM.
   *
   * @private
   */
  private loadScripts() {
    if (!this.scriptsLoaded && this.page?.template?.technicalName) {
      this.scriptsLoaded = true;
      const script = this.renderer2.createElement('script');
      script.type = 'text/javascript';
      script.src = `assets/templates/${this.page?.template?.technicalName}/scripts.js`;
      this.renderer2.appendChild(this.elementRef.nativeElement, script);
      AppComponent.HIDE_LOADER();
    }
  }

  /**
   * Set page data.
   *
   * @param page
   * @param subpages
   * @param currentSubpage
   * @private
   */
  private setData(page: MypbPage, subpages: MypbSubpage[], currentSubpage: MypbSubpage) {
    this.page = page;
    this.subpages = subpages;
    this.subpagesForNavigation = subpages
      .filter(subpage => !subpage.parentId)
      .sort((a, b) => a.position - b.position);
    this.currentSubpage = currentSubpage;
    this.store.dispatch(new AppStateSetDomainAndPage(
      this.domainName,
      this.page.domainId || '',
      this.pageName,
      this.page.id || '',
    ));
    this.loadStylesheets();
    this.loadScripts();
    this.store.dispatch(new AppStateHasPageChanged());
  }

  private setRouteParams() {
    this.slug = this.activatedRoute.snapshot.params['slug'];
    this.subslug = this.activatedRoute.snapshot.params['subslug'];
    this.domainName = this.activatedRoute.snapshot.params['domainName'] || this.ssrPageResolverService.getDomainForXForwardedHost();
    this.pageName = this.activatedRoute.snapshot.params['pageName'];
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }
}
