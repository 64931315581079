import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Store } from '@ngxs/store';
import { AppState } from '../state/app/app.state';

@Injectable({
  providedIn: 'root',
})
export class RoutingHelperService {
  constructor(
    private readonly router: Router,
    private readonly store: Store,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
  }

  public getUrlForPage(pageName: string, domainName: string, onlyPath = false): string {
    if (onlyPath) {
      return `/${pageName}`;
    }
    let hostIncludingProtocol = '';
    if (isPlatformBrowser(this.platformId)) {
      // Client-side rendering
      if (window.location.host === domainName) {
        hostIncludingProtocol = `${window.location.protocol}//${window.location.host}`;
      } else {
        hostIncludingProtocol = `https://${domainName}`;
      }
    } else {
      // Server-side rendering
      if (this.document.location.host === domainName) {
        hostIncludingProtocol = `${this.document.location.protocol}//${this.document.location.host}`;
      } else {
        hostIncludingProtocol = `https://${domainName}`;
      }
    }
    return `${hostIncludingProtocol}/${pageName}`;
  }

  public getRootUrlOfCurrentlySelectedPage(onlyPath = false) {
    const pageName = this.store.selectSnapshot(AppState.pageName);
    const domainName = this.store.selectSnapshot(AppState.domainName);
    return this.getUrlForPage(pageName, domainName, onlyPath);
  }
}
