<div class="mypb-standalone-page">
  <mypb-apps-header></mypb-apps-header>
  <div class="mypb-subscriptions-app  mypb-standalone-page__margin">

    <div class="mypb-grid-small mypb-dashboard-lead">
      <h1>Abos</h1>
    </div>
    
    <div class="mypb-grid-small">
      <h2>Allgemeine Informationen</h2>
      <div>
        <p>Registriert am: {{ technicalProfile.meta.registered | date }}</p>
        <p>Letztes Login: {{ technicalProfile.meta.lastLogin | date:'short' }} Uhr</p>
      </div>
      <h2>Abonnements</h2>
      <div>
        <div *ngFor="let subscription of technicalProfile.meta.subscriptions">
          <h3>{{ subscription.subscriptionPlan.postTitle }}</h3>
          <p>Gültig von {{ subscription.startDate | date }} bis {{ subscription.expirationDate | date }}</p>
          <p>Status {{ subscription.status }}</p>
        </div>
      </div>
    </div>
    
  </div>

  <div class="mypb-bg"><div class="mypb-bg-inner"></div></div>
</div>
