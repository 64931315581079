<div class="mypb-onboarding-template-page">
  <h3>Wähle dein Template aus:</h3>
  <button *ngFor="let template of templates" (click)="selectTemplate(template)">
    {{ template.name }}
  </button>
  <br>
  <button class="btn btn-primary"
          (click)="continue()"
          [disabled]="!selectedTemplate">{{ 'global.ui.continue' | translate }}</button>
</div>
