import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MypbContentManagementBlocksService } from '../../_generated/mypagebuilder-rest-api';
import { Store } from '@ngxs/store';
import { BlockComponent } from '../block/block.component';

@Component({
  selector: 'mypb-block-text',
  templateUrl: './block-text.component.html',
  styleUrls: [
    'block-text.component.less',
    '../block/block.component.less',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class BlockTextComponent extends BlockComponent implements OnInit {
  constructor(
    protected override store: Store,
    protected override mypbContentManagementBlocksService: MypbContentManagementBlocksService,
  ) {
    super(
      store,
      mypbContentManagementBlocksService,
    );
  }

  override ngOnInit(): void {
    super.ngOnInit();
    if (this.block) {
      this.newData = this.block;
    } else {
      this.newData = {};
    }
  }

  public updateText(text: string) {
    this.newData.text = text;
  }
}
