import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import {
  MypbContentDeliveryPagesService,
  MypbContentDeliveryTemplatesService,
  MypbCreateTrialPageDto,
} from '../../../_generated/mypagebuilder-rest-api';
import { OnboardingState } from '../../../state/onboarding/onboarding.state';
import { Router } from '@angular/router';
import { ApiServerHelperService } from '../../../services/api-server-helper.service';
import { AppStateSetAuthentication, AppStateSetProfile } from '../../../state/app/app.actions';

@Component({
  selector: 'mypb-onboarding-details-page',
  templateUrl: './onboarding-details.page.html',
  styleUrls: ['onboarding-details.page.less'],
  encapsulation: ViewEncapsulation.None,
})
export class OnboardingDetailsPage implements OnInit {
  public onboardingForm: FormGroup;

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly apiServerHelperService: ApiServerHelperService,
    private readonly mypbContentDeliveryTemplatesService: MypbContentDeliveryTemplatesService,
    private readonly mypbContentDeliveryPagesService: MypbContentDeliveryPagesService,
  ) {
    this.onboardingForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
    });
  }

  async ngOnInit() {
    this.store.select(OnboardingState.canSetDetails).subscribe({
      next: async canSetDetails => {
        if (!canSetDetails) {
          await this.router.navigateByUrl('/onboarding/choose-name');
        }
      },
    });
  }

  ngOnDestroy() {
  }

  doOnboarding() {
    if (this.onboardingForm.valid) {
      const dto: MypbCreateTrialPageDto = {
        domainId: this.store.selectSnapshot(OnboardingState.domainId),
        templateId: this.store.selectSnapshot(OnboardingState.template)?.id,
        ...this.onboardingForm.value,
      };
      this.mypbContentDeliveryPagesService.pagesDeliveryControllerCreateTrialPage({
        'x-api-key': this.apiServerHelperService.getXApiKey(),
        body: dto,
      }).subscribe({
        next: async response => {
          await this.store.dispatch(new AppStateSetAuthentication(response.access_token, response.refresh_token));
          await this.store.dispatch(new AppStateSetProfile(response.profile));
          await this.router.navigateByUrl('/page-selector');
        },
        error: exception => {

        },
      });
    }
  }
}
