<div class="mypb-block mypb-block-text {{block.blockStyle ? block.blockStyle.cssClass : ''}}" *ngIf="block">
  <div *ngIf="(isEditModeEnabled$ | async); else viewMode;" class="edit-mode edit-trix">
    <mypb-trix-editor [content]="block.text" (contentUpdated)="updateText($event)"
                      [triggerReset]="triggerReset"></mypb-trix-editor>

    <div class="edit-mode-actions">
      <div class="edit-mode-actions--inner">
        <button type="submit" class="btn-small-primary" (click)="updateBlock(newData)">
          {{ 'global.ui.save' | translate }}
        </button>
        <button class="btn-small-secondary" (click)="cancel()">
          {{ 'global.ui.cancel' | translate }}
        </button>
      </div>
    </div>

  </div>
  <ng-template #viewMode>
    <div class="block-content" [innerHTML]="block.text"></div>
  </ng-template>
</div>
