import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MypbCategory, MypbContentManagementCategoriesService, MypbProfile } from '../../_generated/mypagebuilder-rest-api';
import { Store } from '@ngxs/store';
import { AppState } from '../../state/app/app.state';
import swal from 'sweetalert2';
import { LoggerService } from '../../_common/logger/logger.service';
import { ActivatedRoute } from '@angular/router';
import { isPlatformBrowser, Location } from '@angular/common';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'mypb-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.less'],
})
export class CategoriesComponent implements OnInit {
  @Input() categories: MypbCategory[] = [];
  @Output() categorySelected: EventEmitter<MypbCategory> = new EventEmitter<MypbCategory>();
  @ViewChild('newCategoryInput') newCategoryInput!: ElementRef;
  public isEditModeEnabled$ = this.store.select(AppState.isEditModeEnabled);
  public newCategory: string = '';
  public editCategoryId: string | undefined;
  public showNewCategory: boolean = false;
  public selectedCategory: MypbCategory | undefined;
  private profile: MypbProfile = {} as any;

  constructor(
    private mypbContentManagementCategoriesService: MypbContentManagementCategoriesService,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private location: Location,
  ) {
    this.store.select(AppState.profile).subscribe(profile => {
      if (profile) {
        this.profile = profile;
      }
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  private loadData() {
    if (this.profile.id) {
      this.mypbContentManagementCategoriesService.categoriesControllerFindAllByProfileId({
        profileId: this.profile.id,
      }).subscribe({
        next: categories => {
          this.categories = categories;
          this.setCategoryBasedOnFragment();
        },
      });
    } else {
      const pageId = this.store.selectSnapshot(AppState.pageId);
      this.mypbContentManagementCategoriesService.categoriesControllerFindAllByPageId({
        pageId: pageId,
      }).subscribe({
        next: categories => {
          this.categories = categories;
          this.setCategoryBasedOnFragment();
        },
      });
    }
  }

  private setCategoryBasedOnFragment() {
    const fragment = this.activatedRoute.snapshot.fragment;
    LoggerService.LOG(this, 'setCategoryBasedOnFragment', fragment);
    const categoryBySlug = this.categories.find(category => category.slug === fragment);
    if ((fragment && categoryBySlug)) {
      this.selectCategory(categoryBySlug);
    } else if (this.selectedCategory) {
      this.selectCategory(this.selectedCategory);
    }
  }

  public create() {
    if (!this.showNewCategory) {
      this.showNewCategory = true;
      try {
        if (isPlatformBrowser(AppComponent.PLATFORM_ID)) {
          setTimeout(() => {
            this.newCategoryInput.nativeElement.focus();
          }, 1);
        }
      } catch (noFocusException) {
        LoggerService.ERROR(this, 'noFocusException', noFocusException);
      }
      return;
    }
    if (this.newCategory) {
      this.mypbContentManagementCategoriesService.categoriesControllerCreate({
        body: {
          name: this.newCategory,
          profileId: this.profile.id,
        },
      }).subscribe({
        next: () => {
          this.newCategory = '';
          this.showNewCategory = false;
          this.loadData();
        },
        error: () => {
          swal.fire('Oops!', 'Beim Erstellen der Kategorie ist ein Fehler aufgetreten.', 'error');
        },
      });
    }
  }

  public delete(category: MypbCategory) {
    if (category.id) {
      this.mypbContentManagementCategoriesService.categoriesControllerRemove({
        id: category.id,
      }).subscribe({
        next: () => {
          this.loadData();
        },
      });
    }
  }

  public selectCategory(category: MypbCategory) {
    if (this.selectedCategory === category) {
      this.selectedCategory = undefined;
      this.categorySelected.emit();
      this.location.replaceState(this.location.path());
    } else {
      this.selectedCategory = category;
      this.categorySelected.emit(category);
      this.location.replaceState(`${this.location.path()}#${category.slug}`);
    }
  }
}
