<div class="mypb-profile-app-picture">
  <div *ngIf="profile.contentImage" class="mypb-profile-app__upload">
    <div class="mypb-profile-app__upload-actions">
      <i class="fas fa-trash" (click)="deleteImage()"></i>
    </div>
  </div>
  <div class="form-dropzone-field">
    <mypb-upload [allowImage]="true" (uploadedImage)="onImageUploaded($event)" [autoUpload]="true"></mypb-upload>
  </div>
</div>
