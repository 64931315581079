import { Injectable } from '@angular/core';
import { LoggerService } from '../../_common/logger/logger.service';

@Injectable({ providedIn: 'root' })
export class ModalService {
  private modals: any[] = [];

  add(modal: any) {
    this.modals.push(modal);
  }

  remove(id: string) {
    this.modals = this.modals.filter(x => x.id !== id);
  }

  open(id: string) {
    const modal = this.modals.find(x => x.id === id);
    modal.open();
  }

  close(id: string) {
    const modal = this.modals.find(x => x.id === id);
    try {
      modal.close();
    } catch (modalCloseException) {
      LoggerService.LOG(this, 'modalCloseException');
    }
  }
}
