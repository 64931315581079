import { AfterViewInit, Directive, ElementRef, HostListener, Input, SimpleChanges } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { AppComponent } from '../app.component';

@Directive({
  selector: '[fitTextToContainer]',
})
export class FitTextToContainerDirective implements AfterViewInit {
  @Input() fitTextToContainerGroupName: string = '';
  @Input() fitTextToContainerElementName: string = '';
  private fittextParent!: HTMLElement;
  private fittextElement: HTMLElement;
  private fontUnit = 'px';
  private wasChanged = false;
  private currentSize = -1;
  private stillResizing = true;
  static GROUP_ITEMS: {
    groupname: string;
    elementname: string;
    isMaster: boolean;
    fontsize: number;
    originalFontsize: number;
  }[] = [];

  constructor(
    private el: ElementRef,
  ) {
    this.fittextElement = el.nativeElement;
    if (this.fittextElement.parentElement) {
      this.fittextParent = this.fittextElement.parentElement;
    }
  }

  @HostListener('window:resize')
  public onWindowResize = (): void => {
    if (isPlatformBrowser(AppComponent.PLATFORM_ID)) {
      this.checkForTextFitting();
    }
  };

  public ngAfterViewInit() {
    if (isPlatformBrowser(AppComponent.PLATFORM_ID)) {
      const interval = setInterval(() => {
        if (this.stillResizing) {
          this.initializeOrUpdateGroup();
          this.checkForTextFitting();
        } else {
          this.initializeOrUpdateGroup();
          clearInterval(interval);
        }
      }, 5);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (isPlatformBrowser(AppComponent.PLATFORM_ID)) {
      this.checkForTextFitting();
    }
  }

  /**
   * Main function which checks ratio for the current text/container, makes the text
   * smaller if needed.
   *
   * @private
   */
  private checkForTextFitting() {
    if (isPlatformBrowser(AppComponent.PLATFORM_ID)) {
      const ratio = this.getRatio();
      const newFontSize = (this.getFontsizeOfElementInPixels(this.fittextElement) / ratio);
      if (ratio > 1 || this.wasChanged) {
        this.wasChanged = true;
        this.updateGroupElement(newFontSize);
        if (this.fitTextToContainerElementName) {
          this.fittextElement.style.fontSize = this.getMasterFontsizeForGroup() + this.fontUnit;
        } else {
          this.fittextElement.style.fontSize = newFontSize + this.fontUnit;
        }
      } else if (this.hasMaster()) {
        if (this.fitTextToContainerElementName) {
          this.fittextElement.style.fontSize = this.getMasterFontsizeForGroup() + this.fontUnit;
        } else {
          this.fittextElement.style.fontSize = newFontSize + this.fontUnit;
        }
      }
      setTimeout(() => {
        this.stillResizing = this.currentSize !== this.getMasterFontsizeForGroup();
        this.currentSize = this.getMasterFontsizeForGroup();
      }, 50);
    }
  }

  /**
   * Returns the font size in pixels from the passed HTML element.
   *
   * @param element
   * @private
   */
  private getFontsizeOfElementInPixels(element: HTMLElement): number {
    // @ts-ignore
    const computedFontSizeWithUnit = window.getComputedStyle(element)['font-size'];
    return computedFontSizeWithUnit.substring(0, computedFontSizeWithUnit.indexOf(this.fontUnit)) as number;
  }

  /**
   * Returns the ratio between the container width (space available) and the text width (space used by text).
   *
   * @private
   */
  private getRatio() {
    const textWidth = this.fittextElement.offsetWidth;
    const containerWidth = this.fittextParent.offsetWidth;
    return textWidth / containerWidth;
  }

  /**
   * Create a new element in GROUP_ITEMS if it does not exist.
   * @private
   */
  private initializeOrUpdateGroup() {
    if (this.fitTextToContainerGroupName && this.fitTextToContainerElementName) {
      const index = FitTextToContainerDirective.GROUP_ITEMS.findIndex(group => group.groupname === this.fitTextToContainerGroupName && group.elementname === this.fitTextToContainerElementName);
      if (index > -1) {
        FitTextToContainerDirective.GROUP_ITEMS[index].originalFontsize = parseInt(String(this.getFontsizeOfElementInPixels(this.fittextElement)));
      } else {
        FitTextToContainerDirective.GROUP_ITEMS.push({
          groupname: this.fitTextToContainerGroupName,
          elementname: this.fitTextToContainerElementName,
          fontsize: 1000,
          isMaster: false,
          originalFontsize: parseInt(String(this.getFontsizeOfElementInPixels(this.fittextElement))),
        });
      }
    }
  }

  /**
   * Just update the font size for the groupelement.
   *
   * @param fontsize
   * @private
   */
  private updateGroupElement(fontsize: number): void {
    const index = FitTextToContainerDirective.GROUP_ITEMS.findIndex(group => {
      return group.groupname === this.fitTextToContainerGroupName &&
        group.elementname === this.fitTextToContainerElementName;
    });
    if (index > -1) {
      FitTextToContainerDirective.GROUP_ITEMS[index].fontsize = fontsize;
      FitTextToContainerDirective.GROUP_ITEMS[index].isMaster = true;
    }
  }

  /**
   * Returns the master fontsize in pixels for the current group,
   * which is taken from the one which was resized because it is too big for the container.
   *
   * @private
   */
  private getMasterFontsizeForGroup(): number {
    try {
      const masterElementForGroup = FitTextToContainerDirective.GROUP_ITEMS
        .filter(group => group.groupname === this.fitTextToContainerGroupName && group.isMaster)[0];
      if (masterElementForGroup.fontsize > masterElementForGroup.originalFontsize) {
        return masterElementForGroup.originalFontsize;
      }
      return masterElementForGroup.fontsize;
    } catch (noGroupException) {
      return 0;
    }
  }

  /**
   * Checks if there is a master (which means that its font size was modified).
   *
   * @private
   */
  private hasMaster(): boolean {
    try {
      const allElementsForGroup = FitTextToContainerDirective.GROUP_ITEMS
        .filter(group => group.groupname === this.fitTextToContainerGroupName && group.isMaster);
      return allElementsForGroup.length == 1;
    } catch (noGroupException) {
      return false;
    }
  }
}
