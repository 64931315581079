<div class="mypb-onboarding-details-page">
  <form [formGroup]="onboardingForm" (ngSubmit)="doOnboarding()" novalidate>
    <div class="form-group form-group-large">
      <label for="firstname">{{ 'global.personal.firstname' | translate }}</label>
      <input type="text" id="firstname" class="form-control" formControlName="firstname"
             placeholder="{{ 'global.personal.firstname' | translate }}">
      <small class="form-text text-muted"
             *ngIf="onboardingForm.controls['firstname'].touched && onboardingForm.controls['firstname'].hasError('required')">
        {{ 'global.personal.firstname' | translate }}
      </small>
    </div>
    <div class="form-group form-group-large">
      <label for="lastname">{{ 'global.personal.lastname' | translate }}</label>
      <input type="text" id="lastname" class="form-control" formControlName="lastname"
             placeholder="{{ 'global.personal.lastname' | translate }}">
      <small class="form-text text-muted"
             *ngIf="onboardingForm.controls['lastname'].touched && onboardingForm.controls['lastname'].hasError('required')">
        {{ 'global.personal.lastname' | translate }}
      </small>
    </div>
    <div class="form-group form-group-large">
      <label for="email">{{ 'onboarding.email' | translate }}</label>
      <input type="text" id="email" class="form-control" formControlName="email"
             placeholder="{{ 'onboarding.email' | translate }}">
      <small class="form-text text-muted"
             *ngIf="onboardingForm.controls['email'].touched && onboardingForm.controls['email'].hasError('required')">
        {{ 'onboarding.email.required' | translate }}
      </small>
      <small class="form-text text-muted"
             *ngIf="onboardingForm.controls['email'].touched && onboardingForm.controls['email'].hasError('email')">
        {{ 'onboarding.email.invalid' | translate }}
      </small>
    </div>
    <button type="submit"
            class="btn btn-primary"
            [disabled]="!onboardingForm.valid">
      Los geht's!
    </button>
  </form>
</div>
