import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Actions, Store } from '@ngxs/store';
import { AppState } from '../../state/app/app.state';
import { AppStateBackdropClicked } from '../../state/app/app.actions';
import { isPlatformBrowser } from '@angular/common';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'mypb-backdrop',
  templateUrl: './backdrop.component.html',
  styleUrls: ['backdrop.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class BackdropComponent implements OnInit {
  public isBackdropVisible$ = this.store.select(AppState.isBackdropVisible);

  constructor(
    private store: Store,
    private actions: Actions,
  ) {
    if (isPlatformBrowser(AppComponent.PLATFORM_ID)) {
      this.isBackdropVisible$.subscribe(isVisible => {
        const body = document.querySelector('body');
        if (body) {
          if (isVisible) {
            body.classList.add('mypb--is-user-panel-open');
            body.classList.add('blocked');
          } else {
            body.classList.remove('mypb--is-user-panel-open');
            body.classList.remove('blocked');
          }
        }
      });
    }
  }

  ngOnInit() {
  }

  public backdropClicked() {
    this.store.dispatch(new AppStateBackdropClicked());
  }
}
