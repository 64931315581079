import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingHelperService } from '../../../services/routing-helper.service';
import { Store } from '@ngxs/store';
import { ProfileComponent } from '../profile.component';

@Component({
  selector: 'mypb-profile-design-2',
  templateUrl: './profile-design-2.component.html',
  styleUrls: ['profile-design-2.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileDesign2Component extends ProfileComponent {
  constructor(
    protected override store: Store,
    protected override router: Router,
    protected override routingHelperService: RoutingHelperService,
  ) {
    super(store, router, routingHelperService);
  }
}
