<div class="mypb-profile-app-base">
  <form [formGroup]="form" (ngSubmit)="saveProfile()" novalidate>
    <div class="form-group">
      <label for="firstname">{{ 'global.personal.firstname' | translate }}</label>
      <input type="text" id="firstname" class="form-control" formControlName="firstname"
             (blur)="saveProfile()"
             placeholder="{{ 'global.personal.firstname' | translate }}">
      <small class="form-text text-muted"
             *ngIf="form.controls['firstname'].touched && form.controls['firstname'].hasError('required')">
        {{ 'global.personal.firstname' | translate }} {{ 'global.ui.isRequired' | translate }}
      </small>
    </div>
    <div class="form-group">
      <label for="lastname">{{ 'global.personal.lastname' | translate }}</label>
      <input type="text" id="lastname" class="form-control" formControlName="lastname"
             (blur)="saveProfile()"
             placeholder="{{ 'global.personal.lastname' | translate }}">
      <small class="form-text text-muted"
             *ngIf="form.controls['lastname'].touched && form.controls['lastname'].hasError('required')">
        {{ 'global.personal.lastname' | translate }} {{ 'global.ui.isRequired' | translate }}
      </small>
    </div>
    <div class="form-group">
      <label for="contentTitle">{{ 'profile.contentTitle' | translate }}</label>
      <input type="text" id="contentTitle" class="form-control" formControlName="contentTitle"
             (blur)="saveProfile()"
             placeholder="{{ 'profile.contentTitle' | translate }}">
      <small class="form-text text-muted"
             *ngIf="form.controls['contentTitle'].touched && form.controls['contentTitle'].hasError('required')">
        {{ 'profile.contentTitle' | translate }} {{ 'global.ui.isRequired' | translate }}
      </small>
    </div>
    <div class="form-group">
      <label for="contentLeadtext">{{ 'profile.contentLeadtext' | translate }}</label>
      <textarea id="contentLeadtext" class="form-control" formControlName="contentLeadtext"
                (blur)="saveProfile()"
                autoResizeTextarea
                rows="5"
                placeholder="{{ 'profile.contentLeadtext' | translate }}"></textarea>
      <small class="form-text text-muted"
             *ngIf="form.controls['contentLeadtext'].touched && form.controls['contentLeadtext'].hasError('required')">
        {{ 'profile.contentLeadtext' | translate }} {{ 'global.ui.isRequired' | translate }}
      </small>
    </div>
  </form>
</div>
