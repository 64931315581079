<div class="mypb-block mypb-block-contact block-content" *ngIf="profile">

  <div class="mypb-block-contact-wrap" *ngIf="addresses.length || phoneNumbers.length || emailAddresses.length">
    
    <h1 class="mypb-block-contact-name">
      {{ profile.firstname }} {{ profile.lastname }}
    </h1>
    <p class="mypb-block-contact-address" *ngFor="let address of addresses">
      {{ address.street }}<br>
      {{ address.postalCode }} {{ address.city }}
    </p>
    <p class="mypb-block-contact-phone" *ngFor="let phoneNumber of phoneNumbers">
      <a href="tel:{{phoneNumber.value}}">{{ phoneNumber.value }}</a>
    </p>
    <p class="mypb-block-contact-email" *ngFor="let emailAddress of emailAddresses">
      <a href="mailto:{{emailAddress.value}}">{{ emailAddress.value }}</a>
    </p>

    <div class="mypb-block-contact-wrapper">
      <div class="mypb-block-contact-links">
        <ul>
          <li *ngFor="let link of profile.links">
            <a [href]="link.value" target="_blank" [title]="link.label"
              class="tpl-icon-brand {{link.linktype | getIconForLinkTypePipe}}">
              {{ link.label }}
            </a>
          </li>
        </ul>
      </div>

      <div class="mypb-block-contact-message" *ngIf="emailAddresses && emailAddresses.length">
        <a (click)="openContactformModal()"
          title="Kontaktformular öffnen">
          Nachricht senden
        </a>
      </div>

      <div class="mypb-block-contact-qrcode-code">
        <ngx-qrcode
          [elementType]="qrCodeConfiguration.elementType"
          [errorCorrectionLevel]="qrCodeConfiguration.errorCorrectionLevel"
          [value]="vCardString">
        </ngx-qrcode>
      </div>
    </div>

  </div>


  <mypb-modal [id]="'contactform'">
    <mypb-contact-form></mypb-contact-form>
  </mypb-modal>
</div>
