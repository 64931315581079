import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { RoutingHelperService } from '../../services/routing-helper.service';

@Component({
  selector: 'mypb-apps-header',
  templateUrl: './apps-header.component.html',
  styleUrls: ['apps-header.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class AppsHeaderComponent implements OnInit {
  @Input() title: string = '';
  @Input() canGoBack: boolean = true;
  @Input() canClose: boolean = true;

  constructor(
    private location: Location,
    private router: Router,
    private routingHelperService: RoutingHelperService,
  ) {
  }

  ngOnInit() {
  }

  public goBack() {
    this.location.back();
  }

  public async close() {
    await this.router.navigateByUrl(this.routingHelperService.getRootUrlOfCurrentlySelectedPage(true));
  }
}
