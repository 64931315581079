import { Component, ViewEncapsulation } from '@angular/core';
import { MypbAddImageToBlockDto, MypbBlock, MypbBlockToImage, MypbContentManagementBlocksService, MypbContentManagementBlockToImageService, MypbImage } from '../../_generated/mypagebuilder-rest-api';
import { Store } from '@ngxs/store';
import { BlockComponent } from '../block/block.component';
import { lastValueFrom } from 'rxjs';
import { AppStateIsSaving } from '../../state/app/app.actions';

@Component({
  selector: 'mypb-block-gallery',
  templateUrl: './block-gallery.component.html',
  styleUrls: [
    'block-gallery.component.less',
    '../block/block.component.less',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class BlockGalleryComponent extends BlockComponent {
  public newImagesToSave: MypbAddImageToBlockDto[] = [];
  public imagesToDelete: MypbAddImageToBlockDto[] = [];

  constructor(
    protected override store: Store,
    protected override mypbContentManagementBlocksService: MypbContentManagementBlocksService,
    protected mypbContentManagementBlockToImageService: MypbContentManagementBlockToImageService,
  ) {
    super(
      store,
      mypbContentManagementBlocksService,
    );
  }

  async removeImage(blockToImage: MypbBlockToImage) {
    this.imagesToDelete.push(blockToImage);
    if (this.block) {
      const indexOfBlockToImage = this.block.images.findIndex(image => image.id === blockToImage.id);
      if (indexOfBlockToImage > -1) {
        this.block.images.splice(indexOfBlockToImage, 1);
      }
    }
  }

  async changeOrder(blockToImages: MypbBlockToImage[]) {
    blockToImages = blockToImages.map((blockToImage, index) => {
      blockToImage.position = index + 1;
      return blockToImage;
    });
    for (const blockToImage of blockToImages) {
      await lastValueFrom(this.mypbContentManagementBlockToImageService.blockToImageControllerUpdate({
        body: {
          imageId: blockToImage.imageId,
          blockId: blockToImage.blockId,
          position: blockToImage.position,
        },
      }));
    }
  }

  async onImageUploaded(image: MypbImage) {
    if (this.block && this.block.id) {
      const blockToImage = {
        imageId: image.id,
        image: image,
        blockId: this.block.id,
        position: (this.block.images && this.block.images.length) ? this.block.images.length + 1 : 1,
      };
      this.newImagesToSave.push(blockToImage);
      if (this.block.images) {
        this.block.images.push(blockToImage);
      } else {
        this.block.images = [blockToImage];
      }
    }
  }

  async updateBlockGallery(newData: Partial<MypbBlock>) {
    this.store.dispatch(new AppStateIsSaving(true));
    for (const newImageToSave of this.newImagesToSave) {
      await lastValueFrom(this.mypbContentManagementBlockToImageService.blockToImageControllerAdd({
        body: newImageToSave,
      }));
    }
    this.newImagesToSave = [];
    for (const imageToDelete of this.imagesToDelete) {
      await lastValueFrom(this.mypbContentManagementBlockToImageService.blockToImageControllerRemove({
        body: imageToDelete,
      }));
    }
    this.imagesToDelete = [];
    await this.updateBlock(newData);
    this.store.dispatch(new AppStateIsSaving(false));
  }

  async cancelBlockGallery() {
    if (this.newImagesToSave.length || this.imagesToDelete.length) {
      this.newImagesToSave = [];
      this.imagesToDelete = [];
      this.cancel();
    }
  }
}
