import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
  MypbAddress,
  MypbContentManagementBlocksService,
  MypbEmailAddress,
  MypbPhoneNumber,
  MypbProfile,
} from '../../_generated/mypagebuilder-rest-api';
import { Store } from '@ngxs/store';
import { AppState } from '../../state/app/app.state';
import { VCard, VCardEncoding, VCardFormatter } from 'ngx-vcard';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { ModalService } from '../modal/modal.service';

@Component({
  selector: 'mypb-block-contact',
  templateUrl: './block-contact.component.html',
  styleUrls: ['block-contact.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class BlockContactComponent implements OnInit {
  @Input() profile: MypbProfile | undefined;
  public addresses: MypbAddress[] = [];
  public phoneNumbers: MypbPhoneNumber[] = [];
  public emailAddresses: MypbEmailAddress[] = [];
  public isEditModeEnabled = this.store.select(AppState.isEditModeEnabled);
  public vCard: VCard = {} as any;
  public vCardString: string = '';
  public qrCodeConfiguration = {
    elementType: NgxQrcodeElementTypes.URL,
    errorCorrectionLevel: NgxQrcodeErrorCorrectionLevels.HIGH,
  };

  constructor(
    private store: Store,
    private mypbContentManagementBlocksService: MypbContentManagementBlocksService,
    private modalService: ModalService,
  ) {
  }

  ngOnChanges() {
    if (this.profile) {
      this.filterNonPublicEntities();
    }
  }

  ngOnInit(): void {
    if (this.profile) {
      this.filterNonPublicEntities();
      this.vCard = {
        name: {
          firstNames: this.profile.firstname,
          lastNames: this.profile.lastname,
        },
        address: this.addresses.map(entry => {
          return {
            street: entry.street,
            postalCode: entry.postalCode,
            locality: entry.city,
          };
        }),
        email: this.emailAddresses.map(entry => entry.value),
        telephone: this.phoneNumbers.map(entry => entry.value),
      };
      this.vCardString = VCardFormatter.getVCardAsString(this.vCard, VCardEncoding.utf8);
    }
  }

  public openContactformModal() {
    this.modalService.open('contactform');
  }

  private filterNonPublicEntities() {
    if (this.profile) {
      this.addresses = (this.profile.addresses || []).filter(entity => entity.isPublic);
      this.phoneNumbers = (this.profile.phoneNumbers || []).filter(entity => entity.isPublic);
      this.emailAddresses = (this.profile.emailAddresses || []).filter(entity => entity.isPublic);
    }
  }
}
