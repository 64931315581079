import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnChanges, OnInit, QueryList, Renderer2, SimpleChanges, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { AppState } from '../../state/app/app.state';
import { Store } from '@ngxs/store';
import { MypbContentManagementSubpagesService, MypbSubpage } from '../../_generated/mypagebuilder-rest-api';
import { ActivatedRoute } from '@angular/router';
import { AddSubpageEvent } from '../add-subpage/add-subpage.component';
import { AppStateRefreshPageData } from '../../state/app/app.actions';
import { lastValueFrom } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { isPlatformBrowser } from '@angular/common';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'mypb-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['navigation.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class NavigationComponent implements OnInit, OnChanges {
  @Input() subpages: MypbSubpage[] = [];
  @ViewChild('subpageName') subpageName: ElementRef | undefined;
  @ViewChild('navigationElement', { static: true }) navigationElement: ElementRef | undefined;
  @ViewChild('navElement') navElement: ElementRef | undefined;
  @ViewChild('ulElement') ulElement: ElementRef | undefined;
  @ViewChild('navEditElement') navEditElement: ElementRef | undefined;
  @ViewChild('navMoreElement') navMoreElement: ElementRef | undefined;
  @ViewChildren('liElementList') liElementList: QueryList<ElementRef> | undefined;
  public isEditModeEnabled$ = this.store.select(AppState.isEditModeEnabled);
  public isMyPage$ = this.store.select(AppState.isMyPage);
  public rootPath$ = this.store.select(AppState.rootPath);
  public currentSlug = '';
  public isRootPage = false;
  public addSubpageMenuVisible = false;
  public showMoreSubpagesVisible = false;
  public showMoreSubpagesVisibleActive = false;
  private hiddenLis: Set<number> = new Set();
  private scrollListener: () => void = () => {
  };

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private mypbContentManagementSubpagesService: MypbContentManagementSubpagesService,
    private renderer2: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  ngAfterViewInit() {
    this.calculateNavWidth();

    this.scrollListener = this.renderer2.listen('window', 'scroll', () => {
      this.onWindowScroll();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    try {
      setTimeout(() => {
        this.calculateNavWidth();
      }, 1);
    } catch (no) {
    }
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe({
      next: params => {
        if (params) {
          if (!params['slug']) {
            this.isRootPage = true;
          } else {
            this.currentSlug = params['slug'];
          }
        }
      },
    });
  }

  ngOnDestroy() {
    try {
      this.scrollListener();
    } catch (noScrollListenerException) {
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.calculateNavWidth();
  }


  public addSubpage(addSubpageEvent: AddSubpageEvent) {
    const pageId = this.store.selectSnapshot(AppState.pageId);
    this.mypbContentManagementSubpagesService.subpagesControllerCreate({
      body: {
        subpageTypeId: addSubpageEvent.type.id,
        pageId: pageId,
        name: addSubpageEvent.name,
        position: this.subpages.length + 1,
        isActive: false,
      },
    }).subscribe({
      next: () => {
        this.store.dispatch(new AppStateRefreshPageData());
      },
    });
  }

  public sortSubpages(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.subpages, event.previousIndex, event.currentIndex);
    this.subpages.map(async (subpage, index) => {
      await lastValueFrom(this.mypbContentManagementSubpagesService.subpagesControllerUpdate({
        id: subpage.id,
        body: {
          id: subpage.id,
          position: index + 1,
        },
      }));
    });
  }

  public scrollNavigationToTop() {
    if (isPlatformBrowser(AppComponent.PLATFORM_ID)) {
      setTimeout(() => {
        if (this.navigationElement) {
          const navigationEl = this.navigationElement.nativeElement;
          navigationEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 1);
    }
  }

  public toggleShowMoreSubpagesVisibleActive(forceHide = false) {
    if (forceHide) {
      this.showMoreSubpagesVisibleActive = false;
    } else {
      this.showMoreSubpagesVisibleActive = !this.showMoreSubpagesVisibleActive;
    }
    if (isPlatformBrowser(AppComponent.PLATFORM_ID)) {
      const body = document.querySelector('body');
      if (body) {
        if (this.showMoreSubpagesVisibleActive) {
          body.classList.add('blocked');
        } else {
          body.classList.remove('blocked');
        }
      }
    }
  }

  private calculateNavWidth() {
    let totalLiWidth = 0;
    if (this.ulElement && this.liElementList && this.liElementList.length) {
      // Get the width of the UL
      let availableSpace = this.getWidthOfElement(this.ulElement.nativeElement);

      if (this.navEditElement) {
        availableSpace -= this.getWidthOfElement(this.navEditElement.nativeElement);
      }

      if (this.navMoreElement) {
        availableSpace -= this.getWidthOfElement(this.navMoreElement.nativeElement);
      }

      // Iterate over each LI to calculate its width and determine if it fits
      this.liElementList.forEach((li, index) => {
        const liWidth = this.getWidthOfElement(li.nativeElement);
        totalLiWidth += liWidth;

        if (totalLiWidth > availableSpace) {
          // If total width exceeds UL width, mark this and all subsequent LIs as hidden
          this.hiddenLis.add(index);
        } else {
          this.hiddenLis.delete(index);
        }
      });

      this.showMoreSubpagesVisible = this.hiddenLis.size > 0;

      // Trigger change detection if necessary
      this.forceUpdate();
    }
  }

  public shouldBeHidden(index: number): boolean {
    return this.hiddenLis.has(index);
  }

  private forceUpdate() {
    try {
      this.changeDetectorRef.detectChanges();
    } catch (no) {
    }
  }

  private getWidthOfElement(element: HTMLElement) {
    const style = window.getComputedStyle(element);
    const navWidth = element.offsetWidth;
    const margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight);
    const padding = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);
    return navWidth + margin + padding;
  }

  private onWindowScroll() {
    if (isPlatformBrowser(AppComponent.PLATFORM_ID) && this.navigationElement) {
      const boundingClientRect = this.navigationElement.nativeElement.getBoundingClientRect();
      if (boundingClientRect.top > 0) {
        this.renderer2.removeClass(document.body, 'mypb--scroll-header');
      } else {
        this.renderer2.addClass(document.body, 'mypb--scroll-header');
      }
    }
  }
}
