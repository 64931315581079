/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MypbAddAddressToBlockDto } from '../models/mypb-add-address-to-block-dto';
import { MypbBlockToAddress } from '../models/mypb-block-to-address';
import { MypbRemoveAddressFromBlockDto } from '../models/mypb-remove-address-from-block-dto';

@Injectable({
  providedIn: 'root',
})
export class MypbContentManagementBlockToAddressService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation blockToAddressControllerAdd
   */
  static readonly BlockToAddressControllerAddPath = '/v1/content-management/block-to-address';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `blockToAddressControllerAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  blockToAddressControllerAdd$Response(params: {
    context?: HttpContext
    body: MypbAddAddressToBlockDto
  }
): Observable<StrictHttpResponse<MypbBlockToAddress>> {

    const rb = new RequestBuilder(this.rootUrl, MypbContentManagementBlockToAddressService.BlockToAddressControllerAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MypbBlockToAddress>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `blockToAddressControllerAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  blockToAddressControllerAdd(params: {
    context?: HttpContext
    body: MypbAddAddressToBlockDto
  }
): Observable<MypbBlockToAddress> {

    return this.blockToAddressControllerAdd$Response(params).pipe(
      map((r: StrictHttpResponse<MypbBlockToAddress>) => r.body as MypbBlockToAddress)
    );
  }

  /**
   * Path part for operation blockToAddressControllerRemove
   */
  static readonly BlockToAddressControllerRemovePath = '/v1/content-management/block-to-address';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `blockToAddressControllerRemove()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  blockToAddressControllerRemove$Response(params: {
    context?: HttpContext
    body: MypbRemoveAddressFromBlockDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MypbContentManagementBlockToAddressService.BlockToAddressControllerRemovePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `blockToAddressControllerRemove$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  blockToAddressControllerRemove(params: {
    context?: HttpContext
    body: MypbRemoveAddressFromBlockDto
  }
): Observable<void> {

    return this.blockToAddressControllerRemove$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
