import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewEncapsulation } from '@angular/core';
import { MypbBlockStyle, MypbContentDeliveryBlockStylesService } from '../../_generated/mypagebuilder-rest-api';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'mypb-blockstyle-selector',
  templateUrl: './blockstyle-selector.component.html',
  styleUrls: ['blockstyle-selector.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class BlockstyleSelectorComponent implements OnInit {
  @Input() preselected: MypbBlockStyle | undefined;
  @Output() selected: EventEmitter<MypbBlockStyle> = new EventEmitter<MypbBlockStyle>();
  static BLOCK_STYLES: MypbBlockStyle[] = [];
  public blockStyles: MypbBlockStyle[] = [];
  public preselectedId: string = '';
  public showMenu = false;

  constructor(
    private mypbContentDeliveryBlockStylesService: MypbContentDeliveryBlockStylesService,
    @Optional() @Inject('X_API_KEY') private xApiKey: string,
  ) {
  }

  ngOnInit() {
    if (!BlockstyleSelectorComponent.BLOCK_STYLES.length) {
      this.initializeBlockTypes();
    } else {
      this.blockStyles = BlockstyleSelectorComponent.BLOCK_STYLES;
    }
    if (this.preselected) {
      this.preselectedId = this.preselected.id;
    }
  }

  public select(blockstyle: MypbBlockStyle) {
    this.selected.emit(blockstyle);
    this.showMenu = false;
  }

  private initializeBlockTypes() {
    this.mypbContentDeliveryBlockStylesService.blockStylesDeliveryControllerFindAll({
      'x-api-key': this.getXApiKey(),
    })
      .subscribe({
        next: blockstyles => {
          this.blockStyles = blockstyles;
          BlockstyleSelectorComponent.BLOCK_STYLES = blockstyles;
        },
      });
  }

  /**
   * Returns the API Secret from node environment (SSR) or angular environment (CSR).
   *
   * @private
   */
  private getXApiKey(): string {
    if (this.xApiKey) {
      return this.xApiKey;
    }
    return environment.apiConfiguration.apiKey;
  }
}
