import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { LoggerService } from '../../_common/logger/logger.service';
import { isPlatformBrowser } from '@angular/common';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'mypb-trix-editor',
  templateUrl: './trix-editor.component.html',
  styleUrls: ['trix-editor.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class TrixEditorComponent implements OnInit {
  @ViewChild('trixEditor') trixEditorElement!: ElementRef;
  @ViewChild('trixEditorContainer') trixEditorContainerElement!: ElementRef;
  @Input() content: string | undefined = '';
  @Input() triggerReset = false;
  @Output() contentUpdated: EventEmitter<string> = new EventEmitter<string>();
  public id: string = Math.random().toString(36).substring(2, 9);
  private trixEditor: any;
  private trixEditorContainer: any;
  private eventListenersSet = false;
  private parentSubpageBlockElement: HTMLDivElement | undefined = undefined;

  constructor(
    private elementRef: ElementRef,
  ) {
  }

  ngAfterViewInit() {
    try {
      this.trixEditor = this.trixEditorElement?.nativeElement;
      this.trixEditorContainer = this.trixEditorContainerElement?.nativeElement;
      if (!this.eventListenersSet) {
        this.setContent();
        if (isPlatformBrowser(AppComponent.PLATFORM_ID)) {
          this.parentSubpageBlockElement = this.elementRef.nativeElement.parentNode;
          while (this.parentSubpageBlockElement && !this.parentSubpageBlockElement.classList.contains('mypb-subpage--block')) {
            this.parentSubpageBlockElement = this.parentSubpageBlockElement.parentNode as HTMLDivElement;
          }
          this.setEventListeners();
        }
      }
    } catch (noNativeElementFound) {
      LoggerService.ERROR(this, 'noNativeElementFound', noNativeElementFound);
    }
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    try {
      if (changes['triggerReset'] && !changes['triggerReset'].isFirstChange() && changes['triggerReset'].currentValue === true) {
        while (this.trixEditor.editorController.editor.canUndo()) {
          this.trixEditor.editorController.editor.undo();
        }
      }
    } catch (noTriggerReset) {
      LoggerService.ERROR(this, 'noTriggerReset', noTriggerReset);
    }
  }

  setContent() {
    if (this.trixEditor) {
      this.trixEditor.editorController.editor.insertHTML(this.content);
    }
  }

  setEventListeners() {
    const body = document.querySelector('body') as HTMLBodyElement;
    this.eventListenersSet = true;
    this.trixEditor.addEventListener('trix-change', (event: any) => {
      const htmlString = this.addTargetBlankToAllAElements(event.target.value);
      this.contentUpdated.emit(htmlString);
    });
    this.trixEditor.addEventListener('trix-focus', (event: any) => {
      this.showTrixToolbar(body);
    });
    this.trixEditor.addEventListener('trix-blur', (event: any) => {
      try {
        if (!!event.target.editorController.toolbarController.attributes.frozen) {
          return;
        } else {
          this.hideTrixToolbar(body);
        }
      } catch (noAttributes) {
        this.hideTrixToolbar(body);
      }
    });
    try {
      this.trixEditor.blur();
    } catch (noBlurException) {
    }
  }

  private showTrixToolbar(body: HTMLBodyElement) {
    this.trixEditorContainer.querySelector('trix-toolbar').classList.add('active');
    if (body) {
      body.classList.add('mypb--is-trix-editor-active');
    }
    if (this.parentSubpageBlockElement) {
      this.parentSubpageBlockElement.classList.add('mypb-subpage--block--trix-active');
    }
  }

  private hideTrixToolbar(body: HTMLBodyElement) {
    setTimeout(() => {
      this.trixEditorContainer.querySelector('trix-toolbar').classList.remove('active');
      if (body) {
        body.classList.remove('mypb--is-trix-editor-active');
      }
      if (this.parentSubpageBlockElement) {
        this.parentSubpageBlockElement.classList.remove('mypb-subpage--block--trix-active');
      }
    }, 100);
  }

  private addTargetBlankToAllAElements(htmlString: string): string {
    try {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, 'text/html');

      const anchors = doc.querySelectorAll('a');
      anchors.forEach(anchor => {
        if (!anchor.getAttribute('target')) {
          anchor.setAttribute('target', '_blank');
        }
      });

      return doc.body.innerHTML;
    } catch (parsingException) {
      LoggerService.ERROR(this, 'parsingException', parsingException);
      return htmlString;
    }
  }
}
