/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MypbCreateEmailAddressDto } from '../models/mypb-create-email-address-dto';
import { MypbEmailAddress } from '../models/mypb-email-address';
import { MypbUpdateEmailAddressDto } from '../models/mypb-update-email-address-dto';

@Injectable({
  providedIn: 'root',
})
export class MypbContentManagementEmailAddressesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation emailAddressesControllerCreate
   */
  static readonly EmailAddressesControllerCreatePath = '/v1/content-management/email-addresses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailAddressesControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailAddressesControllerCreate$Response(params: {
    context?: HttpContext
    body: MypbCreateEmailAddressDto
  }
): Observable<StrictHttpResponse<MypbEmailAddress>> {

    const rb = new RequestBuilder(this.rootUrl, MypbContentManagementEmailAddressesService.EmailAddressesControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MypbEmailAddress>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailAddressesControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailAddressesControllerCreate(params: {
    context?: HttpContext
    body: MypbCreateEmailAddressDto
  }
): Observable<MypbEmailAddress> {

    return this.emailAddressesControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<MypbEmailAddress>) => r.body as MypbEmailAddress)
    );
  }

  /**
   * Path part for operation emailAddressesControllerFindAllByProfileId
   */
  static readonly EmailAddressesControllerFindAllByProfileIdPath = '/v1/content-management/email-addresses/byProfileId/{profileId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailAddressesControllerFindAllByProfileId()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailAddressesControllerFindAllByProfileId$Response(params: {
    profileId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<MypbEmailAddress>>> {

    const rb = new RequestBuilder(this.rootUrl, MypbContentManagementEmailAddressesService.EmailAddressesControllerFindAllByProfileIdPath, 'get');
    if (params) {
      rb.path('profileId', params.profileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MypbEmailAddress>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailAddressesControllerFindAllByProfileId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailAddressesControllerFindAllByProfileId(params: {
    profileId: string;
    context?: HttpContext
  }
): Observable<Array<MypbEmailAddress>> {

    return this.emailAddressesControllerFindAllByProfileId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MypbEmailAddress>>) => r.body as Array<MypbEmailAddress>)
    );
  }

  /**
   * Path part for operation emailAddressesControllerRemove
   */
  static readonly EmailAddressesControllerRemovePath = '/v1/content-management/email-addresses/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailAddressesControllerRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailAddressesControllerRemove$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MypbContentManagementEmailAddressesService.EmailAddressesControllerRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailAddressesControllerRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailAddressesControllerRemove(params: {
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.emailAddressesControllerRemove$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation emailAddressesControllerUpdate
   */
  static readonly EmailAddressesControllerUpdatePath = '/v1/content-management/email-addresses/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailAddressesControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailAddressesControllerUpdate$Response(params: {
    id: string;
    context?: HttpContext
    body: MypbUpdateEmailAddressDto
  }
): Observable<StrictHttpResponse<MypbEmailAddress>> {

    const rb = new RequestBuilder(this.rootUrl, MypbContentManagementEmailAddressesService.EmailAddressesControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MypbEmailAddress>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailAddressesControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailAddressesControllerUpdate(params: {
    id: string;
    context?: HttpContext
    body: MypbUpdateEmailAddressDto
  }
): Observable<MypbEmailAddress> {

    return this.emailAddressesControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<MypbEmailAddress>) => r.body as MypbEmailAddress)
    );
  }

}
