import { Component, Input, OnInit } from '@angular/core';
import { AppState } from '../../state/app/app.state';
import { Store } from '@ngxs/store';

@Component({
  selector: 'mypb-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.less'],
})
export class BreadcrumbComponent implements OnInit {
  @Input('backroute') backroute: string = '';

  constructor(
    private readonly store: Store,
  ) {
  }

  ngOnInit(): void {
    const rootPath = this.store.selectSnapshot(AppState.rootPath);
    if (!this.backroute) {
      this.backroute = rootPath;
    } else {
      this.backroute = `${rootPath}/${this.backroute}`;
    }
  }

}
