<div class="mypb-addresses-editor">
  <form [formGroup]="form" (ngSubmit)="save()" novalidate>
    <div class="mypb-addresses-editor-entry" *ngFor="let entity of entities; let index = index;">
      <div class="form-group">
        <label for="entity{{index}}street{{randomId}}">{{ 'address.street' | translate }}</label>
        <input type="text" id="entity{{index}}street{{randomId}}" class="form-control"
               [formControl]="$any(form).controls['entity'+index+'street']"
               placeholder="{{ 'address.street' | translate }}">
        <small class="form-text text-muted"
               *ngIf="form.controls['entity'+index+'street'].touched && form.controls['entity'+index+'street'].hasError('required')">
          {{ 'address.street' | translate }} {{ 'global.ui.isRequired' | translate }}
        </small>
      </div>
      <div class="form-group form-group-1-3">
        <div>
          <label for="entity{{index}}postalCode{{randomId}}">{{ 'address.postalCode' | translate }}</label>
          <input type="text" id="entity{{index}}postalCode{{randomId}}" class="form-control"
                 [formControl]="$any(form).controls['entity'+index+'postalCode']"
                 placeholder="{{ 'address.postalCode' | translate }}">
          <small class="form-text text-muted"
                 *ngIf="form.controls['entity'+index+'postalCode'].touched && form.controls['entity'+index+'postalCode'].hasError('required')">
            {{ 'address.postalCode' | translate }} {{ 'global.ui.isRequired' | translate }}
          </small>
        </div>
        <div>
          <label for="entity{{index}}city{{randomId}}">{{ 'address.city' | translate }}</label>
          <input type="text" id="entity{{index}}city{{randomId}}" class="form-control"
                 [formControl]="$any(form).controls['entity'+index+'city']"
                 placeholder="{{ 'address.city' | translate }}">
          <small class="form-text text-muted"
                 *ngIf="form.controls['entity'+index+'city'].touched && form.controls['entity'+index+'city'].hasError('required')">
            {{ 'address.city' | translate }} {{ 'global.ui.isRequired' | translate }}
          </small>
        </div>
      </div>
      <div class="form-group">
        <input type="checkbox" id="entity{{index}}isPublic{{randomId}}" class="form-control"
               [formControl]="$any(form).controls['entity'+index+'isPublic']"
               placeholder="{{ 'global.ui.isPublic' | translate }}">
        <label for="entity{{index}}isPublic{{randomId}}">{{ 'global.ui.isPublic' | translate }}</label>
        <small class="form-text text-muted"
               *ngIf="form.controls['entity'+index+'isPublic'].touched && form.controls['entity'+index+'isPublic'].hasError('required')">
          {{ 'global.ui.isPublic' | translate }} {{ 'global.ui.isRequired' | translate }}
        </small>
      </div>
      <ng-container *ngIf="allowMultiple">
        <a class="mypb-addresses-editor-entry__delete" (click)="deleteAtIndex(index)">Löschen</a>
      </ng-container>
    </div>
    <ng-container *ngIf="allowMultiple">
      <button class="btn btn-secondary" (click)="$event.preventDefault(); addNew()">
        {{ 'actions.new.address' | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="!autoSave">
      <button type="submit"
              class="btn btn-primary"
              [disabled]="!form.valid || form.pristine">
        {{ 'global.ui.save' | translate }}
      </button>
    </ng-container>
  </form>
</div>
