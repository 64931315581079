import { Component, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { AppState } from '../../state/app/app.state';
import { Store } from '@ngxs/store';
import {
  MypbBlock,
  MypbBlockStyle,
  MypbBlockType,
  MypbContentManagementBlocksService,
  MypbContentManagementSubpagesService,
  MypbProfile,
  MypbSubpage,
  MypbSubpageTechnicalNameEnum,
} from '../../_generated/mypagebuilder-rest-api';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { AppStateRefreshPageData } from '../../state/app/app.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { AddSubpageEvent } from '../../components/add-subpage/add-subpage.component';
import { isPlatformBrowser } from '@angular/common';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'mypb-subpage',
  templateUrl: './subpage.component.html',
  styleUrls: ['subpage.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class SubpageComponent implements OnInit {
  @Input() subpage: MypbSubpage | undefined;
  @Input() profile: MypbProfile | undefined;
  public isMyPage$ = this.store.select(AppState.isMyPage);
  public isEditModeEnabled$ = this.store.select(AppState.isEditModeEnabled);
  public subpageTechnicalNameEnum = MypbSubpageTechnicalNameEnum;
  public rootPath$ = this.store.select(AppState.rootPath);
  public blogPostTitle = '';

  constructor(
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private mypbContentManagementBlocksService: MypbContentManagementBlocksService,
    private mypbContentManagementSubpagesService: MypbContentManagementSubpagesService,
  ) {
  }

  async ngOnInit() {

  }

  async ngOnChanges(changes: SimpleChanges) {
    try {
      const subpage = changes['subpage'].currentValue as MypbSubpage;
      this.addVisibleClassOnBody(subpage.isActive);
      await this.readBlogPostTitle();
    } catch (noChanges) {
    }
  }

  public async saveBlogPostTitle() {
    if (this.subpage && this.blogPostTitle && this.blogPostTitle !== '') {
      const updatedSubpage = await lastValueFrom(this.mypbContentManagementSubpagesService.subpagesControllerUpdate({ id: this.subpage.id, body: { id: this.subpage.id, name: this.blogPostTitle } }));
      const rootPath = this.store.selectSnapshot(AppState.rootPath);
      if (updatedSubpage.parent) {
        await this.router.navigateByUrl(`/${rootPath}/${updatedSubpage.parent.slug}/${updatedSubpage.slug}`);
      } else {
        await this.router.navigateByUrl(`/${rootPath}/${updatedSubpage.slug}`);
      }
    }
  }

  public async setSubpageAsActive(isActive: boolean) {
    if (this.subpage) {
      const updatedSubpage = await lastValueFrom(this.mypbContentManagementSubpagesService.subpagesControllerUpdate({ id: this.subpage.id, body: { id: this.subpage.id, isActive: isActive } }));
      this.subpage.isActive = isActive;
    }
  }

  public async addBlockAtPosition(blockType: MypbBlockType, position: number) {
    const newBlock: MypbBlock = {
      blockTypeId: blockType.id,
      blockType: blockType,
      subpageId: this.subpage?.id,
      title: '',
      text: '',
      position: position,
    } as any;
    const savedBlock = await lastValueFrom(this.mypbContentManagementBlocksService.blocksControllerCreate({
      body: newBlock,
    }));
    this.subpage?.blocks.splice(position, 0, savedBlock);
  }

  public sortBlocks(event: CdkDragDrop<string[]>) {
    if (this.subpage) {
      moveItemInArray(this.subpage.blocks, event.previousIndex, event.currentIndex);
      this.updateBlockPositions();
    }
  }

  public moveBlockUp(index: number) {
    if (index > 0 && this.subpage) {
      const temp = this.subpage.blocks[index - 1];
      this.subpage.blocks[index - 1] = this.subpage.blocks[index];
      this.subpage.blocks[index] = temp;
      this.updateBlockPositions();
    }
  }

  public moveBlockDown(index: number) {
    if (this.subpage && index < this.subpage.blocks.length - 1) {
      const temp = this.subpage.blocks[index + 1];
      this.subpage.blocks[index + 1] = this.subpage.blocks[index];
      this.subpage.blocks[index] = temp;
      this.updateBlockPositions();
    }
  }

  public delete(block: MypbBlock) {
    this.mypbContentManagementBlocksService.blocksControllerRemove({
      id: block.id,
    }).subscribe({
      next: () => {
        this.store.dispatch(new AppStateRefreshPageData());
      },
    });
  }

  public async setBlockStyle(blockStyle: MypbBlockStyle, block: MypbBlock) {
    block.blockStyle = blockStyle;
    await lastValueFrom(this.mypbContentManagementBlocksService.blocksControllerUpdate({
      id: block.id,
      body: {
        id: block.id,
        blockStyle: blockStyle,
      },
    }));
  }

  public async addBlogPost(addSubpageEvent: AddSubpageEvent) {
    const pageId = this.store.selectSnapshot(AppState.pageId);
    this.mypbContentManagementSubpagesService.subpagesControllerCreate({
      body: {
        subpageTypeId: addSubpageEvent.type.id,
        pageId: pageId,
        name: addSubpageEvent.name,
        isActive: false,
        parentId: this.subpage?.id,
      },
    }).subscribe({
      next: async subpage => {
        this.store.dispatch(new AppStateRefreshPageData());
        const rootPath = this.store.selectSnapshot(AppState.rootPath);
        await this.router.navigateByUrl(`/${rootPath}/${this.subpage?.slug}/${subpage.slug}?new=true`);
      },
    });
  }

  private updateBlockPositions() {
    if (this.subpage) {
      this.subpage.blocks.map(async (block, index) => {
        await lastValueFrom(this.mypbContentManagementBlocksService.blocksControllerUpdate({
          id: block.id,
          body: {
            id: block.id,
            position: index + 1,
          },
        }));
      });
    }
  }

  private addVisibleClassOnBody(isVisible: boolean = true) {
    if (isPlatformBrowser(AppComponent.PLATFORM_ID)) {
      const body = document.querySelector('body');
      if (body) {
        if (!isVisible) {
          body.classList.add('mypb--is-subpage-inactive');
        } else {
          body.classList.remove('mypb--is-subpage-inactive');
        }
      }
    }
  }

  private async readBlogPostTitle() {
    const queryParams = await firstValueFrom(this.activatedRoute.queryParams);
    if ((!queryParams || !queryParams['new']) && this.subpage) {
      console.log('queryParams', queryParams, !queryParams, !queryParams['new'], this.subpage.name);
      this.blogPostTitle = this.subpage.name;
    }
  }
}
