import { Component, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { AppState } from '../../state/app/app.state';
import { BehaviorSubject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'mypb-saving-indicator',
  templateUrl: './saving-indicator.component.html',
  styleUrls: ['saving-indicator.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class SavingIndicatorComponent {
  static HIDE_TIMEOUT_AFTER_SECONDS = 2;
  public isLoggedIn$ = this.store.select(AppState.isLoggedIn);
  public isSaving$ = this.store.select(AppState.isSaving);
  public hasSaved$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public timeoutIsVisible: any;
  public timeoutHasSaved: any;

  constructor(
    private readonly store: Store,
  ) {

  }

  ngOnInit() {
    if (isPlatformBrowser(AppComponent.PLATFORM_ID)) {
      this.isSaving$
        .subscribe({
          next: isSaving => {
            this.cancelExistingTimeouts();
            if (isSaving) {
              this.isVisible$.next(true);
            } else {
              this.hasSaved$.next(true);
              this.timeoutIsVisible = setTimeout(() => {
                this.isVisible$.next(false);
              }, SavingIndicatorComponent.HIDE_TIMEOUT_AFTER_SECONDS * 1000);
              this.timeoutHasSaved = setTimeout(() => {
                this.hasSaved$.next(false);
              }, (SavingIndicatorComponent.HIDE_TIMEOUT_AFTER_SECONDS + 1) * 1000);
            }
          },
        });
    }
  }

  private cancelExistingTimeouts() {
    try {
      clearTimeout(this.timeoutHasSaved);
    } catch (noTimeout) {
      // Nothing to do here.
    }
    try {
      clearTimeout(this.timeoutIsVisible);
    } catch (noTimeout) {
      // Nothing to do here.
    }
  }
}
