import { AfterViewInit, Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[titleDynamicFontSize]',
})
export class TitleDynamicFontSizeDirective implements AfterViewInit {
  @Input() titleMinFontSize: number = 10; // Minimum font size for the title
  @Input() titleMaxFontSize: number = 23; // Maximum font size for the title
  @Input() titleMargin: string = '100px'; // Default margin for the title
  private viewportThreshold: number = 1100; // Viewport width threshold

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  ngAfterViewInit() {
    this.adjustFontSize();
  }

  @HostListener('window:resize')
  onResize() {
    this.adjustFontSize();
  }

  private adjustFontSize() {
    const containerElement = this.el.nativeElement;
    const titleElement = containerElement.querySelector('.title');

    if (!titleElement) return;

    let currentFontSize = this.titleMaxFontSize;
    this.renderer.setStyle(titleElement, 'font-size', `${currentFontSize}px`);

    let containerWidth = containerElement.offsetWidth;
    let availableWidth = containerWidth;

    if (window.innerWidth < this.viewportThreshold) {
      availableWidth -= this.parseMargin(this.titleMargin);
    }

    while (titleElement.scrollWidth > availableWidth && currentFontSize > this.titleMinFontSize) {
      currentFontSize--;
      this.renderer.setStyle(titleElement, 'font-size', `${currentFontSize}px`);
      if (window.innerWidth < this.viewportThreshold) {
        availableWidth = containerWidth - this.parseMargin(this.titleMargin);
      } else {
        availableWidth = containerWidth;
      }
    }
  }

  private parseMargin(margin: string): number {
    return parseInt(margin.replace('px', ''));
  }
}
