<div class="mypb-contact-form">
  <h2>Kontakt</h2>
  <form [formGroup]="form" (ngSubmit)="send()" novalidate>
    <div class="form-group form-group-1-1">
      <div>
        <label for="firstname">{{ 'global.personal.firstname' | translate }}</label>
        <input type="text" id="firstname" class="form-control" formControlName="firstname"
               placeholder="{{ 'global.personal.firstname' | translate }}">
        <small class="form-text text-muted"
               *ngIf="form.controls['firstname'].touched && form.controls['firstname'].hasError('required')">
          {{ 'global.personal.firstname' | translate }} {{ 'global.ui.isRequired' | translate }}
        </small>
      </div>
      <div>
        <label for="lastname">{{ 'global.personal.lastname' | translate }}</label>
        <input type="text" id="lastname" class="form-control" formControlName="lastname"
               placeholder="{{ 'global.personal.lastname' | translate }}">
        <small class="form-text text-muted"
               *ngIf="form.controls['lastname'].touched && form.controls['lastname'].hasError('required')">
          {{ 'global.personal.lastname' | translate }} {{ 'global.ui.isRequired' | translate }}
        </small>
      </div>
    </div>
    <div class="form-group form-group-1-1">
      <div>
        <label for="emailAddress">{{ 'global.personal.emailAddress' | translate }}</label>
        <input type="text" id="emailAddress" class="form-control" formControlName="emailAddress"
               placeholder="{{ 'global.personal.emailAddress' | translate }}">
        <small class="form-text text-muted"
               *ngIf="form.controls['emailAddress'].touched && form.controls['emailAddress'].hasError('required')">
          {{ 'global.personal.emailAddress' | translate }} {{ 'global.ui.isRequired' | translate }}
        </small>
      </div>
      <div>
        <label for="phoneNumber">{{ 'global.personal.phoneNumber' | translate }}</label>
        <input type="text" id="phoneNumber" class="form-control" formControlName="phoneNumber"
               placeholder="{{ 'global.personal.phoneNumber' | translate }}">
        <small class="form-text text-muted"
               *ngIf="form.controls['phoneNumber'].touched && form.controls['phoneNumber'].hasError('required')">
          {{ 'global.personal.phoneNumber' | translate }} {{ 'global.ui.isRequired' | translate }}
        </small>
      </div>
    </div>
    <div class="form-group">
      <label for="message">{{ 'contact.message' | translate }}</label>
      <textarea id="message" class="form-control" formControlName="message"
                rows="10"
                placeholder="{{ 'contact.message' | translate }}"></textarea>
      <small class="form-text text-muted"
             *ngIf="form.controls['message'].touched && form.controls['message'].hasError('required')">
        {{ 'contact.message' | translate }} {{ 'global.ui.isRequired' | translate }}
      </small>
    </div>
    <br>
    <button type="submit"
            class="btn btn-primary"
            [disabled]="!form.valid">
      {{ 'global.ui.send' | translate }}
    </button>
  </form>
</div>
